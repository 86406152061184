import React, {useEffect, useRef, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Avatar,
    Box,
    Button,
    Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    Add,
    ArrowBack,
    ArrowDownward,
    AutoAwesome,
    Clear,
    Construction,
    Delete,
    Edit,
    ExpandMore,
    Language,
    LinkSharp,
    PeopleAlt,
    School,
    WorkHistory,
    WorkspacePremium
} from "@mui/icons-material";
import DeleteDialog from "../../miniComponents/DeleteDialog";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {programs} from "../../programs";
import apiUrls from "../../apiUrls";
import axios from "axios";
import {flagUrls} from "../../flagUrls";
import {competences} from "../../competences-az";
import {languages} from "../../languages-az";
import {useNavigate} from "react-router-dom";
import CropperComponent from "../../utils/cropperComponent";



const CvOrderEdit = ({wordsWithLang}) => {
    const cvAppConfig = useSelector(state => state.cvAppConfig)
    const navigate = useNavigate()
    const [flagUrlsArr, setFlagUrlsArr] = useState(flagUrls);
    // useEffect(()=>{polyfillCountryFlagEmojis()},[])
    const [competencesList, setCompetencesList] = useState(competences);
    const [languageList, setLanguageList] = useState(languages);
    const [openCropper, setOpenCropper] = useState(false);
    // const [wordsWithLang, setWordsWithLang] = useState(words);

    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)

    // const [count, setCount] = useState(0);
    // const changeLang = () => {
    //     if(count===0){
    //         import("../../competencesList-en").then(module => {
    //             setCompetences(module.competencesList)
    //         });
    //     }
    //     if(count===1){
    //         import("../../competencesList-az").then(module => {
    //             setCompetences(module.competencesList)
    //         });
    //     }
    //     if(count===2){
    //         import("../../competencesList-ru").then(module => {
    //             setCompetences(module.competencesList)
    //         });
    //     }
    //     setCount((prevCount) => (prevCount + 1) % 3);
    // }

    const dispatcher = useDispatch()
    const cvAppConfigCvData = useSelector(state => state.cvAppConfig.cvData)

    const isMobile = window.screen.width <= 700
    const [avatar, setAvatar] = useState(cvAppConfigCvData.avatarUrl)
    const [expanded, setExpanded] = useState("panel1")

    const avatarDelete = useRef();
    const avatarAdd = useRef();
    const fileUpload = useRef(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    // titles refs
    const [errorTitles, setErrorTitles] = useState([]);
    const [personalInfoTitleColor, setPersonalInfoTitleColor] = useState("#000000");
    const [educationalInfoTitleColor, setEducationalInfoTitleColor] = useState("#000000");
    const [workExperienceTitleColor, setWorkExperienceTitleColor] = useState("#000000");
    const [langTitleColor, setLangTitleColor] = useState("#000000");
    const [programTitleColor, setProgramTitleColor] = useState("#000000");
    const [certificateTitleColor, setCertificateTitleColor] = useState("#000000");
    const [referenceTitleColor, setReferenceTitleColor] = useState("#000000");
    const [competencyTitleColor, setCompetencyTitleColor] = useState("#000000");
    const [additionalNoteTitleColor, setAdditionalNoteTitleColor] = useState("#000000");
    // const [certificateTitleColor, setCertificateTitleColor] = useState("#000000");

    //field refs
    const [personalData, setPersonalData] = useState(cvAppConfigCvData.personal)
        // name: "",
        // surname: "",
        // birthDate: "",
        // email: "",
        // phone: "",
        // about: ""

    const [link, setLink] = useState("");


    // cvAppData vars
    const [certificate, setCertificate] = useState({
        id: null,
        name: "",
        about: "",
        issuance: null
    });

    const [competencies, setCompetencies] = useState([]);

    const [education, setEducation] = useState({
        id: null,
        eduType: "",
        name: "",
        specialty: "",
        faculty: "",
        startDate: "",
        endDate: "",
        isContinue: false
    });

    const [lang, setLang] = useState({
        id: null,
        language: "",
        level: 1
    });

    const [program, setProgram] = useState({
        id: null,
        program: "",
        level: 1
    });

    const [reference, setReference] = useState({
        id: null,
        refName: "",
        contact: ""
    });

    const [workExperience, setWorkExperience] = useState({
        id: null,
        workplace: "",
        branch: "",
        position: "",
        about: "",
        startDate: "",
        endDate: "",
        isContinue: false
    });
    const [note, setNote] = useState("");

    const [deleteAvatarDialogStatus, setDeleteAvatarDialogStatus] = useState(false)
    const onDeleteAvatar = () => {
        handleFileChange(null)
        setDeleteAvatarDialogStatus(false)
        setAvatar(null)
        let tempData = cvAppConfigCvData;
        tempData.avatarUrl = null
        dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
        axios.delete(apiUrls.baseApiUrl + apiUrls.cvAppDeleteAvatarImage.replace("{CvOrderId}",newCvOrder.id), {withCredentials: true}).then((resp)=>{

        })
    }

    // const formData = new FormData();

    const handleCropComplete = (croppedImage) => {
        setCroppedImage(croppedImage);
        setOpenCropper(false)
        croppedImage = croppedImage.replace("data:image/jpeg;base64,", "");
        croppedImage = croppedImage.replace("data:image/png;base64,", "");
        croppedImage = croppedImage.replace("data:image/webp;base64,", "");
        let imageObj = {}
        imageObj.base64 = croppedImage
        imageObj.fileType = fileUpload.current.files[0].name.split('.').at(-1)

        axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostAvatarImage.replace("{CvOrderId}",newCvOrder.id), imageObj, {
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
            }

        })
            .then(resp => {
                let tempData = cvAppConfigCvData;
                tempData.avatarUrl = resp.data
                dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
                setAvatar(resp.data)
            })
    };
    const handleFileChange = (event) => {
        let fileTemp = null;
        let imageUrl = null;
        if(event === null){
            fileUpload.current.value = null
            imageUrl = null;
            setOpenCropper(false)
            setSelectedFile(null);
            setCroppedImage(null)
            fileTemp = null
        }
        else {
            fileTemp = event.target.files[0]
            setSelectedFile(fileTemp)
            if (fileTemp) {
                 imageUrl = URL.createObjectURL(fileTemp);
                console.log(imageUrl)
                setSelectedFile(imageUrl);
                setOpenCropper(true)
            }
        }
    };


// error messages

    const [errorMessagesForPersonalData, setErrorMessagesForPersonalData] = useState({
        name: "",
        surname: "",
        email: "",
        birthDate: "",
        phone: "",
        about: "",
    })
    const [errorMessagesForEduData, setErrorMessagesForEduData] = useState({
        id: "",
        eduType: "",
        eduName: "",
        eduSpecialty: "",
        eduFaculty: "",
        eduStartDate: "",
        eduEndDate: "",
        eduIsContinue: ""
    })
    const [errorMessagesForWorkData, setErrorMessagesForWorkData] = useState({
        id: "",
        workWorkplace: "",
        workBranch: "",
        workPosition: "",
        workAbout: "",
        workStartDate: "",
        workEndDate: "",
        workIsContinue: ""
    })
    const [errorMessagesForCertData, setErrorMessagesForCertData] = useState({
        id: "",
        name: "",
        about: "",
        issuance: ""
    })
    const [errorMessagesForRefData, setErrorMessagesForRefData] = useState({
        id: "",
        refName: "",
        contact: ""
    })


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [startCheckingPersonal, setStartCheckingPersonal] = useState(false);

    const personalInfoValidate = (fromClick) => {
        if (fromClick || startCheckingPersonal) {
            setStartCheckingPersonal(true)
        } else {
            return false;
        }

        let errorMessages = errorMessagesForPersonalData;

        if (!personalData.name || personalData.name.length < 3) {
            errorMessages = {
                ...errorMessages,
                name: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessages = {...errorMessages, name: ""}
        }

        if (!personalData.surname || personalData.surname.length < 3 || personalData.surname === null) {
            errorMessages = {
                ...errorMessages,
                surname: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.surname
            }
        } else {
            errorMessages = {...errorMessages, surname: ""}

        }

        if (!personalData.birthDate || personalData.birthDate === null ||personalData.birthDate.length < 3) {
            errorMessages = {
                ...errorMessages,
                birthDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.birthDate
            }

        } else {
            errorMessages = {...errorMessages, birthDate: ""}
        }

        if (!emailRegex.test(personalData.email)) {
            errorMessages = {
                ...errorMessages,
                email: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.email
            }
        } else {
            errorMessages = {...errorMessages, email: ""}
        }

        if (!personalData.phone || personalData.phone.length < 5) {
            errorMessages = {
                ...errorMessages,
                phone: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.phone
            }
        } else {
            errorMessages = {...errorMessages, phone: ""}
        }

        if (!personalData.about || personalData.about && personalData.about.length > 250) {
            errorMessages = {
                ...errorMessages,
                about: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.about
            }
        } else {
            errorMessages = {...errorMessages, about: ""}
        }


        setErrorMessagesForPersonalData(errorMessages)

        if (Object.values(errorMessages).every(value => value === "")) {
            setPersonalInfoTitleColor("#000000")

        } else {
            setPersonalInfoTitleColor("#e50914")
            return false;
        }

        if (Object.values(errorMessages).every(value => value === "") && fromClick) {
            setPersonalInfoTitleColor("#000000")

            let temp = cvAppConfigCvData

            temp.personal.name = personalData.name
            temp.personal.surname = personalData.surname
            temp.personal.birthDate = personalData.birthDate
            temp.personal.email = personalData.email
            temp.personal.phone = personalData.phone
            temp.personal.about = personalData.about

            dispatcher({type: "CV_DATA_UPDATE", payload: temp})

            setStartCheckingPersonal(false)
            return true
        }

    }


// education functions
    const [startCheckingEdu, setStartCheckingEdu] = useState(false);

    const eduInfoValidate = (fromClick) => {
        if (fromClick || startCheckingEdu) {
            setStartCheckingEdu(true)
        } else {
            return false;
        }
        let errorMessagesForEduDataTemp = errorMessagesForEduData;
        if (education.eduType.length < 3) {
            errorMessagesForEduDataTemp = {
                ...errorMessagesForEduDataTemp,
                eduType: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduType: ""}
        }
        if (education.name.length < 3) {
            errorMessagesForEduDataTemp = {
                ...errorMessagesForEduDataTemp,
                eduName: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduName: ""}
        }

        if (education.specialty.length < 3 && education.eduType !== "secondary") {
            errorMessagesForEduDataTemp = {
                ...errorMessagesForEduDataTemp,
                eduSpecialty: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduSpecialty: ""}

        }
        if (education.faculty.length < 3 && education.eduType !== "secondary") {
            errorMessagesForEduDataTemp = {
                ...errorMessagesForEduDataTemp,
                eduFaculty: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduFaculty: ""}
        }

        if (education.startDate.length < 3) {
            errorMessagesForEduDataTemp = {
                ...errorMessagesForEduDataTemp,
                eduStartDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduStartDate: ""}
        }

        if (education.endDate.length < 3 && !education.isContinue) {
            errorMessagesForEduDataTemp = {
                ...errorMessagesForEduDataTemp,
                eduEndDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduEndDate: ""}
        }

        setErrorMessagesForEduData(errorMessagesForEduDataTemp)

        if (Object.values(errorMessagesForEduDataTemp).every(value => value === "")) {
            setEducationalInfoTitleColor("#000000")

        } else {
            setEducationalInfoTitleColor("#e50914")
            return false;
        }

        if (Object.values(errorMessagesForEduDataTemp).every(value => value === "") && fromClick) {
            cvAppConfigCvData.educations = [...cvAppConfigCvData.educations, education]
            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
            setEducation({
                id: null,
                eduType: "",
                name: "",
                specialty: "",
                faculty: "",
                startDate: "",
                endDate: "",
                isContinue: false
            })
            setStartCheckingEdu(false)
            return true
        }
    }

    const deleteEdu = (key) => {
        cvAppConfigCvData.educations.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }

// workExperience functions
    const [startCheckingWork, setStartCheckingWork] = useState(false);

    const workInfoValidate = (fromClick) => {
        if (fromClick || startCheckingWork) {
            setStartCheckingWork(true)
        } else {
            return false;
        }
        let errorMessagesForWorkDataTemp = errorMessagesForWorkData;
        if (workExperience.workplace.length < 3) {
            errorMessagesForWorkDataTemp = {
                ...errorMessagesForWorkDataTemp,
                workWorkplace: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workWorkplace: ""}
        }
        if (workExperience.branch.length < 3) {
            errorMessagesForWorkDataTemp = {
                ...errorMessagesForWorkDataTemp,
                workBranch: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workBranch: ""}
        }

        if (workExperience.position.length < 3) {
            errorMessagesForWorkDataTemp = {
                ...errorMessagesForWorkDataTemp,
                workPosition: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workPosition: ""}

        }
        // if (workExperience.about.length < 3) {
        //     errorMessagesForWorkDataTemp = {
        //         ...errorMessagesForWorkDataTemp,
        //         workAbout: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
        //     }
        //
        // } else {
        //     errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workAbout: ""}
        // }

        if (workExperience.startDate.length < 3) {
            errorMessagesForWorkDataTemp = {
                ...errorMessagesForWorkDataTemp,
                workStartDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workStartDate: ""}
        }

        if (workExperience.endDate.length < 3 && !workExperience.isContinue) {
            errorMessagesForWorkDataTemp = {
                ...errorMessagesForWorkDataTemp,
                workEndDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workEndDate: ""}
        }

        setErrorMessagesForWorkData(errorMessagesForWorkDataTemp)

        if (Object.values(errorMessagesForWorkDataTemp).every(value => value === "")) {
            setWorkExperienceTitleColor("#000000")

        } else {
            setWorkExperienceTitleColor("#e50914")
            return false;
        }

        if (Object.values(errorMessagesForWorkDataTemp).every(value => value === "") && fromClick) {
            cvAppConfigCvData.workExperiences = [...cvAppConfigCvData.workExperiences, workExperience]
            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})

            setWorkExperience({
                id: null,
                workplace: "",
                branch: "",
                position: "",
                about: "",
                startDate: "",
                endDate: "",
                isContinue: false
            })
            setStartCheckingWork(false)
            return true
        }
    }

    const deleteWork = (key) => {
        cvAppConfigCvData.workExperiences.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }

// language functions

    const langLevelValues = (value) => {
        if(wordsWithLang) {
            switch (value) {
                case 0:
                    return wordsWithLang.cvApp.cvAppForms.langLevelWeak;
                case 1:
                    return wordsWithLang.cvApp.cvAppForms.langLevelNormal;
                case 2:
                    return wordsWithLang.cvApp.cvAppForms.langLevelGood;
                case 3:
                    return wordsWithLang.cvApp.cvAppForms.langLevelExcellent;
                default:
                    return '';
            }
        }
    }

    const langLevelMarks = [
        {value: 0, label: wordsWithLang.cvApp.cvAppForms.langLevelWeak},
        {value: 1, label: wordsWithLang.cvApp.cvAppForms.langLevelNormal},
        {value: 2, label: wordsWithLang.cvApp.cvAppForms.langLevelGood},
        {value: 3, label: wordsWithLang.cvApp.cvAppForms.langLevelExcellent}
    ];
    // const langLevelMarks = () =>{
    //     if(wordsWithLang){
    //         let data = [
    //             {value: 0, label: wordsWithLang.cvApp.cvAppForms.langLevelWeak},
    //             {value: 1, label: wordsWithLang.cvApp.cvAppForms.langLevelNormal},
    //             {value: 2, label: wordsWithLang.cvApp.cvAppForms.langLevelGood},
    //             {value: 3, label: wordsWithLang.cvApp.cvAppForms.langLevelExcellent}
    //         ]
    //         return  data;
    //     } else return null;
    // }

    // const deleteLang = (key) => {
    //     cvAppConfigCvData.langs.splice(key, 1)
    //     dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    // }

// programs functions
    const deleteProgram = (key) => {
        cvAppConfigCvData.programs.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }
    const filterProgramOptions = (options, {inputValue}) => {
        const filteredOptions = options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions.slice(0, 10); // Adjust the number as per your requirement
    };
    const [programNames, setProgramNames] = useState(programs);

// certificate functions
    const [startCheckingCert, setStartCheckingCert] = useState(false);

    const deleteCert = (key) => {
        cvAppConfigCvData.certificates.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }
    const certInfoValidate = (fromClick) => {
        if (fromClick || startCheckingCert) {
            setStartCheckingCert(true)
        } else {
            return false;
        }
        let errorMessagesForCertDataTemp = errorMessagesForCertData;
        if (certificate.name.length < 3) {
            errorMessagesForCertDataTemp = {
                ...errorMessagesForCertDataTemp,
                name: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesForCertDataTemp = {...errorMessagesForCertDataTemp, name: ""}
        }
        if (certificate.about.length > 1 && certificate.about.length < 3) {
            errorMessagesForCertDataTemp = {
                ...errorMessagesForCertDataTemp,
                about: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesForCertDataTemp = {...errorMessagesForCertDataTemp, about: ""}
        }

        if (certificate.issuance === "" || certificate.issuance === null) {
            errorMessagesForCertDataTemp = {
                ...errorMessagesForCertDataTemp,
                issuance: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }

        } else {
            errorMessagesForCertDataTemp = {...errorMessagesForCertDataTemp, issuance: ""}

        }

        setErrorMessagesForCertData(errorMessagesForCertDataTemp)

        if (Object.values(errorMessagesForCertDataTemp).every(value => value === "")) {
            setCertificateTitleColor("#000000")

        } else {
            setCertificateTitleColor("#e50914")
            return false;
        }

        if (Object.values(errorMessagesForCertDataTemp).every(value => value === "") && fromClick) {
            cvAppConfigCvData.certificates = [...cvAppConfigCvData.certificates, certificate]
            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
            setCertificate({
                id: "",
                name: "",
                about: "",
                issuance: ""
            })
            setStartCheckingCert(false)
            return true
        }
    }

// reference functions
    const [startCheckingRef, setStartCheckingRef] = useState(false);

    const deleteRef = (key) => {
        cvAppConfigCvData.references.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }
    const refInfoValidate = (fromClick) => {
        if (fromClick || startCheckingRef) {
            setStartCheckingRef(true)
        } else {
            return false;
        }
        let errorMessagesTemp = errorMessagesForRefData;
        if (reference.refName.length < 3) {
            errorMessagesTemp = {
                ...errorMessagesTemp,
                refName: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesTemp = {...errorMessagesTemp, refName: ""}
        }
        if (reference.contact.length < 3) {
            errorMessagesTemp = {
                ...errorMessagesTemp,
                contact: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
            }
        } else {
            errorMessagesTemp = {...errorMessagesTemp, contact: ""}
        }

        setErrorMessagesForRefData(errorMessagesTemp)

        if (Object.values(errorMessagesTemp).every(value => value === "")) {
            setReferenceTitleColor("#000000")

        } else {
            setReferenceTitleColor("#e50914")
            return false;
        }

        if (Object.values(errorMessagesTemp).every(value => value === "") && fromClick) {
            cvAppConfigCvData.references = [...cvAppConfigCvData.references, reference]
            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
            setReference({
                id: null,
                refName: "",
                contact: ""
            })
            setStartCheckingRef(false)
            return true
        }
    }

    const checkAllErrors = () => {
        if (personalInfoValidate(true)) {
            return true;
        }
        dispatcher({
            type: "OPEN_SNACK",
            payload: {
                "isSnackOpen": true,
                snackType: "error",
                snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
            }
        })
        // if(!eduInfoValidate(true)){
        //     return false;
        // }
        // if(!workInfoValidate(true)){
        //     return false;
        // }
        // if(!certInfoValidate(true)){
        //     return false;
        // }
        // if(!refInfoValidate(true)){
        //     return false;
        // }
        return false

    }

    const updateCvDataInServer = (comeForUpdate) => {
        axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}",newCvOrder.id).replace("{comeForUpdate}",comeForUpdate),cvAppConfigCvData,
            {withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(resp=>{
            dispatcher({type: "CV_DATA_UPDATE", payload: resp.data})
            console.log(resp.data)
        })
    }

    const sortLanguagesByName = (languages) => {
        // Convert the object to an array of [key, value] pairs
        const entries = Object.entries(languages);

        // Sort the entries array by the language name
        entries.sort((a, b) => a[1].language.localeCompare(b[1].language));

        // Convert the sorted array back to an object
        const sortedLanguages = Object.fromEntries(entries);

        return sortedLanguages;
    }
    const getFlagUrl = (flagEmoji) => {
        // Find the flag object that matches the provided emoji
        const flagObj = flagUrlsArr.find(obj => obj.flag === flagEmoji);

        // Return the flag URL or a default URL if not found
        return flagObj ? flagObj.flagurl : 'https://flagcdn.com/w80/default.svg'; // You can set a default URL here

    }
    const getFlagUrlByKey = (key) => {
        // Find the flag object that matches the provided emoji
        const flagObj = flagUrlsArr.find(obj => obj.flag === languageList[key].flag);

        // Return the flag URL or a default URL if not found
        return flagObj ? flagObj.flagurl : 'https://flagcdn.com/w80/default.svg'; // You can set a default URL here

    }
    // useEffect(() => {
    //     navigate(window.location.pathname);
    //     window.addEventListener('popstate', handleClose);
    // },[])

    useEffect(() => {
        if(cvAppConfig.cvAppScreen==="edit"){
            navigate(window.location.pathname);
            window.addEventListener('popstate', ()=>dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "main"}));
        }
    },[])
    useEffect(() => {
        personalInfoValidate(false)
    }, [personalData, startCheckingPersonal])

    useEffect(() => {
        eduInfoValidate(false)
    }, [education, startCheckingEdu])

    useEffect(() => {
        workInfoValidate(false)
    }, [workExperience, startCheckingWork])

    useEffect(() => {
        certInfoValidate(false)
    }, [certificate, startCheckingCert])

    // useEffect(()=>{
    //     switch (window.localStorage.getItem("pageLang")?window.localStorage.getItem("pageLang"):"az"){
    //         case "az":
    //             import("../../words").then(module => {
    //                 setWordsWithLang(module.words);
    //             });
    //             break;
    //         case "ru":
    //             import("../../words-ru").then(module => {
    //                 setWordsWithLang(module.words);
    //             });
    //             break;
    //
    //         case "en":
    //             import("../../words-en").then(module => {
    //                 setWordsWithLang(module.words);
    //             });
    //             break;
    //         default:
    //             break;
    //
    //     }
    // },[])

    useEffect(() => {

        switch (newCvOrder.language) {
            case "az":
                import("../../competences-az").then(module => {
                    setCompetencesList(module.competences);
                });
                import("../../languages-az").then(module => {
                    setLanguageList(sortLanguagesByName(module.languages));
                });
                break;

            case "ru":
                import("../../competences-ru").then(module => {
                    setCompetencesList(module.competences);
                });
                import("../../languages-ru").then(module => {
                    setLanguageList(sortLanguagesByName(module.languages));
                });
                break;

            case "en":
                import("../../competences-en").then(module => {
                    setCompetencesList(module.competences);
                });
                import("../../languages-en").then(module => {
                    setLanguageList(sortLanguagesByName(module.languages));
                });
                break;

            default:
                break;
        }

    }, []);


    return (
        <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    style={{marginLeft: 23}}
                    onClick={() => {
                        let temp = {
                            id: null,
                                status: "",
                                paymentStatus: "",
                                paymentDateTime: null,
                                cvUrl: "",
                                previewUrl: "",
                                pdfUrl: "",
                                isPublic: false,
                                language: "az",
                                cvData: {},
                                price: null,
                                products: [],
                            }
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "main"})}
                    }
                >
                    <ArrowBack fontSize="small"/>
                    <Typography style={{marginLeft: 3, fontSize: isMobile ? "13px" : "15px"}} variant="button">
                        {wordsWithLang.vacancyBody.back}
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Grid item justifyContent="center" display={"flex"} xs={12} style={{margin: "30px"}}>

{/*avatar functions*/}
                        <IconButton style={{height: 200, width: 200}}>
                            {avatar === null ?
                                (<><Avatar style={{height: 180, width: 180}}
                                           onMouseEnter={() => (avatarAdd.current.style.opacity = 0.7)}
                                           onMouseLeave={() => (avatarAdd.current.style.opacity = 0)}
                                           onTouchStart={() => (avatarAdd.current.style.opacity = 0.7)}
                                           onTouchEnd={() => (avatarAdd.current.style.opacity = 0)}/>
                                        <Add ref={avatarAdd} color={"primary"}
                                             style={{position: "absolute", height: 120, width: 120, opacity: 0}}
                                             onMouseEnter={() => (avatarAdd.current.style.opacity = 0.7)}
                                             onMouseLeave={() => (avatarAdd.current.style.opacity = 0)}
                                             onTouchStart={() => (avatarAdd.current.style.opacity = 0.7)}
                                             onTouchEnd={() => (avatarAdd.current.style.opacity = 0)}
                                             onClick={() => {
                                                 fileUpload.current.click();
                                             }}
                                        >
                                        </Add>
                                    </>
                                ) :
                                (<>
                                    <div style={{
                                        padding: 0,
                                        margin: 0,
                                        borderRadius: 100,
                                        height: 180,
                                        width: 180,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundImage: "url('"+apiUrls.baseApiUrl+apiUrls.cvAppGetAvatar.replace("{fileName}",avatar)+"')"
                                    }}
                                         onMouseEnter={() => (avatarDelete.current.style.opacity = 0.7)}
                                         onMouseLeave={() => (avatarDelete.current.style.opacity = 0)}
                                         onTouchStart={() => (avatarDelete.current.style.opacity = 0.7)}
                                         onTouchEnd={() => (avatarDelete.current.style.opacity = 0)}/>
                                    <Delete ref={avatarDelete} color={"secondary"}
                                            style={{position: "absolute", height: 120, width: 120, opacity: 0}}
                                            onMouseEnter={() => (avatarDelete.current.style.opacity = 0.7)}
                                            onMouseLeave={() => (avatarDelete.current.style.opacity = 0)}
                                            onTouchStart={() => (avatarDelete.current.style.opacity = 0.7)}
                                            onTouchEnd={() => (avatarDelete.current.style.opacity = 0)}
                                            onClick={() => (setDeleteAvatarDialogStatus(true))}/></>)}
                        </IconButton>

                        <input style={{opacity: 0, visibility: "hidden", display:"none"}} ref={fileUpload} onChange={handleFileChange}
                               type="file" id="avatar" name="avatar" accept="image/png, image/jpeg"/>

                    </Grid>
                    <Grid item justifyContent="center" xs={12} style={{margin: "10px"}} display={"block"}>
                        <Typography align={"center"}>{wordsWithLang.cvApp.addYourPhoto}</Typography>
                    </Grid>
                </Grid>


                <Grid item  xs={12} style={{margin: "30px"}}>
                    {/*<Grid container justifyContent="center">*/}

{/*personal info accordion*/}
                    <Accordion expanded={expanded === "panel1"} onChange={() => {
                        setExpanded("panel1")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={personalInfoTitleColor}>{wordsWithLang.cvApp.cvAppForms.personalInfo}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField id={"name"} disabled={newCvOrder.paymentStatus==="SUCCESS"} value={personalData.name} onChange={(e) => {
                                setPersonalData({...personalData, name: e.target.value})
                            }} helperText={errorMessagesForPersonalData.name}
                                       error={errorMessagesForPersonalData.name !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.name} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>

                            <TextField id={"surname"} disabled={newCvOrder.paymentStatus==="SUCCESS"} value={personalData.surname} onChange={(e) => {
                                setPersonalData({...personalData, surname: e.target.value})
                            }} helperText={errorMessagesForPersonalData.surname}
                                       error={errorMessagesForPersonalData.surname !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.surname} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>

                            <TextField id={"birthDate"} disabled={newCvOrder.paymentStatus==="SUCCESS"} value={personalData.birthDate} onChange={(e) => {
                                setPersonalData({...personalData, birthDate: e.target.value})
                            }} helperText={errorMessagesForPersonalData.birthDate}
                                       error={errorMessagesForPersonalData.birthDate !== ""} type={"date"} fullWidth
                                       margin="normal" label={wordsWithLang.cvApp.cvAppForms.birthDate} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5},inputProps: {max:  new Date().toISOString().split('T')[0]}}} InputLabelProps={{shrink: true}}/>
                            <TextField id={"email"} disabled={newCvOrder.paymentStatus==="SUCCESS"} value={personalData.email} onChange={(e) => {
                                setPersonalData({...personalData, email: e.target.value})
                            }} helperText={errorMessagesForPersonalData.email}
                                       error={errorMessagesForPersonalData.email !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.email} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <label htmlFor="phone" style={{display: 'block', marginBottom: '10px', fontSize: '16px'}}>
                                {wordsWithLang.cvApp.cvAppForms.phone}
                            </label>
                            <PhoneInput value={personalData.phone} disabled={newCvOrder.paymentStatus==="SUCCESS"} onChange={(phone) => {
                                setPersonalData({...personalData, phone: phone})
                            }} fullWidth margin="normal"
                                        country={'az'}
                                        enableAreaCodes={true}
                                        specialLabel={wordsWithLang.cvApp.cvAppForms.phone}
                                        variant="outlined"
                                        inputProps={{
                                            id: "phone",
                                            name: wordsWithLang.cvApp.cvAppForms.phone,
                                            required: true,
                                            label: wordsWithLang.cvApp.cvAppForms.phone,
                                            sx: {borderRadius: 15},
                                            fullWidth: true,
                                            helperText: errorMessagesForPersonalData.phone,
                                            error: errorMessagesForPersonalData.phone !== ""
                                        }
                                        }
                                        inputStyle={{
                                            padding: '25px',
                                            fontSize: '20px',
                                            borderRadius: 15,
                                            borderBottomLeftRadius: 0,
                                            borderTopLeftRadius: 0,
                                            width: "95%",
                                            right: "-5%",
                                            borderColor: errorMessagesForPersonalData.phone !== "" ? "#e50914" : null,
                                        }}
                            />
                            <TextField id={"about"} disabled={newCvOrder.paymentStatus==="SUCCESS"} onChange={(e) => {
                                setPersonalData({...personalData, about: e.target.value})
                            }}
                                       value={personalData.about}
                                       helperText={personalData.about!== null ?personalData.about.length + "/250 " + errorMessagesForPersonalData.about:null}
                                       error={errorMessagesForPersonalData.about !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.about} variant="outlined" multiline minRows={4}
                                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 250}}}/>


                            {cvAppConfigCvData.personal.links.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <LinkSharp fontSize={"small"}
                                                   style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noLinkAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.personal.links.map((value) => (
                                    <Box display={"block"} alignItems="center" marginY={2}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                            fontStyle: "italic"
                                        }}>
                                            <LinkSharp fontSize={"small"}
                                                       style={{marginRight: 5}}/>
                                            {value}
                                        </Box>
                                    </Box>
                                )))}
                            <TextField fullWidth id={"link"} disabled={newCvOrder.paymentStatus==="SUCCESS"} InputProps={{sx: {borderRadius: 5}}} margin="normal"
                                       value={link}
                                       onChange={(e) => {
                                           setLink(e.target.value)
                                       }}
                                       label={wordsWithLang.cvApp.cvAppForms.link} variant="outlined"
                                       style={{marginLeft: 8}}/>
                            <br/>
                            <br/>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12} lg={6}>

                                    <Button className={"addButton cleanButton"} size={"small"} style={{marginLeft: 8}}
                                            startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}
                                            onClick={() => {
                                                cvAppConfigCvData.personal.links = []
                                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                            }}>
                                        {wordsWithLang.cvApp.cvAppForms.clean}
                                    </Button>

                                    <Button className={link === "" ? "addButton disabledButton" : "addButton"}
                                            size={"small"} style={{marginLeft: 8}}
                                            startIcon={<Add fontSize={"medium"} style={{color: "white"}}/>}
                                            onClick={() => {
                                                if (link !== "") {
                                                    cvAppConfigCvData.personal.links = [...cvAppConfigCvData.personal.links, link]
                                                    dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                                    setLink("")
                                                }
                                            }}>
                                        {wordsWithLang.cvApp.cvAppForms.addLink}
                                    </Button>



                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Button
                                        variant="contained"
                                        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                        className="addButton continueButton"
                                        size="small"
                                        style={{width: "100%", float: "right"}}
                                        onClick={() => {
                                            if (personalInfoValidate(true)) {
                                                updateCvDataInServer("personal")
                                                setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                            }
                                        }
                                        }
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.continue}
                                    </Button>
                                </Grid>
                            </Grid>


                            <br/>
                            <br/>
                        </AccordionDetails>
                    </Accordion>


{/*educational info accordion*/}
                    <Accordion expanded={expanded === "panel2"} onChange={() => {
                        setExpanded("panel2")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={educationalInfoTitleColor}>{wordsWithLang.cvApp.cvAppForms.educationalInfo}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cvAppConfigCvData.educations.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <School fontSize={"small"}
                                                style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noEduAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.educations.map((value, key) => (
                                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}>
                                            <School fontSize={"small"}
                                                    style={{marginRight: 5}}/>
                                            <Typography>{wordsWithLang.cvApp.cvAppForms[value.eduType]} : {value.name} | <span
                                                style={{
                                                    fontSize: 12,
                                                    fontStyle: "italic"
                                                }}>{value.startDate} - {value.isContinue === true ? wordsWithLang.cvApp.cvAppForms.eduIsContinue : value.endDate}</span></Typography>
                                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                                deleteEdu(key)
                                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                                style={{color: "#e50914"}}/></Button>
                                        </Box>
                                    </Box>
                                )))}

                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>

                            <FormControl fullWidth margin="normal" error={errorMessagesForEduData.eduType !== ""}
                                         variant="outlined">
                                <InputLabel id="eduType-label">{wordsWithLang.cvApp.cvAppForms.eduType}</InputLabel>
                                <Select
                                    labelId="eduType-label"
                                    id="eduType"
                                    value={education.eduType}
                                    // defaultValue={"bachelor"}
                                    onChange={(e) => setEducation({...education, eduType: e.target.value})}
                                    label={wordsWithLang.cvApp.cvAppForms.eduType}
                                    sx={{borderRadius: 5}}
                                >
                                    <MenuItem value="secondary">Orta</MenuItem>
                                    <MenuItem value="vocational">Peşə</MenuItem>
                                    <MenuItem value="secondarySpecialized">Orta-ixtisas</MenuItem>
                                    <MenuItem value="bachelor">Ali-bakalavr</MenuItem>
                                    <MenuItem value="master">Magistr</MenuItem>
                                    <MenuItem value="doctoral">Dokturantura</MenuItem>
                                </Select>
                                <FormHelperText>{errorMessagesForEduData.eduType}</FormHelperText>
                            </FormControl>


                            <TextField id={"eduName"} value={education.name} onChange={(e) => {
                                setEducation({...education, name: e.target.value})
                            }} helperText={errorMessagesForEduData.eduName}
                                       error={errorMessagesForEduData.eduName !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.eduName} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <TextField id={"eduSpecialty"} disabled={education.eduType === "secondary"}
                                       value={education.specialty}
                                       onChange={(e) => {
                                           setEducation({...education, specialty: e.target.value})
                                       }} helperText={errorMessagesForEduData.eduSpecialty}
                                       error={errorMessagesForEduData.eduSpecialty !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.eduSpecialty} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <TextField id={"eduFaculty"} disabled={education.eduType === "secondary"} onChange={(e) => {
                                setEducation({...education, faculty: e.target.value})
                            }} helperText={errorMessagesForEduData.eduFaculty}
                                       value={education.faculty}
                                       error={errorMessagesForEduData.eduFaculty !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.eduFaculty} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5},inputProps: {max:  new Date().toISOString().split('T')[0]}}}/>
                            <TextField id={"eduStartDate"} type={"date"} onChange={(e) => {
                                setEducation({...education, startDate: e.target.value})
                            }} helperText={errorMessagesForEduData.eduStartDate}
                                       value={education.startDate}
                                       error={errorMessagesForEduData.eduStartDate !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.eduStartDate} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}} InputLabelProps={{shrink: true}}/>
                            <TextField id={"eduEndDate"} type={"date"} disabled={education.isContinue}
                                       onChange={(e) => {
                                           setEducation({...education, endDate: e.target.value})
                                       }} helperText={errorMessagesForEduData.eduEndDate}
                                       value={education.endDate}
                                       error={errorMessagesForEduData.eduEndDate !== "" && !education.isContinue}
                                       fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.eduEndDate} variant="outlined"
                                       InputProps={{
                                           sx: {borderRadius: 5},
                                           inputProps: {min: education.startDate ? education.startDate : ""}
                                       }} InputLabelProps={{shrink: true}}

                            />

                            <FormControl margin="normal" error={errorMessagesForEduData.eduIsContinue !== ""}
                                         variant="outlined">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="eduIsContinue"
                                            checked={education.isContinue}
                                            onChange={(e) => {
                                                setEducation({...education, isContinue: !education.isContinue})
                                            }}
                                            sx={{borderRadius: 5}}
                                        />
                                    }
                                    label={wordsWithLang.cvApp.cvAppForms.eduIsContinue}
                                />
                                <FormHelperText>{errorMessagesForEduData.eduIsContinue}</FormHelperText>
                            </FormControl>

                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button
                                        id={"eduCommit"}
                                        onClick={() => {
                                            eduInfoValidate(true)
                                            updateCvDataInServer("educations");
                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className="addButton"
                                        size="small"
                                        style={{width: '100%', marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Button
                                        variant="contained"
                                        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                        className="addButton continueButton"
                                        size="small"
                                        style={{width: '100%', marginTop: 15}}
                                        onClick={() => {
                                            updateCvDataInServer("educations");
                                            setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                        }}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.continue}
                                    </Button>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>

{/*workExperience accordion*/}
                    <Accordion expanded={expanded === "panel3"} onChange={() => {
                        setExpanded("panel3")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={workExperienceTitleColor}>{wordsWithLang.cvApp.cvAppForms.workExperience}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {cvAppConfigCvData.workExperiences.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <WorkHistory fontSize={"small"}
                                                     style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noWorkAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.workExperiences.map((value, key) => (
                                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}>
                                            <WorkHistory fontSize={"small"}
                                                         style={{marginRight: 5}}/>
                                            <Typography>{value.workplace} | <span style={{
                                                fontSize: 12,
                                                fontStyle: "italic"
                                            }}>{value.startDate} - {value.isContinue === true ? wordsWithLang.cvApp.cvAppForms.workIsContinue : value.endDate}</span></Typography>
                                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                                deleteWork(key)
                                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                                style={{color: "#e50914"}}/></Button>
                                        </Box>
                                    </Box>
                                )))}

                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>

                            <TextField id={"workWorkplace"} value={workExperience.workplace} onChange={(e) => {
                                setWorkExperience({...workExperience, workplace: e.target.value})
                            }} helperText={errorMessagesForWorkData.workWorkplace}
                                       error={errorMessagesForWorkData.workWorkplace !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.workplaceName} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>

                            <TextField id={"workBranch"} value={workExperience.branch} onChange={(e) => {
                                setWorkExperience({...workExperience, branch: e.target.value})
                            }} helperText={errorMessagesForWorkData.workBranch}
                                       error={errorMessagesForWorkData.workBranch !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.workBranch} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <TextField id={"workPosition"}
                                       value={workExperience.position}
                                       onChange={(e) => {
                                           setWorkExperience({...workExperience, position: e.target.value})
                                       }} helperText={errorMessagesForWorkData.workPosition}
                                       error={errorMessagesForWorkData.workPosition !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.workPosition} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <TextField id={"workAbout"} onChange={(e) => {
                                setWorkExperience({...workExperience, about: e.target.value})
                            }} helperText={workExperience.about.length + "/200 " + errorMessagesForWorkData.workAbout}
                                       value={workExperience.about}
                                       multiline maxRows={10} minRows={3}
                                       error={errorMessagesForWorkData.workAbout !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.workAbout} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 200}}}/>
                            <TextField id={"workStartDate"} type={"date"} onChange={(e) => {
                                setWorkExperience({...workExperience, startDate: e.target.value})
                            }} helperText={errorMessagesForWorkData.workStartDate}
                                       value={workExperience.startDate}
                                       error={errorMessagesForWorkData.workStartDate !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.workStartDate} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5},inputProps: {max:  new Date().toISOString().split('T')[0]}}} InputLabelProps={{shrink: true}}/>
                            <TextField id={"workEndDate"} type={"date"} disabled={workExperience.isContinue}
                                       onChange={(e) => {
                                           setWorkExperience({...workExperience, endDate: e.target.value})
                                       }} helperText={errorMessagesForWorkData.workEndDate}
                                       value={workExperience.endDate}
                                       error={errorMessagesForWorkData.workEndDate !== "" && !workExperience.isContinue}
                                       fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.workEndDate} variant="outlined"
                                       InputProps={{
                                           sx: {borderRadius: 5},
                                           inputProps: {min: workExperience.startDate ? workExperience.startDate : ""}
                                       }} InputLabelProps={{shrink: true}}

                            />

                            <FormControl margin="normal" error={errorMessagesForWorkData.workIsContinue !== ""}
                                         variant="outlined">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={"workIsContinue"}
                                            checked={workExperience.isContinue}
                                            onChange={(e) => {
                                                setWorkExperience({
                                                    ...workExperience,
                                                    isContinue: !workExperience.isContinue
                                                })
                                            }}
                                            sx={{borderRadius: 5}}
                                        />
                                    }
                                    label={wordsWithLang.cvApp.cvAppForms.workIsContinue}
                                />
                                <FormHelperText>{errorMessagesForWorkData.workIsContinue}</FormHelperText>
                            </FormControl>

                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button
                                        id={"workCommit"}
                                        onClick={() => {
                                            workInfoValidate(true)
                                            updateCvDataInServer("educations");

                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className="addButton"
                                        size="small"
                                        style={{width: '100%', marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Button
                                        variant="contained"
                                        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                        className="addButton continueButton"
                                        size="small"
                                        style={{width: '100%', marginTop: 15}}
                                        onClick={() => {
                                            updateCvDataInServer("workExperiences");
                                            setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                        }}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.continue}
                                    </Button>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>

{/*langKnowledge accordion*/}
                    <Accordion expanded={expanded === "panel4"} onChange={() => {
                        setExpanded("panel4")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={langTitleColor}>{wordsWithLang.cvApp.cvAppForms.langKnowledge}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cvAppConfigCvData.langs.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <Language fontSize={"small"}
                                                  style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noLangAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.langs.map((value, key) => (
                                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                            fontStyle: "italic"
                                        }}>
                                            <Language fontSize={"small"}
                                                      style={{marginRight: 5}}/>
                                            <Box component="img"
                                                 src={getFlagUrlByKey(value.language)}
                                                 alt={`${lang.language} flag`}
                                                 sx={{ width: 30, height: 20, marginRight:2 }}
                                            />
                                            <Typography>{languageList[value.language].language} | <span
                                                style={{fontSize: 12}}>{langLevelValues(value.level)}</span></Typography>
{/*<Button style={{marginLeft:10, color:"#e50914"}} onClick={() => {*/}
{/*    deleteWork(key)*/}
{/*}}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete style={{color:"#e50914"}}/></Button>*/}
                                        </Box>
                                    </Box>
                                )))}

                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>

                            <FormControl fullWidth margin="normal"
                                         variant="outlined">
                                <InputLabel id="language-label">{wordsWithLang.cvApp.cvAppForms.landKnowledgeLabel}</InputLabel>
                                <Select
                                    className={"flagEmoji"}
                                    labelId="language-label"
                                    id="language"
                                    value={lang.language}
                                    // defaultValue={"bachelor"}
                                    onChange={(e) => setLang({...lang, language: e.target.value})}
                                    label={wordsWithLang.cvApp.cvAppForms.langKnowledge}
                                    sx={{borderRadius: 5}}
                                >
                                    {Object.entries(languageList).map(([key, lang]) => (
                                        <MenuItem key={key} value={key}>
                                            <ListItemIcon style={{verticalAlign:"middle",display:"inline-flex",minWidth:40}}>
                                                <Box component="img"
                                                     src={getFlagUrl(lang.flag)}
                                                     sx={{ width: 30, height: 20,
                                                         display:"inline-flex", verticalAlign:"middle" }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText style={{display:"inline-flex", verticalAlign:"middle"}} primary={lang.language}/>
                                        </MenuItem>
                                    ))}
                                </Select>
{/*<FormHelperText>{errorMessagesForEduData.eduType}</FormHelperText>*/}
                            </FormControl>

                            <Grid item xs={12} md={8} lg={6} style={{margin: 15}}>
                                <Typography id="discrete-slider-custom" gutterBottom>
                                    {wordsWithLang.cvApp.cvAppForms.landKnowledgeLevel}
                                </Typography>
                                <Slider
                                    aria-labelledby="discrete-slider-custom"
                                    defaultValue={1}
                                    value={lang.level}
                                    onChange={(e) => {
                                        setLang({...lang, level: e.target.value})
                                    }}
                                    getAriaValueText={langLevelValues}
                                    valueLabelFormat={langLevelValues}
                                    step={1}
                                    marks={wordsWithLang?langLevelMarks:null}
                                    min={0}
                                    max={3}
                                    size={"medium"}
                                    color={"primary"}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        '& .MuiSlider-track': {
                                            height: 8,
                                        },
                                        '& .MuiSlider-rail': {
                                            height: 8,
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={12} lg={8}>
                                    <Button className={"addButton cleanButton"} size={"small"}
                                            startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}
                                            style={{marginTop: 15, marginLeft: 8}}
                                            onClick={() => {
                                                cvAppConfigCvData.langs = []
                                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                            }}>
                                        {wordsWithLang.cvApp.cvAppForms.clean}
                                    </Button>
                                    <Button
                                        id={"langCommit"}
                                        disabled={lang.language === ""}
                                        onClick={() => {
                                            if (lang.language !== "") {
                                                cvAppConfigCvData.langs = [...cvAppConfigCvData.langs, lang]
                                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                                setLang({
                                                    id: null,
                                                    language: "",
                                                    level: 1
                                                })
                                            }
                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className={lang.language === "" ? "addButton disabledButton" : "addButton"}
                                        size="small"
                                        style={{marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                                    </Button>


                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container justifyContent={"flex-end"}>
                                        <Grid item xs={12} md={6} lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                                className="addButton continueButton"
                                                size="small"
                                                style={{width: '100%', marginTop: 15}}
                                                onClick={() => {
                                                    updateCvDataInServer("langs");
                                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                                }}
                                            >
                                                {wordsWithLang.cvApp.cvAppForms.continue}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>

{/*program accordion*/}

                    <Accordion expanded={expanded === "panel5"} onChange={() => {
                        setExpanded("panel5")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={programTitleColor}>{wordsWithLang.cvApp.cvAppForms.programKnowledge}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cvAppConfigCvData.programs.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <Construction fontSize={"small"}
                                                      style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noProgramAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.programs.map((value, key) => (
                                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}>
                                            <Construction fontSize={"small"}
                                                          style={{marginRight: 5}}/>
                                            <Typography>{value.program} | <span style={{
                                                fontSize: 12,
                                                fontStyle: "italic"
                                            }}>{langLevelValues(value.level)}</span></Typography>
                                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                                deleteProgram(key)
                                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                                style={{color: "#e50914"}}/></Button>
                                        </Box>
                                    </Box>
                                )))}

                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>


                            <Autocomplete
                                options={programNames}
                                filterOptions={filterProgramOptions}
                                inputValue={program.program}
                                value={program.program}
                                onInputChange={(event, newInputValue) => setProgram({
                                    ...program,
                                    program: newInputValue
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={wordsWithLang.cvApp.cvAppForms.programKnowledgeLabel}
                                        variant="outlined"
                                        fullWidth
                                        // filterOptions={filterProgramOptions}
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                                // '& .MuiOutlinedInput-root': {
                                                borderRadius: "20px",
                                                // },
                                            }
                                        }}
                                    />
                                )}
                            />


                            <Grid item xs={12} md={8} lg={6} style={{margin: 15}}>
                                <Typography id="discrete-slider-custom" gutterBottom>
                                    {wordsWithLang.cvApp.cvAppForms.programKnowledgeLevel}
                                </Typography>
                                <Slider
                                    aria-labelledby="discrete-slider-custom"
                                    defaultValue={1}
                                    value={program.level}
                                    onChange={(e) => {
                                        setProgram({...program, level: e.target.value})
                                    }}
                                    getAriaValueText={langLevelValues}
                                    valueLabelFormat={langLevelValues}
                                    step={1}
                                    marks={langLevelMarks}
                                    min={0}
                                    max={3}
                                    size={"medium"}
                                    color={"primary"}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        '& .MuiSlider-track': {
                                            height: 8,
                                        },
                                        '& .MuiSlider-rail': {
                                            height: 8,
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={12} lg={8}>
                                    <Button
                                        id={"programCommit"}
                                        onClick={() => {
                                            if (program.program !== "") {
                                                cvAppConfigCvData.programs = [...cvAppConfigCvData.programs, program]
                                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                                setProgram({
                                                    id: null,
                                                    program: "",
                                                    level: 1
                                                })
                                            }
                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className={program.program === "" ? "addButton disabledButton" : "addButton"}
                                        size="small"
                                        style={{marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                                    </Button>
{/*<Button className={"addButton cleanButton"} size={"small"}*/}
{/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
{/*        style={{ marginTop: 15, marginLeft: 8}}*/}
{/*        onClick={() => {*/}
{/*            cvAppConfigCvData.langs = []*/}
{/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
{/*        }}>*/}
{/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
{/*</Button>*/}

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container justifyContent={"flex-end"}>
                                        <Grid item xs={12} md={6} lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                                className="addButton continueButton"
                                                size="small"
                                                style={{width: '100%', marginTop: 15}}
                                                onClick={() => {
                                                    updateCvDataInServer("programs");
                                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                                }}
                                            >
                                                {wordsWithLang.cvApp.cvAppForms.continue}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>

{/*certificate accordion*/}

                    <Accordion expanded={expanded === "panel6"} onChange={() => {
                        setExpanded("panel6")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={certificateTitleColor}>{wordsWithLang.cvApp.cvAppForms.certificateTitle}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cvAppConfigCvData.certificates.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <WorkspacePremium fontSize={"small"}
                                                          style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noCertificateAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.certificates.map((value, key) => (
                                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}>
                                            <WorkspacePremium fontSize={"small"}
                                                              style={{marginRight: 5}}/>
                                            <Typography>{value.name} | <span style={{
                                                fontSize: 12,
                                                fontStyle: "italic"
                                            }}>{value.issuance}</span></Typography>
                                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                                deleteCert(key)
                                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                                style={{color: "#e50914"}}/></Button>
                                        </Box>
                                    </Box>
                                )))}

                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>


                            <TextField id={"certName"}
                                       value={certificate.name}
                                       onChange={(e) => {
                                           setCertificate({...certificate, name: e.target.value})
                                       }} helperText={errorMessagesForCertData.name}
                                       error={errorMessagesForCertData.name !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.certificateName} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <TextField id={"certAbout"} onChange={(e) => {
                                setCertificate({...certificate, about: e.target.value})
                            }} helperText={certificate.about.length + "/200 " + errorMessagesForCertData.about}
                                       multiline maxRows={10} minRows={3}
                                       value={certificate.about}
                                       error={errorMessagesForCertData.about !== ""} fullWidth
                                       label={wordsWithLang.cvApp.cvAppForms.certificateAbout} variant="outlined"
                                // inputProps={{ maxLength: 150 }}
                                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 200}}}/>
                            <TextField id={"certIssuance"} type={"date"} onChange={(e) => {
                                setCertificate({...certificate, issuance: e.target.value})
                            }} helperText={errorMessagesForCertData.issuance}
                                       value={certificate.issuance}
                                       error={errorMessagesForCertData.issuance !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.certificateIssuance} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5},inputProps: {max:  new Date().toISOString().split('T')[0]}}} InputLabelProps={{shrink: true}}/>


                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={12} lg={8}>
                                    <Button
                                        id={"certCommit"}
                                        onClick={() => {
                                            certInfoValidate(true)
                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className={certificate.name === "" || certificate.issuance === "" ? "addButton disabledButton" : "addButton"}
                                        size="small"
                                        style={{marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                                    </Button>
{/*<Button className={"addButton cleanButton"} size={"small"}*/}
{/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
{/*        style={{ marginTop: 15, marginLeft: 8}}*/}
{/*        onClick={() => {*/}
{/*            cvAppConfigCvData.langs = []*/}
{/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
{/*        }}>*/}
{/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
{/*</Button>*/}

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container justifyContent={"flex-end"}>
                                        <Grid item xs={12} md={6} lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                                className="addButton continueButton"
                                                size="small"
                                                style={{width: '100%', marginTop: 15}}
                                                onClick={() => {
                                                    updateCvDataInServer("certificates");
                                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                                }}
                                            >
                                                {wordsWithLang.cvApp.cvAppForms.continue}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>


{/*Competence accordion*/}

                    <Accordion expanded={expanded === "panel7"} onChange={() => {
                        setExpanded("panel7")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={competencyTitleColor}>{wordsWithLang.cvApp.cvAppForms.competenceTitle}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>

                            {Object.entries(competencesList).map(([key, value]) => (
                                // <Button size={"small"} margin="normal" variant="outlined">
                                <FormControl key={key} size={"small"} error={errorMessagesForEduData.eduIsContinue !== ""}
                                             variant="outlined" style={{
                                    border: "1px solid",
                                    borderColor: "#b4b4b4",
                                    borderRadius: "20px",
                                    margin: "8px",
                                    paddingLeft: "10px"
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size={"small"}
                                                checked={cvAppConfigCvData.competencies && cvAppConfigCvData.competencies.includes(key)}
                                                key={key}
                                                onClick={(e) => {
                                                    if (!cvAppConfigCvData.competencies.includes(key)) {
                                                        cvAppConfigCvData.competencies = [...cvAppConfigCvData.competencies, key]
                                                        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                                    } else {
                                                        cvAppConfigCvData.competencies = cvAppConfigCvData.competencies.filter(item => item !== key);
                                                        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                                    }
                                                }}
                                                sx={{borderRadius: 5}}
                                            />
                                        }
                                        label={value}
                                    />
{/*<FormHelperText>{errorMessagesForEduData.eduIsContinue}</FormHelperText>*/}
                                </FormControl>
                                // </Button>
                            ))}
                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>


                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={12} lg={8}>

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container justifyContent={"flex-end"}>
                                        <Grid item xs={12} md={6} lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                                className="addButton continueButton"
                                                size="small"
                                                style={{width: '100%', marginTop: 15}}
                                                onClick={() => {
                                                    updateCvDataInServer("competencies");
                                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                                }}
                                            >
                                                {wordsWithLang.cvApp.cvAppForms.continue}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>


{/*reference accordion*/}

                    <Accordion expanded={expanded === "panel8"} onChange={() => {
                        setExpanded("panel8")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                color={referenceTitleColor}>{wordsWithLang.cvApp.cvAppForms.referenceTitle}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cvAppConfigCvData.references.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <PeopleAlt fontSize={"small"}
                                                   style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noReferenceAdded}
                                    </Box>
                                </Box>
                                :
                                (cvAppConfigCvData.references.map((value, key) => (
                                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}>
                                            <PeopleAlt fontSize={"small"}
                                                       style={{marginRight: 5}}/>
                                            <Typography>{value.refName} | <span style={{
                                                fontSize: 12,
                                                fontStyle: "italic"
                                            }}>{value.contact}</span></Typography>
                                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                                deleteRef(key)
                                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                                style={{color: "#e50914"}}/></Button>
                                        </Box>
                                    </Box>
                                )))}

                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>


                            <TextField id={"refName"}
                                       value={reference.refName}
                                       onChange={(e) => {
                                           setReference({...reference, refName: e.target.value})
                                       }} helperText={errorMessagesForRefData.refName}
                                       error={errorMessagesForRefData.refName !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.referenceName} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}}}/>
                            <TextField id={"refContact"} onChange={(e) => {
                                setReference({...reference, contact: e.target.value})
                            }} helperText={reference.contact.length + "/200 " + errorMessagesForRefData.contact}
                                       value={reference.contact}
                                       error={errorMessagesForRefData.contact !== ""} fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.referenceContact} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 200}}}/>

                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={12} lg={8}>
                                    <Button
                                        id={"referenceCommit"}
                                        onClick={() => {
                                            refInfoValidate(true)
                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className={reference.refName === "" || reference.contact === "" ? "addButton disabledButton" : "addButton"}
                                        size="small"
                                        style={{marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                                    </Button>
{/*<Button className={"addButton cleanButton"} size={"small"}*/}
{/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
{/*        style={{ marginTop: 15, marginLeft: 8}}*/}
{/*        onClick={() => {*/}
{/*            cvAppConfigCvData.langs = []*/}
{/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
{/*        }}>*/}
{/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
{/*</Button>*/}

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container justifyContent={"flex-end"}>
                                        <Grid item xs={12} md={6} lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                                                className="addButton continueButton"
                                                size="small"
                                                style={{width: '100%', marginTop: 15}}
                                                onClick={() => {
                                                    updateCvDataInServer("references");
                                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                                }}
                                            >
                                                {wordsWithLang.cvApp.cvAppForms.continue}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>

{/*note accordion*/}

                    <Accordion expanded={expanded === "panel9"} onChange={() => {
                        setExpanded("panel9")
                    }} style={{borderRadius: 10, margin: 10}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography
                                style={{color: additionalNoteTitleColor}}>{"📝 " + wordsWithLang.cvApp.cvAppForms.additionalNotesTitle}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cvAppConfigCvData.note == null || cvAppConfigCvData.note.length === 0 ?
                                <Box display={"block"} alignItems="center" marginY={2}>
                                    <Box alignItems={"center"} display={"flex"} style={{
                                        width: "100%",
                                        fontWeight: "400",
                                        color: "gray",
                                        fontSize: "12px",
                                        fontStyle: "italic"
                                    }}>
                                        <Edit fontSize={"small"}
                                              style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noAdditionalNotes}
                                    </Box>
                                </Box>
                                :
                                (<Box display={"block"} alignItems="center" marginY={2}>
                                        <Box alignItems={"center"} display={"flex"} style={{
                                            width: "100%",
                                            fontWeight: "400",
                                            color: "gray",
                                            fontSize: "13px",
                                            fontStyle: "italic"

                                        }}>
                                            <Edit fontSize={"small"}
                                                  style={{marginRight: 5}}/>
                                            {cvAppConfigCvData.note}
{/*<Button style={{marginLeft:10, color:"#e50914"}} onClick={() => {*/}
{/*    deleteRef(key)*/}
{/*}}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete style={{color:"#e50914"}}/></Button>*/}
                                        </Box>
                                    </Box>
                                )}

                            <Grid item xs={12} style={{margin: 10}}>
                                <Divider/>
                            </Grid>


                            <TextField id={"addNotes"}
                                       value={note}
                                       onChange={(e) => {
                                           setNote(e.target.value)
                                       }}
                                       multiline minRows={4}
                                // helperText={errorMessagesForRefData.refName}
                                       helperText={note.length + "/300 "}

                                // error={errorMessagesForRefData.refName !== ""}
                                       fullWidth margin="normal"
                                       label={wordsWithLang.cvApp.cvAppForms.additionalNotes} variant="outlined"
                                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 300}}}/>

{/*<TextField id={"refContact"} onChange={(e) => {*/}
{/*    setReference({...reference, contact: e.target.value})*/}
{/*}} helperText={errorMessagesForCertData.about}*/}
{/*           value={reference.contact}*/}
{/*           error={errorMessagesForRefData.contact !== ""} fullWidth margin="normal"*/}
{/*           label={wordsWithLang.cvApp.cvAppForms.referenceContact} variant="outlined"*/}
{/*           InputProps={{sx: {borderRadius: 5}, inputProps:{ maxLength: 200 }}}/>*/}

                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item xs={12} md={12} lg={8}>
                                    {cvAppConfigCvData.note?
                                        (<Button
                                        id={"noteCommit"}
                                        onClick={() => {
                                            cvAppConfigCvData.note = note
                                            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                            setNote("")
                                        }}
                                        variant="contained"
                                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                        className={note === "" ? "addButton disabledButton" : "addButton"}
                                        size="small"
                                        style={{marginTop: 15}}
                                    >
                                        {wordsWithLang.cvApp.cvAppForms.updateInfos}
                                    </Button>) :
                                        (<Button
                                            id={"noteCommit"}
                                            onClick={() => {
                                                cvAppConfigCvData.note = note
                                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                                setNote("")
                                            }}
                                            variant="contained"
                                            startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                                            className={note === "" ? "addButton disabledButton" : "addButton"}
                                            size="small"
                                            style={{marginTop: 15}}
                                        >
                                            {wordsWithLang.cvApp.cvAppForms.addInfos}
                                        </Button>)}

                                    <Button className={"addButton cleanButton"} size={"small"}
                                            startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}
                                            style={{marginTop: 15, marginLeft: 8}}
                                            onClick={() => {
                                                cvAppConfigCvData.note = ""
                                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                            }}>
                                        {wordsWithLang.cvApp.cvAppForms.clean}
                                    </Button>

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container justifyContent={"flex-end"}>
                                        <Grid item xs={12} md={6} lg={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<AutoAwesome fontSize="medium" style={{color: 'white'}}/>}
                                                className="addButton continueButton btn"
                                                size="small"
                                                style={{width: '100%', marginTop: 15}}
                                                onClick={() => {
                                                    if (checkAllErrors()) {
                                                        updateCvDataInServer("note");
                                                        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"})
                                                    }
                                                }
                                                }
                                            >
                                                {wordsWithLang.cvApp.cvAppForms.choseTemplate}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </AccordionDetails>
                    </Accordion>
                    {/*</Grid>*/}

                </Grid>
{/*accordions end*/}

            </Grid>
            <DeleteDialog open={deleteAvatarDialogStatus} onClose={() => (setDeleteAvatarDialogStatus(false))}
                          onYes={() => (onDeleteAvatar())} question={wordsWithLang.cvApp.deleteConfirmQuestionAvatar}
                          header={wordsWithLang.cvApp.deleteConfirm}/>

            <Dialog open={openCropper} onClose={() => (setOpenCropper(false))} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle>{wordsWithLang.general.cropImage}</DialogTitle>
                <DialogContent>
                    <CropperComponent imageSrc={selectedFile} onCropComplete={handleCropComplete} wordsWithLang={wordsWithLang} handleClose={handleFileChange} />
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default CvOrderEdit;



