import React, {useEffect, useRef, useState} from 'react';
import VacancyItem from "../components/vacancyItem";
import {Box, Button, Grid, Stack, useMediaQuery, useTheme,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";
import {words} from "../words";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {setDocDesc, setDocTitle} from "../utils/Utils";
import ReactVisibilitySensor from "react-visibility-sensor";
import useInfiniteScroll from "react-easy-infinite-scroll-hook";
import GoUpButton from "../components/goUpButton";


const Vacancy = ({wordsWithLang}) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const mount = useRef(false)
    const refer = useRef();
    const vacancySelector = useSelector(state => state.vacancy)
    const dispatcher = useDispatch()
    const userConfig = useSelector(state => state.userConfig)
    const mainConfig = useSelector(state => state.mainConfig)
    const searchState = useSelector(state => state.search)
    const prevSearchState = useSelector(state => state.prevSearch)
    const cvAppState = useSelector(state => state.cvAppConfig)

    const location = useLocation();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {slug} = useParams();
    const [isVisibleCount, setIsVisibleCount] = useState(0)
    const [importantStyleWidth, setImportantStyleWidth] = useState(null)
    const [randomNum, setRandomNum] = useState(Math.floor(Math.random() * (3 - 1 + 1) + 1))


    const importantStyle3 = {
        display: "block",
        height: "100px",
        width: "100%",
        // backgroundColor: "lightgray",
        backgroundImage: "url('/img/middRek" + randomNum + ".gif')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        margin: 20
    };

    const getVacanciesV2 = (reset, render) => {
        if (reset) {
            dispatcher({type: "RESET_PAGE_AND_VACANCIES_AND_HASMORE", payload: null})
            setIsVisibleCount(0)
            axios.post(apiUrls.baseApiUrl + apiUrls.filterVacancies + vacancySelector.page, searchState).then((resp) => {
                if (resp.data.length === 0 || resp.data.length < 20) {
                    dispatcher({type: "SET_HASMORE", payload: false})
                }
                dispatcher({
                    type: "ADD_VACANCIES_AND_NEXT_PAGE",
                    payload: {arr: resp.data, page: vacancySelector.page + 1}
                })
            })
        } else {
            axios.post(apiUrls.baseApiUrl + apiUrls.filterVacancies + vacancySelector.page, searchState).then((resp) => {
                if (resp.data.length === 0 || resp.data.length < 20) {
                    dispatcher({type: "SET_HASMORE", payload: false})
                }
                dispatcher({
                    type: "ADD_VACANCIES_AND_NEXT_PAGE",
                    payload: {arr: resp.data, page: vacancySelector.page + 1}
                })
            })
        }

    }

    // useEffect(() => {
    //     console.log(window.location.pathname+window.location.search)
    //     navigate(window.location.pathname+window.location.search);
    //     window.addEventListener('popstate', ()=>{navigate(-1)});
    // },[])

    useEffect(() => {

        if (window.innerWidth < 600) {
            setImportantStyleWidth(500)
        }
        if (window.innerWidth > 600) {
            setImportantStyleWidth(700)
        }
        // if (window.innerWidth>900) {
        //     setImportantStyleWidth(600)
        // }
        // if (window.innerWidth>1200) {
        //     setImportantStyleWidth(800)

    }, [importantStyleWidth])


    useEffect(() => {
        if (location.pathname.split("/")[1] === "") {
            // dispatcher({type: "SET_DISPLAY_FILTER_AND_PAGE", payload: false})
            if (vacancySelector.page === 1) {
                setDocTitle(wordsWithLang.general.documentTitle)
                setDocDesc(wordsWithLang.general.documentDesc)
            }
        } else {
            if (location.pathname.split("/")[1] === "company") {
                dispatcher({type: "SET_COMPANY", payload: slug})
                dispatcher({type: "SET_DISPLAY_FILTER_AND_PAGE", payload: true})
            }
            if (location.pathname.split("/")[1] === "category") {
                dispatcher({type: "SET_CATEGORY", payload: slug})
                dispatcher({type: "SET_DISPLAY_FILTER_AND_PAGE", payload: true})
            }
        }
    }, [location.pathname])

    useEffect(() => {
        // ref.current.scrollTop = vacancySelector.vacancyScroll
        refer.current.scrollTop = vacancySelector.vacancyScroll
        if (JSON.stringify(prevSearchState) !== JSON.stringify(searchState)) {
            dispatcher({type: "UPDATE_SEARCH", payload: searchState})
            getVacanciesV2(!mount.current, true);
        }
    }, [searchState.company, searchState.category, searchState.term, searchState.date, searchState.area, searchState.workDay])


    const ref = useInfiniteScroll({
        // Function to fetch more items
        getVacanciesV2,
        // The number of items loaded if you use the "Y-scroll" axis ("up" and "down")
        // if you are using the "X-scroll" axis ("left" and "right") use "columnCount" instead
        // you can also use "rowCount" and "columnCount" if you use "Y-scroll" and "X-scroll" at the same time
        rowCount: vacancySelector.vacancies.length,
        // Whether there are more items to load
        // if marked "true" in the specified direction, it will try to load more items if the threshold is reached
        // support for all directions "up", "down", "left", "right", both individually and in all directions at the same time
        hasMore: vacancySelector.hasMore,
    });

    const [showButton, setShowButton] = useState( false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowButton(window.localStorage.getItem('showCVButton') === "true");
        }, 1000); // Check every second

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (

        <Grid container
              ref={refer}

              justifyContent={"center"}
              style={{
                  // maxHeight:"80vh",overflow:"auto",
                  padding: 10,
                  marginTop: mainConfig.isFilterOpen && isMobile ? 230 : 20
              }}>

            <Box justifyContent={isMobile?"center":"flex-start"} style={{marginTop: "-40px", marginBottom: "20px",  width:isMobile?"80%":"100%"}}>
                <Button variant={"contained"}
                    className={"btn"}
                        style={{ display: showButton===true ? "block" : "none",width:isMobile?"100%":"180px"}}
                onClick={()=> {
                    if (!userConfig.isLogin) {
                        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
                    } else {
                        navigate("/cv")
                    }
                }}>
                {wordsWithLang.cvApp.createCv}</Button>

            </Box>
            {vacancySelector.vacancies.map((item, k) =>
                (k === 12 || k === 30 || k === 50 ? (
                    <Box key={k + "i"} style={{width: "100%"}}><Box component={Stack} direction="row"
                                                                    justifyContent="center">
                        <div onClick={() => (window.open("https://is.gd/middRek" + randomNum))}
                             style={importantStyle3}></div>
                        {/*<video style={importantStyle3} onClick={()=>(window.open("https://tinyurl.com/middRek"+randomNum))} src={"https://bakujobs.az/img/middRek"+randomNum+".gif"} controls playsinline muted autoPlay loop></video>*/}
                    </Box><VacancyItem key={k} item={item} wordsWithLang={wordsWithLang}/></Box>) : (<VacancyItem key={k} item={item} wordsWithLang={wordsWithLang}/>))
            )}

            <Grid item xs={12}>
                <ReactVisibilitySensor onChange={(isVisible) => {
                    setIsVisibleCount(isVisibleCount + 1)
                    if (isVisible && isVisibleCount !== 0 && vacancySelector.hasMore) {
                        getVacanciesV2(false, false)
                    }
                }}>
                    <Button size={"small"}
                            style={{
                                color: "gray",
                                opacity: 0.5
                            }}>{vacancySelector.hasMore ? wordsWithLang.general.loading : wordsWithLang.general.loaded}</Button>
                </ReactVisibilitySensor>

            </Grid>

            <GoUpButton/>

        </Grid>

    );
};

export default Vacancy;
