import React from 'react';
import {Box, Grid, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import {AutoAwesome, DateRangeOutlined, Diamond, Favorite, Share, Visibility} from "@mui/icons-material";
import {words} from "../words";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CsChip from "../miniComponents/CsChip";
import {companyLogo, dateCorrector, favoriteHandler, shareHandler} from "../utils/Utils";
import axios from "axios";
import apiUrls from "../apiUrls";

const VacancyItem = ({item,wordsWithLang}) => {
    const theme = useTheme()
    const navigator = useNavigate();
    const dispatcher = useDispatch();
    const userConfig = useSelector(state => state.userConfig);
    const adminConfig = useSelector(state => state.adminConfig);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const vacancyItem = item ? item :
        {
            id: "",
            source: "",
            company: {
                id: "",
                name: "",
                logo: "",
                slug: "",
                vacancyCount: "",
            },
            category: {
                id: "",
                logo: "",
                title: "",
                slug: "",
                vacancyCount: "",
            },
            title: "",
            content: "",
            salary: "",
            slug: "",
            actionDataType: "",

            isNew: "",
            isVip: "",
            isFavorite: "",
            deadline: "",
            created: "",
            viewCount: "0",
        }

        const vacancyItemClick = (e) => {
            e.stopPropagation();
            if (e.shiftKey) {
                axios.get(apiUrls.baseApiUrl+apiUrls.amIAdmin, {withCredentials:true}).then(resp=>{
                    if(resp.data){
                        dispatcher({type:"OPEN_ADMIN_DIALOG", payload:{adminDialogState:true, adminVacId:vacancyItem.id}})
                    }
                })
            }else {
                dispatcher({type: "SET_VACANCY", payload: vacancyItem});
                navigator("/vacancy/" + vacancyItem.slug + "/" + vacancyItem.id)
            }
        }


    return (
        <Grid item xs={12} className={"vacancyCard"} style={{backgroundColor:vacancyItem.isVip?"#DBD5FD":"white", marginTop:"2px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} onClick={vacancyItemClick}>
                <Grid item xs={2} sm={1} style={{minWidth: 70, maxWidth: 70}}>
                    {vacancyItem.company.miniLogo ?
                        <Grid item xs={9} className={"vacancyItemImg"}
                              style={{backgroundColor:"white",backgroundImage: "url('" + companyLogo(vacancyItem) + "')",}}>
                        </Grid> : <Grid item xs={9} className={"vacancyItemImg"}
                                        style={{backgroundColor:"white",backgroundImage: "url('/img/company.png')", backgroundSize:"40px",}}>
                        {/*><Typography style={{fontSize: 30, color: "darkgray"}} align={"center"}>*/}
                        {/*    {vacancyItem.company.name.charAt(0).toUpperCase()}*/}
                        {/*</Typography>*/}
                        </Grid>}
                </Grid>
                <Grid item xs={9} sm={9} md={3} onClick={vacancyItemClick}>
                    <Grid container onClick={vacancyItemClick}>
                        <Grid item xs={12} style={{padding: "10px 0px"}}  >
                            <Typography color={"textPrimary"} variant={"h6"} style={{fontWeight:isMobile?"bold":"normal", fontSize: isMobile?"1.3rem":"1.1rem",color: vacancyItem.isVip?"#31064A":null}}  >{vacancyItem.title}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 5}}>
                            <Link to={"/company/" + vacancyItem.company.slug} onClick={(e)=>e.stopPropagation()}>
                                <Typography
                                color={"primary"}
                                variant={"caption"}
                                style={{fontSize: 11,}}
                                onMouseEnter={(e)=>{e.target.style.color=theme.palette.secondary.main}}
                                onMouseLeave={(e)=>{e.target.style.color=theme.palette.primary.main}}
                                >{vacancyItem.company.name}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={5}>
                            <Grid container justifyContent={"flex-end"}>
                                <CsChip
                                    icon={<DateRangeOutlined style={{fontSize:18}} titleAccess={wordsWithLang.vacancyItem.date}  />}
                                        color={theme.palette.primary.main} textColor={"white"} label={dateCorrector(vacancyItem.created,wordsWithLang)}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid container justifyContent={"center"}>
                                <Box style={{alignItems: "center", backgroundColor: "darkgray", height: 30, width: 1}}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container justifyContent={"flex-start"}>
                                <CsChip
                                    icon={<Visibility style={{fontSize:18}} titleAccess={wordsWithLang.vacancyItem.view}/>}
                                    color={theme.palette.primary.main} textColor={"white"} label={vacancyItem.viewCount ? vacancyItem.viewCount : 0}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid container justifyContent={"center"} alignItems={"center"}>
                                {/*<Money/>*/}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>


                <Grid item xs={6} sm={3}>
                    <Grid container spacing={2} alignItems={"center"} justifyContent={"flex-end"} direction={"row"}>
                        <Grid item xs={3}>
                            <Grid container justifyContent={"flex-end"}>
                                <IconButton color={"primary"} onClick={(e) =>  shareHandler(dispatcher, "https://bakujobs.az/vacancy/"+vacancyItem.slug+"/"+vacancyItem.id,e, vacancyItem)}>
                                    <Share fontSize={isMobile?"large":"medium"} titleAccess={wordsWithLang.vacancyItem.share}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container justifyContent={"flex-end"}>
                                <IconButton color={userConfig.favorites && userConfig.favorites.includes(vacancyItem.id.toString())?"secondary":"default"} onClick={(e) => favoriteHandler(vacancyItem.id, dispatcher, userConfig,e)} >
                                    <Favorite fontSize={isMobile?"large":"medium"} titleAccess={wordsWithLang.vacancyItem.favorite}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container justifyContent={"flex-start"} direction={"row"} style={{paddingRight:1}} >
                                {vacancyItem.isVip ?
                                    <CsChip icon={
                                        <Diamond style={{
                                        fontSize: 15,
                                        marginRight:2
                                        // padding: 1,
                                    }}/>} color={theme.palette.warning.light} label={wordsWithLang.vacancyItem.premium} textColor={"white"}/>
                                    : null}
                                {vacancyItem.isNew ?
                                    <CsChip icon={
                                        <AutoAwesome style={{
                                        fontSize: 15,
                                        marginRight:2
                                        // padding: 1,
                                    }}/>} color={theme.palette.secondary.main} label={wordsWithLang.vacancyItem.new} textColor={"white"}/>
                                    : null}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>

    );
};

export default VacancyItem;
