import React, {useEffect, useState} from 'react';
import {Backdrop, Box, Button, Divider, Grid, styled, Typography} from "@mui/material";
import {ArrowBack, AutoFixHigh, TaskAlt} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import ColorPicker from "../../miniComponents/colorPicker";
import CvAppTemplateCard from "../../components/cvAppTemplateCard";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {useNavigate} from "react-router-dom";

const CvAppTemplate = ({wordsWithLang}) => {
    const isMobile = window.screen.width <= 700
    const dispatcher = useDispatch()
    const cvAppConfigCvData = useSelector(state => state.cvAppConfig.cvData)
    const [color, setColor] = useState(cvAppConfigCvData.mainColor !== null ? cvAppConfigCvData.mainColor : "#3F51B5");
    const [isLoading, setIsLoading] = useState(false);
    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
    const navigate = useNavigate();

    const [templates, setTemplates] = useState([
        // {
        //     id: "1",
        //     productType: "CV_TEMPLATE",
        //     lang: "az",
        //     currency: "azn",
        //     name: "Deep web hello purple",
        //     title: "Numune-01 adli numune shablon",
        //     description: "Numune-01 adli numune shablonun aciqlamasi burada olacaq barede",
        //     previewUrl: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png",
        //     filePath: "",
        //     createDate: "",
        //     badge: "",
        //     price: "20",
        //     discount: "20",
        //     specs: "",
        //     status: "",
        // }
    ])


    const selectTemplate = (id) => {
        let tempData = cvAppConfigCvData
        tempData.templateId = id
        dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
        updateCvDataInServer("templateId")
        dispatcher({type: "SELECTED_CV_UPDATE", payload: templates.filter(template => template.id === id)[0]})
    }


    const updateColor = (customColor) => {
        if(customColor === null){
            setColor("#3F51B5")
            let tempData = {...cvAppConfigCvData, mainColor: "#3F51B5"}
            dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
        }
        else {
            setColor(customColor)
            let tempData = {...cvAppConfigCvData, mainColor: customColor}
            dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
        }

    }

    const updateCvDataInServer = (comeForUpdate) => {
        axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), cvAppConfigCvData,
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(resp => {

        })
    }

    const continueCvGen = () => {
        setIsLoading(true)
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{CvOrderId}", newCvOrder.id).replace("{productId}", cvAppConfigCvData.templateId),
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(resp1 => {
            dispatcher({type: "CV_DATA_UPDATE", payload: resp1.data.cvData})
            dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp1.data})
            setColor(resp1.data.cvData.mainColor)

            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGeneratePreviewOfCv.replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(resp => {

                // dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                // dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})

                setIsLoading(false)
                dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "preview"})
            })
        })


        // --------send data to server. for set template id then generate cv
        // --------then get newInstance from server and update

    }

    const BlinkingTypography = styled(Typography)`
        @keyframes blink {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        animation: blink 3s linear infinite;
    `;

    useEffect(()=>{updateCvDataInServer("mainColor")}, [cvAppConfigCvData])
    useEffect(()=>{updateColor(null)}, [])

    useEffect(() => {
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{productType}", "CV_TEMPLATE").replace("{lang}", newCvOrder.language), {withCredentials: true}).then(resp => {
            setTemplates(resp.data)
            if(cvAppConfigCvData.templateId!==null){
                dispatcher({type: "SELECTED_CV_UPDATE", payload: resp.data.filter(template => template.id === cvAppConfigCvData.templateId)[0]})
            }
        })
    }, [])

    useEffect(() => {
        // navigate('/cv/#');
        window.addEventListener('popstate', ()=>{dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})});
    },[])
    return (
        <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    style={{marginLeft: 23}}
                    onClick={() => dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})}
                >
                    <ArrowBack fontSize="small"/>
                    <Typography style={{marginLeft: 3, fontSize: isMobile ? "20px" : "15px"}} variant="button">
                        {wordsWithLang.vacancyBody.back}
                    </Typography>
                </Button>
            </Grid>
            <Grid container justifyContent="center" style={{margin: "10px", marginTop: "25px"}}>
                <Grid item xs={12}>
                    <Typography fontSize={isMobile ? "25px" : "15"}
                                ml={5}>{wordsWithLang.cvApp.cvAppForms.choseColor}</Typography>
                    <ColorPicker onColorSelect={updateColor}/>
                    {color && (
                        <Box mt={2}>
                            {/*<Typography fontSize={isMobile?"25px":"15"} mb={1} ml={5} mt={2}>{wordsWithLang.cvApp.cvAppForms.chosenColor}</Typography>*/}
                            <Box
                                mr={1}
                                width={"25%"}
                                height={35}
                                onClick={() => {
                                    updateColor(color)
                                }}
                                style={{
                                    transition: 'all .3s',
                                    boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
                                    backgroundColor: color, margin: '0 auto', borderRadius: '35px', left: "10%"
                                }}
                            ><TaskAlt fontSize={"large"} style={{color: "#ffffff", marginLeft: "3px"}}/></Box>
                        </Box>
                    )}
                    <Typography fontSize={isMobile ? "20px" : "11"} style={{color: "gray"}}
                                m={5}>{wordsWithLang.cvApp.cvAppForms.nextPageColored}</Typography>

                </Grid>
                <Grid item xs={12} style={{margin: 20}}>
                    <Divider/>
                </Grid>
                <Grid item xs={11} style={{margin: 20}}>
                    <Typography ml={2}>{wordsWithLang.cvApp.cvAppForms.choseTemplate}</Typography>
                    <Grid container justifyContent={"center"}>
                        {templates.map((temp) => (
                            <CvAppTemplateCard key={temp.id} template={temp} selectTemplate={selectTemplate}
                                               selectedTemplate={cvAppConfigCvData.templateId}/>
                        ))}
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                </Grid>

                {/*<Grid item xs={12} md={6} lg={6} p={3 }>*/}
                {/*    <Button*/}
                {/*        variant="contained"*/}
                {/*        startIcon={<AutoFixHigh fontSize="medium" style={{color: 'white'}}/>}*/}
                {/*        className="addButton continueButton btn"*/}
                {/*        size="small"*/}
                {/*        style={{width: '100%', marginTop: 15}}*/}
                {/*        onClick={()=>{*/}
                {/*            if(cvAppConfigCvData.cvTemplateId === "" || cvAppConfigCvData.cvTemplateId === null){*/}
                {/*                dispatcher({*/}
                {/*                    type: "OPEN_SNACK",*/}
                {/*                    payload: {"isSnackOpen": true, snackType: "error", snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.templateNotChosen}*/}
                {/*                })*/}
                {/*                }*/}
                {/*            else {*/}

                {/*            }*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {wordsWithLang.cvApp.cvAppForms.complete}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}


                {/*<Grid item justifyContent="center" display={"flex"} xs={2} style={{margin: "30px"}}>*/}
                {/*</Grid>*/}

            </Grid>

            <Grid container justifyContent={"center"}
                  style={{
                      boxShadow: "0 5px 5px rgba(0,0,0,.08), 0 0 6px rgba(21, 101, 192, 0.3)",
                      position: isMobile !== null ? 'fixed' : 'static',
                      bottom: isMobile !== null ? 0 : 'auto',
                      left: isMobile !== null ? 0 : 'auto',
                      width: isMobile !== null ? '100%' : 'auto',
                      zIndex: isMobile !== null ? 999 : 'auto',
                      backgroundColor: "white"
                  }}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {/*<Grid item xs={10}>*/}
                {/*    <Grid container>*/}
                {/*        <Typography>Yekun mebleg: <span>{totalPrice()}</span></Typography>*/}
                {/*    </Grid>*/}

                {/*</Grid>*/}
                <Grid item xs={12} md={6}>
                    <Button
                        variant="contained"
                        startIcon={<AutoFixHigh fontSize="medium" style={{color: 'white'}}/>}
                        className={cvAppConfigCvData.templateId ? "addButton continueButton btn" : "disabledButton continueButton btn"}
                        size="small"
                        style={{width: isMobile !== null ? '100%' : '65%', marginTop: 15, marginBottom: 15}}
                        onClick={() => {
                            if (cvAppConfigCvData.templateId === "" || cvAppConfigCvData.templateId === null) {
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        isSnackOpen: true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.templateNotChosen
                                    }
                                });
                            } else {
                                continueCvGen();
                            }
                        }}
                    >
                        {wordsWithLang.cvApp.cvAppForms.complete}
                    </Button>
                </Grid>
            </Grid>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1300}}
                open={isLoading}
            >
                {/*<CircularProgress color="inherit"/><br/>*/}
                <div className="scan-container">
                    <img src="https://bakujobs.az/img/cv.svg" alt="Document" width={200} height={200}/>
                    <div className="scan-line"></div>
                    <BlinkingTypography align={"center"}
                                        width={"100%"}>{wordsWithLang.cvApp.cvAppForms.cvGettingReady}</BlinkingTypography>
                </div>
            </Backdrop>
        </Grid>
    );
};

export default CvAppTemplate;
