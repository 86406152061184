import React, {useRef} from 'react';
import {useTheme} from "@mui/material";
import {ArrowForwardIos} from "@mui/icons-material";

const GoUpButton = (props) => {
    const toUpRef = useRef();
    const theme = useTheme();
    document.addEventListener("scroll", () => {
        if (toUpRef.current) {
            if (window.scrollY > 100) {
                toUpRef.current.style.visibility = "visible"
                toUpRef.current.style.opacity = "1"
            } else {
                toUpRef.current.style.visibility = "hidden"
                toUpRef.current.style.opacity = "0"
            }
        }
    })
    return (
        <div
            // ref={props.toUpRef}
            ref={toUpRef}
            className={"vacancyCard"} style={{
            display: "block",
            position: "fixed",
            width: 60,
            height: 60,
            zIndex: theme.zIndex.drawer + 1 ,
            right: "100px",
            bottom: "25px",
            backgroundColor: useTheme().palette.primary.main,
            borderRadius: "100px",
            transition: "visibility 1s, opacity 1s",
            visibility: "hidden", opacity: "0"
        }}
            onClick={() => {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }}>
            <ArrowForwardIos fontSize={"large"}
                             style={{
                                 position: "relative",
                                 rotate: "-90deg",
                                 left: "20%",
                                 top: "17%",
                                 color: "white"
                             }}/>
        </div>
    );
};

export default GoUpButton;
