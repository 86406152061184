import React, {useState} from 'react';
import {Button, Divider, Grid, TextField} from "@mui/material";
import axios from "axios";
import apiUrls from "../apiUrls";

const AddEditBlogDialogPart = () => {
    const [blogPost, setBlogPost] = useState({
        id: "",
        blogPost: "",
        gTitle: "",
        gDesc: "",
        category: "",
        blogPostImageSlug:"",
        slug: "",
        viewCount: "",
        addDate: "",
        keywords:"",
        more: []
    })

    const [secCode, setSecCode] = useState("")


    const changeForm = (e) => {
        switch (e.target.id) {
            case "id": {
                setBlogPost({...blogPost, id: e.target.value})
                break;
            }
            case "blogPost": {
                setBlogPost({...blogPost, blogPost: e.target.value})
                break;
            }
            case "gTitle": {
                setBlogPost({...blogPost, gTitle: e.target.value})
                break;
            }
            case "gDesc": {
                setBlogPost({...blogPost, gDesc: e.target.value})
                break;
            }
            case "category": {
                setBlogPost({...blogPost, category: e.target.value})
                break;
            }
            case "blogPostImageSlug": {
                setBlogPost({...blogPost, blogPostImageSlug: e.target.value})
                break;
            }
            case "slug": {
                setBlogPost({...blogPost, slug: e.target.value})
                break;
            }
            case "viewCount": {
                setBlogPost({...blogPost, viewCount: e.target.value})
                break;
            }
            case "addDate": {
                setBlogPost({...blogPost, addDate: e.target.value})
                break;
            }
            case "secCode": {
                setSecCode(e.target.value)
                break;
            }
            case "keywords": {
                setBlogPost({...blogPost, keywords: e.target.value})
                break;
            }
        }
    };
    const saveBlogPost = () => {
        axios.post(apiUrls.baseApiUrl + apiUrls.addBlog, blogPost,
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                console.log(blogPost)
                setBlogPost(resp.data)

            }
        )
    }

    const removeBlogPost = () => {
        axios.get(apiUrls.baseApiUrl + apiUrls.removeBlog.replace(":id", blogPost.id).replace(":secCode", "995511"),
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                console.log(blogPost)
                setBlogPost(resp.data)

            }
        )
    }

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFilesChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };
    const formData = new FormData();
    const handleUpload = () => {
        // Do something with the selected files
        if (selectedFiles.length > 0) {
            console.log('Selected files:', selectedFiles);

            selectedFiles.forEach((file) => {
                formData.append('files', file);
            });
            axios.post(apiUrls.baseApiUrl + apiUrls.postBlogImage, formData, {
                withCredentials: true,
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Content-Type': 'multipart/form-data'
                }

            })
                .then(resp => {
                    alert(resp.data)
                })
        }
        ;}

    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={8} style={{marginTop: 20}}>
                {/*<Button onClick={saveBlogPost} color={"secondary"} variant={"outlined"}>Save Blogpost</Button>*/}
                <TextField margin={"dense"} fullWidth label={"secCode"}
                           type={"password"} value={secCode} id="secCode" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"id"}
                           value={blogPost.id ? blogPost.id : ""} id="id" onChange={(e) => {
                    changeForm(e)
                }}/>
                <Button onClick={removeBlogPost} color={"secondary"} variant={"outlined"}>Delete Blogpost</Button>
                {/*<TextField fullWidth label={"source"} value={blogPost.source?blogPost.source:""} id="source" onChange={(e)=>{changeForm(e)}} />*/}

                <br/><br/>
                <br/><br/>

                <TextField margin={"dense"} fullWidth label={"id"}
                           value={blogPost.id ? blogPost.id : ""} id="id"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"blogPost"}
                           value={blogPost.blogPost ? blogPost.blogPost : ""} id="blogPost"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField multiline margin={"dense"} fullWidth label={"blogPostImageSlug (full image url)"}
                           value={blogPost.blogPostImageSlug ? blogPost.blogPostImageSlug : ""} id="blogPostImageSlug"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField multiline margin={"dense"} fullWidth label={"gTitle"}
                           value={blogPost.gTitle ? blogPost.gTitle : ""} id="gTitle" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField multiline maxRows={10} margin={"gDesc"} fullWidth label={"gDesc"}
                           value={blogPost.gDesc ? blogPost.gDesc : ""} id="gDesc" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"category"}
                           value={blogPost.category ? blogPost.category : ""} id="category"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"slug"}
                           value={blogPost.slug ? blogPost.slug : ""} id="slug" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"viewCount"}
                           value={blogPost.viewCount ? blogPost.viewCount : ""} id="viewCount"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"keywords with comma"}
                           value={blogPost.keywords ? blogPost.keywords : ""} id="keywords"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                {/*<TextField margin={"dense"} fullWidth label={"addDate"}*/}
                {/*           value={blogPost.addDate ? blogPost.addDate : ""} id="addDate"*/}
                {/*           onChange={(e) => {*/}
                {/*               changeForm(e)*/}
                {/*           }}/>*/}
                {/*<TextField margin={"dense"} fullWidth label={"more"}*/}
                {/*           value={blogPost.more ? blogPost.more : ""} id="more" onChange={(e) => {*/}
                {/*    changeForm(e)*/}
                {/*}}/>*/}
                <div>
                    <input type="file" multiple onChange={handleFilesChange} />
                    <button onClick={handleUpload}>Upload Blog Image</button>
                </div>
                <br/><br/>


                <Divider style={{marginTop: 20, marginBottom: 20}}/>

                <Button onClick={saveBlogPost} color={"secondary"} variant={"outlined"}>Save BlogPost</Button>
            </Grid>

        </Grid>
    );
}

export default AddEditBlogDialogPart;
