import React from 'react';
import {Dialog, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import LoginRegister from "../components/loginRegister";
import LoginLogin from "../components/loginLogin";


const LoginDialog = ({wordsWithLang}) => {
    const mainConfig = useSelector(state => state.mainConfig)
    const dialogPage = mainConfig.loginDialogPage


    // const [registerUser, setRegisterUser] = useState({
    //     age: "18-23",
    //     gender: "male",
    //     name: "",
    //     email: "",
    //     phoneNumber: "",
    //     businessCategorySlug: "all",
    //     pass: "",
    //     repass: "",
    // })
    // const [errorEmail, setErrorEmail] = useState("")
    // const [errorPass, setErrorPass] = useState("")


    const dispatcher = useDispatch()
    const theme = useTheme();
    const handleClose = () => {
        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 1})
    };

    // function containsAnyLetters(str) {
    //     return /[a-zA-Z]/.test(str);
    // }

    // const formChange = (e) => {
    //     switch (e.target.id) {
    //         case "name" : {
    //             setRegisterUser({...registerUser, name: e.target.value});
    //             return true;
    //         }
    //         case "email" : {
    //             setRegisterUser({...registerUser, email: e.target.value});
    //             return true;
    //         }
    //         case "age" : {
    //             setRegisterUser({...registerUser, age: e.target.value});
    //             return true;
    //         }
    //         case "phoneNumber" : {
    //             setRegisterUser({...registerUser, phoneNumber: e.target.value});
    //             return true;
    //         }
    //         case "gender" : {
    //             setRegisterUser({...registerUser, gender: e.target.value});
    //             return true;
    //         }
    //         case "pass" : {
    //             setRegisterUser({...registerUser, pass: e.target.value});
    //             return true;
    //         }
    //         case "repass" : {
    //             setRegisterUser({...registerUser, repass: e.target.value});
    //             return true;
    //         }
    //     }
    //     switch (e.target.name) {
    //         case "businessCategorySlug" : {
    //             setRegisterUser({...registerUser, businessCategorySlug: e.target.value});
    //             return true;
    //         }
    //     }
    // }
    //
    // const checkEmailIsValid = () => {
    //     return true;
    // }
    //
    // const customValidator = () => {
    //     let isItOkay = true;
    //     if (registerUser.password === "") {
    //         setErrorPass(words.loginDialog.errorPass)
    //         return false;
    //     }
    //     if (!checkEmailIsValid()) {
    //         setErrorEmail(words.loginDialog.errorUsedEmail)
    //         return false;
    //     }
    //     return isItOkay;
    // }

    // const changeLoginRegister = () => {
    //     if (dialogPage === 1) {
    //         dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0})
    //     } else if (dialogPage === 0) {
    //         dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 1})
    //     }
    // }
    //
    // const continueRegister = () => {
    //     if (customValidator()) {
    //         dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 2})
    //     }
    // }
    //
    // const doneRegister = () => {
    //     dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 3})
    //
    // }


    // const initComponent = () => {
    //     setRegisterUser({
    //         age: "18-23",
    //         gender: "male",
    //         name: "",
    //         email: "",
    //         phoneNumber: "",
    //         businessCategorySlug: "all",
    //         pass: "",
    //         repass: "",
    //     })
    //     axios.get(apiUrls.baseApiUrl + apiUrls.getCategories).then(resp => {
    //         let arr = [];
    //         arr.push({catName: words.general.other, catSlug: "all"})
    //         resp.data.sort((a, b) => a.pop - b.pop);
    //         resp.data.map((item, i) => arr.push({catName: item.category, catSlug: item.slug}))
    //         setCategories(arr)
    //         // dispatcher({type: "SET_CATEGORIES", payload: arr})
    //     })
    // }
    //
    // useEffect(initComponent, [mainConfig.isLoginOpen])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={mainConfig.isLoginOpen}
            onClose={handleClose}
            PaperProps={{
                style: {borderRadius: 15}
            }}
        >
            {dialogPage === 0 ? (
                <LoginLogin theme={theme} wordsWithLang={wordsWithLang}/>
            ) : dialogPage === 1 ? (
                <LoginRegister theme={theme} wordsWithLang={wordsWithLang}/>
            ) :(<div>ERROR</div>  )}
        </Dialog>
    );
};

export default LoginDialog;
