import React, {useState} from 'react';
import {Button, Divider, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import axios from "axios";
import apiUrls from "../apiUrls";
import {useDispatch, useSelector} from "react-redux";
import {words} from "../words";

const NewVacancyDialogPart = () => {
    const configState = useSelector(searchState => searchState.mainConfig)
    const [selectedCategory, setSelectedCategory] = useState("")
    const [vacancyItemAdmin, setVacancyItemAdmin] = useState({
        id: "",
        srcId: "",
        source: "",
        company: {
            name: "",
            slug: "",
            logo: "",
            miniLogo: "",
            vacancyCount: "",
            info: "",
            address: "",
            email: "",
            phones: [],
            sites: [],
        },
        category: "",
        title: "",
        content: "",
        salary: "",
        slug: "",
        actionDataType: "email",
        actionData: "",

        workTime: "full-time",
        area: "Bakı",

        isNew: "true",
        isVip: "",
        isFavorite: "",
        deadline: "",
        created: "",
        viewCount: "",
        isActive: "true",
    })

    const [companies, setCompanies] = useState([]);

    const [companyItem, setCompanyItem] = useState({
        name: "",
        slug: "",
        logo: "",
        miniLogo: "",
        vacancyCount: "",
        info: "",
        address: "",
        email: "",
        phones: [],
        sites: [],
    })
    const changeForm = (e) => {
        switch (e.target.id) {

            case "id": {
                setVacancyItemAdmin({...vacancyItemAdmin, id: e.target.value})
                break;
            }
            case "srcId": {
                setVacancyItemAdmin({...vacancyItemAdmin, srcId: e.target.value})
                break;
            }
            case "source": {
                setVacancyItemAdmin({...vacancyItemAdmin, source: e.target.value})
                break;
            }
            // case "company":{
            //     setVacancyItemAdmin({...vacancyItemAdmin,company:e.target.value})
            //     break;
            // }
            case "category": {
                // setVacancyItemAdmin({...vacancyItemAdmin,category:e.target.value})
                setSelectedCategory(e.target.value)
                axios.get(apiUrls.baseApiUrl + apiUrls.getAdminCategories,
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp1 => {
                        const result = resp1.data.filter(cat => cat.slug === e.target.value)[0];
                        setVacancyItemAdmin({...vacancyItemAdmin, category: result})
                    })
                break;
            }
            case "title": {
                setVacancyItemAdmin({...vacancyItemAdmin, title: e.target.value})
                break;
            }
            case "content": {
                setVacancyItemAdmin({...vacancyItemAdmin, content: e.target.value})
                break;
            }
            case "salary": {
                setVacancyItemAdmin({...vacancyItemAdmin, salary: e.target.value})
                break;
            }
            case "slug": {
                setVacancyItemAdmin({...vacancyItemAdmin, slug: e.target.value})
                break;
            }
            case "actionDataType": {
                setVacancyItemAdmin({...vacancyItemAdmin, actionDataType: e.target.value})
                break;
            }
            case "actionData": {
                setVacancyItemAdmin({...vacancyItemAdmin, actionData: e.target.value})
                break;
            }
            case "workTime": {
                setVacancyItemAdmin({...vacancyItemAdmin, workTime: e.target.value})
                break;
            }
            case "area": {
                setVacancyItemAdmin({...vacancyItemAdmin, area: e.target.value})
                break;
            }
            case "isNew": {
                setVacancyItemAdmin({...vacancyItemAdmin, isNew: e.target.value})
                break;
            }
            case "isVip": {
                setVacancyItemAdmin({...vacancyItemAdmin, isVip: e.target.value})
                break;
            }
            case "isFavorite": {
                setVacancyItemAdmin({...vacancyItemAdmin, isFavorite: e.target.value})
                break;
            }
            case "deadline": {
                setVacancyItemAdmin({...vacancyItemAdmin, deadline: e.target.value})
                break;
            }
            case "created": {
                setVacancyItemAdmin({...vacancyItemAdmin, created: e.target.value})
                break;
            }
            case "viewCount": {
                setVacancyItemAdmin({...vacancyItemAdmin, viewCount: e.target.value})
                break;
            }
            case "isActive": {
                setVacancyItemAdmin({...vacancyItemAdmin, isActive: e.target.value})
                break;
            }


            case "company-name": {
                setVacancyItemAdmin({...vacancyItemAdmin, company: {...vacancyItemAdmin.company, name: e.target.value}})
                break;
            }
            case "company-slug": {
                setVacancyItemAdmin({...vacancyItemAdmin, company: {...vacancyItemAdmin.company, slug: e.target.value}})
                break;
            }
            case "company-logo": {
                setVacancyItemAdmin({...vacancyItemAdmin, company: {...vacancyItemAdmin.company, logo: e.target.value}})
                break;
            }
            case "company-miniLogo": {
                setVacancyItemAdmin({
                    ...vacancyItemAdmin,
                    company: {...vacancyItemAdmin.company, miniLogo: e.target.value}
                })
                break;
            }
            case "company-vacancyCount": {
                setVacancyItemAdmin({
                    ...vacancyItemAdmin,
                    company: {...vacancyItemAdmin.company, vacancyCount: e.target.value}
                })
                break;
            }
            case "company-info": {
                setVacancyItemAdmin({...vacancyItemAdmin, company: {...vacancyItemAdmin.company, info: e.target.value}})
                break;
            }
            case "company-address": {
                setVacancyItemAdmin({
                    ...vacancyItemAdmin,
                    company: {...vacancyItemAdmin.company, address: e.target.value}
                })
                break;
            }
            case "company-email": {
                setVacancyItemAdmin({
                    ...vacancyItemAdmin,
                    company: {...vacancyItemAdmin.company, email: e.target.value}
                })
                break;
            }
            case "company-phones": {
                setVacancyItemAdmin({
                    ...vacancyItemAdmin,
                    company: {...vacancyItemAdmin.company, phones: e.target.value}
                })
                break;
            }
            case "company-sites": {
                setVacancyItemAdmin({
                    ...vacancyItemAdmin,
                    company: {...vacancyItemAdmin.company, sites: e.target.value}
                })
                break;
            }
            default: {
                return null;
            }
        }
    }
    const dispatcher = useDispatch()
    const saveVacancy = () => {
        axios.post(apiUrls.baseApiUrl + apiUrls.postAdminVacancy, vacancyItemAdmin,
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                console.log(vacancyItemAdmin)
                setVacancyItemAdmin(resp.data)
                dispatcher({
                    type: "OPEN_SNACK", payload: {
                        isSnackOpen: true,
                        snackType: resp.status === 200 ? "success" : "error",
                        snackMessage: resp.status === 200 ? "Vacancy Saved" : "Some Error."
                    }
                })
            }
        )
    }

    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={8} style={{marginTop: 20}}>
                <Button onClick={saveVacancy} color={"secondary"} variant={"outlined"}>Save New Vacancy</Button>
                {/*<TextField fullWidth label={"source"} value={vacancyItemAdmin.source?vacancyItemAdmin.source:""} id="source" onChange={(e)=>{changeForm(e)}} />*/}

                <br/><br/>
                <InputLabel id="actionDataType-label">actionDataType</InputLabel>
                <Select
                    labelId="actionDataType-label"
                    id="actionDataType"
                    value={vacancyItemAdmin.actionDataType ? vacancyItemAdmin.actionDataType : ""}
                    label="actionDataType"
                    onChange={(e) => {
                        // e.target.value = e.value
                        e.target.id = "actionDataType"
                        changeForm(e)
                    }}
                    fullWidth
                >
                    <MenuItem value={"email"}>Email</MenuItem>
                    <MenuItem value={"phoneNumber"}>Phone Number</MenuItem>
                    <MenuItem value={"link"}>Link</MenuItem>
                </Select>

                {/*<TextField style={{marginTop:10}} fullWidth label={"actionDataType"} value={vacancyItemAdmin.actionDataType?vacancyItemAdmin.actionDataType:""} id="actionDataType" onChange={(e)=>{changeForm(e)}} />*/}
                <TextField fullWidth label={"actionData"}
                           variant={"standard"}
                           value={vacancyItemAdmin.actionData ? vacancyItemAdmin.actionData : ""} id="actionData"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>

                <Divider style={{marginTop: 20, marginBottom: 20}}/>

                <InputLabel>{"category"}</InputLabel>
                {configState.categories.length > 1 ? (
                    <Select
                        fullWidth
                        id={"category"}
                        name={"categoryList"}
                        defaultValue={configState.categories[0]}
                        label={words.search.category}
                        value={selectedCategory}
                        onChange={(e) => {
                            e.target.id = "category"
                            changeForm(e)
                        }}
                    >
                        {configState.categories.map((item, i) => (
                            <MenuItem key={i} value={item.catSlug}>{item.catName}</MenuItem>
                        ))}
                    </Select>) : null}

                {/*<TextField margin={"dense"} disabled fullWidth label={"id"} value={vacancyItemAdmin.id?vacancyItemAdmin.id:""} id="id" onChange={(e)=>{changeForm(e)}} />*/}
                <TextField margin={"dense"} fullWidth label={"srcId"}
                           value={vacancyItemAdmin.srcId ? vacancyItemAdmin.srcId : ""} id="srcId" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"source"}
                           value={vacancyItemAdmin.source ? vacancyItemAdmin.source : ""} id="source" onChange={(e) => {
                    changeForm(e)
                }}/>
                {/*<TextField margin={"dense"} fullWidth label={"category"} value={vacancyItemAdmin.category?vacancyItemAdmin.category:"} id="category" onChange={(e)=>{changeForm(e)}} />*/}
                <TextField margin={"dense"} fullWidth label={"title"}
                           value={vacancyItemAdmin.title ? vacancyItemAdmin.title : ""} id="title" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField multiline maxRows={10} margin={"dense"} fullWidth label={"content"}
                           value={vacancyItemAdmin.content ? vacancyItemAdmin.content : ""} id="content"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"salary"}
                           value={vacancyItemAdmin.salary ? vacancyItemAdmin.salary : ""} id="salary" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"slug"}
                           value={vacancyItemAdmin.slug ? vacancyItemAdmin.slug : ""} id="slug" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"workTime"}
                           value={vacancyItemAdmin.workTime ? vacancyItemAdmin.workTime : ""} id="workTime"
                           helperText={"full-time / part-time / internship"}
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"area"}
                           value={vacancyItemAdmin.area ? vacancyItemAdmin.area : ""} id="area" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"isNew"}
                           value={vacancyItemAdmin.isNew ? vacancyItemAdmin.isNew : ""} id="isNew" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"isVip"}
                           value={vacancyItemAdmin.isVip ? vacancyItemAdmin.isVip : ""} id="isVip" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} fullWidth label={"isFavorite"}
                           value={vacancyItemAdmin.isFavorite ? vacancyItemAdmin.isFavorite : ""} id="isFavorite"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField placeholder={"2022-01-30"} margin={"dense"} fullWidth label={"deadline"}
                           value={vacancyItemAdmin.deadline ? vacancyItemAdmin.deadline : ""} id="deadline"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField placeholder={"2022-01-30"} margin={"dense"} fullWidth label={"created"}
                           value={vacancyItemAdmin.created ? vacancyItemAdmin.created : ""} id="created"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"viewCount"}
                           value={vacancyItemAdmin.viewCount ? vacancyItemAdmin.viewCount : ""} id="viewCount"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} fullWidth label={"isActive"}
                           value={vacancyItemAdmin.isActive ? vacancyItemAdmin.isActive : ""} id="isActive"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>


                <Divider style={{marginTop: 20, marginBottom: 20}}><Typography color={"primary"}
                                                                               variant={"caption"}>company</Typography></Divider>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"name"}
                           value={vacancyItemAdmin.company.name ? vacancyItemAdmin.company.name : ""} id="company-name"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"slug"}
                           value={vacancyItemAdmin.company.slug ? vacancyItemAdmin.company.slug : ""} id="company-slug"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"logo"}
                           value={vacancyItemAdmin.company.logo ? vacancyItemAdmin.company.logo : ""} id="company-logo"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"miniLogo"}
                           value={vacancyItemAdmin.company.miniLogo ? vacancyItemAdmin.company.miniLogo : ""}
                           id="company-miniLogo" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"vacancyCount"}
                           value={vacancyItemAdmin.company.vacancyCount ? vacancyItemAdmin.company.vacancyCount : ""}
                           id="company-vacancyCount" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"info"}
                           value={vacancyItemAdmin.company.info ? vacancyItemAdmin.company.info : ""} id="company-info"
                           onChange={(e) => {
                               changeForm(e)
                           }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"address"}
                           value={vacancyItemAdmin.company.address ? vacancyItemAdmin.company.address : ""}
                           id="company-address" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"email"}
                           value={vacancyItemAdmin.company.email ? vacancyItemAdmin.company.email : ""}
                           id="company-email" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"phones"}
                           value={vacancyItemAdmin.company.phones ? vacancyItemAdmin.company.phones : ""}
                           id="company-phones" onChange={(e) => {
                    changeForm(e)
                }}/>
                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"sites"}
                           value={vacancyItemAdmin.company.sites ? vacancyItemAdmin.company.sites : ""}
                           id="company-sites" onChange={(e) => {
                    changeForm(e)
                }}/>
                <Divider style={{marginTop: 20, marginBottom: 20}}/>

                <Button onClick={saveVacancy} color={"secondary"} variant={"outlined"}>Save New Vacancy</Button>
            </Grid>

        </Grid>
    );
};

export default NewVacancyDialogPart;
