export const languages = {
    "mandarin_chinese": {"language": "Mandarin Çin", "flag": "🇨🇳"},
    "spanish": {"language": "İspan", "flag": "🇪🇸"},
    "english": {"language": "İngilis", "flag": "🇬🇧"},
    "hindi": {"language": "Hindi", "flag": "🇮🇳"},
    "arabic": {"language": "Ərəb", "flag": "🇸🇦"},
    "bengali": {"language": "Benqal", "flag": "🇧🇩"},
    "portuguese": {"language": "Portuqal", "flag": "🇵🇹"},
    "russian": {"language": "Rus", "flag": "🇷🇺"},
    "japanese": {"language": "Yapon", "flag": "🇯🇵"},
    "punjabi_lahnda": {"language": "Pəncabi/Lahnda", "flag": "🇮🇳"},
    "marathi": {"language": "Marathi", "flag": "🇮🇳"},
    "telugu": {"language": "Telugu", "flag": "🇮🇳"},
    "wu_chinese": {"language": "Wu Çin (Şanxay)", "flag": "🇨🇳"},
    "turkish": {"language": "Türk", "flag": "🇹🇷"},
    "korean": {"language": "Koreya", "flag": "🇰🇷"},
    "french": {"language": "Fransız", "flag": "🇫🇷"},
    "german": {"language": "Alman", "flag": "🇩🇪"},
    "vietnamese": {"language": "Vyetnam", "flag": "🇻🇳"},
    "tamil": {"language": "Tamil", "flag": "🇮🇳"},
    "yue_chinese": {"language": "Yue Çin (Kanton)", "flag": "🇨🇳"},
    "urdu": {"language": "Urdu", "flag": "🇵🇰"},
    "javanese": {"language": "Cavan", "flag": "🇮🇩"},
    "italian": {"language": "İtalyan", "flag": "🇮🇹"},
    "western_punjabi": {"language": "Qərbi Pəncabi", "flag": "🇵🇰"},
    "thai": {"language": "Tay", "flag": "🇹🇭"},
    "gujarati": {"language": "Gucarat", "flag": "🇮🇳"},
    "jin_chinese": {"language": "Jin Çin", "flag": "🇨🇳"},
    "southern_min": {"language": "Cənubi Min (Hokkien-Tayvan)", "flag": "🇨🇳"},
    "persian": {"language": "Fars", "flag": "🇮🇷"},
    "polish": {"language": "Polşalı", "flag": "🇵🇱"},
    "pashto": {"language": "Puştu", "flag": "🇦🇫"},
    "kannada": {"language": "Kannada", "flag": "🇮🇳"},
    "xiang_chinese": {"language": "Xiang Çin", "flag": "🇨🇳"},
    "malayalam": {"language": "Malayalam", "flag": "🇮🇳"},
    "sundanese": {"language": "Sundan", "flag": "🇮🇩"},
    "hausa": {"language": "Hausa", "flag": "🇳🇬"},
    "burmese": {"language": "Burma", "flag": "🇲🇲"},
    "hakka_chinese": {"language": "Hakka Çin", "flag": "🇨🇳"},
    "ukrainian": {"language": "Ukrayna", "flag": "🇺🇦"},
    "bhojpuri": {"language": "Bhojpuri", "flag": "🇮🇳"},
    "tagalog": {"language": "Tagalog (Filippin)", "flag": "🇵🇭"},
    "yoruba": {"language": "Yoruba", "flag": "🇳🇬"},
    "maithili": {"language": "Maithili", "flag": "🇮🇳"},
    "uzbek": {"language": "Özbək", "flag": "🇺🇿"},
    "sindhi": {"language": "Sindi", "flag": "🇵🇰"},
    "amharic": {"language": "Amxar", "flag": "🇪🇹"},
    "fula": {"language": "Fula", "flag": "🇸🇳"},
    "romanian": {"language": "Rumın", "flag": "🇷🇴"},
    "oromo": {"language": "Oromo", "flag": "🇪🇹"},
    "igbo": {"language": "İqbo", "flag": "🇳🇬"},
    "azerbaijani": {"language": "Azərbaycan", "flag": "🇦🇿"},
    "awadhi": {"language": "Awadhi", "flag": "🇮🇳"},
    "cebuano": {"language": "Cebuano", "flag": "🇵🇭"},
    "dutch": {"language": "Holland", "flag": "🇳🇱"},
    "kurdish": {"language": "Kürt", "flag": "🇹🇷"},
    "serbo_croatian": {"language": "Serb-Xorvat", "flag": "🇷🇸"},
    "malagasy": {"language": "Malqaş", "flag": "🇲🇬"},
    "saraiki": {"language": "Saraiki", "flag": "🇵🇰"},
    "nepali": {"language": "Nepal", "flag": "🇳🇵"},
    "sinhalese": {"language": "Sinqal", "flag": "🇱🇰"},
    "chittagonian": {"language": "Çittagonian", "flag": "🇧🇩"},
    "zhuang": {"language": "Çuang", "flag": "🇨🇳"},
    "khmer": {"language": "Kmer", "flag": "🇰🇭"},
    "somali": {"language": "Somali", "flag": "🇸🇴"},
    "maldivian": {"language": "Maldiv", "flag": "🇲🇻"},
    "madurese": {"language": "Madur", "flag": "🇮🇩"},
    "hungarian": {"language": "Macar", "flag": "🇭🇺"},
    "rundi": {"language": "Rundi", "flag": "🇧🇮"},
    "czech": {"language": "Çex", "flag": "🇨🇿"},
    "greek": {"language": "Yunan", "flag": "🇬🇷"},
    "swedish": {"language": "İsveç", "flag": "🇸🇪"},
    "hmong": {"language": "Hmong", "flag": "🇨🇳"},
    "shona": {"language": "Şona", "flag": "🇿🇼"},
    "uyghur": {"language": "Uyğur", "flag": "🇨🇳"},
    "mossi": {"language": "Mossi", "flag": "🇧🇫"},
    "xhosa": {"language": "Xhosa", "flag": "🇿🇦"},
    "belarussian": {"language": "Belarus", "flag": "🇧🇾"},
    "balochi": {"language": "Balochi", "flag": "🇵🇰"},
    "konkani": {"language": "Konkani", "flag": "🇮🇳"},
    "wolof": {"language": "Wolof", "flag": "🇸🇳"},
    "afrikaans": {"language": "Afrikaans", "flag": "🇿🇦"},
    "tigrinya": {"language": "Tigrinya", "flag": "🇪🇷"},
    "hebrew": {"language": "İvrit", "flag": "🇮🇱"},
    "tswana": {"language": "Tswana", "flag": "🇧🇼"},
    "slovak": {"language": "Slovak", "flag": "🇸🇰"},
    "basque": {"language": "Bask", "flag": "🇪🇸"},
    "latvian": {"language": "Latviya", "flag": "🇱🇻"},
    "sotho": {"language": "Soto", "flag": "🇱🇸"},
    "nyanja": {"language": "Nyanja", "flag": "🇲🇼"},
    "kirundi": {"language": "Kirundi", "flag": "🇧🇮"},
    "mongolian": {"language": "Monqol", "flag": "🇲🇳"},
    "kazakh": {"language": "Qazax", "flag": "🇰🇿"},
    "sylheti": {"language": "Silheti", "flag": "🇧🇩"},
    "norwegian": {"language": "Norveç", "flag": "🇳🇴"},
    "lao": {"language": "Laos", "flag": "🇱🇦"},
    "estonian": {"language": "Eston", "flag": "🇪🇪"},
    "maltese": {"language": "Maltalı", "flag": "🇲🇹"},
    "tajik": {"language": "Tacik", "flag": "🇹🇯"},
    "sesotho": {"language": "Sesotho", "flag": "🇱🇸"}
}
