import React, {useEffect, useState} from 'react';
import {Button, Divider, Grid, IconButton, Typography, useTheme} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";
import {
    Announcement,
    ArrowBack,
    AutoAwesome,
    Category,
    DateRangeOutlined,
    Diamond,
    Favorite,
    Money,
    Send,
    ShareOutlined,
    Timer,
    Visibility
} from "@mui/icons-material";

import {
    companyLogo,
    copyDeadline,
    dateCorrector,
    favoriteHandler,
    setDocDesc,
    setDocTitle,
    setOgImage,
    shareHandler
} from "../utils/Utils";
import CsLabel from "../miniComponents/CsLabel";
import ApplyDialog from "../components/applyDialog";


const VacancyBody = ({wordsWithLang}) => {
    const theme = useTheme();
    const params = useParams();
    const vacancyItem = useSelector(state => state.vacancy.vacancyItem)
    const userConfig = useSelector(state => state.userConfig);
    const dispatcher = useDispatch();
    const [applyType, setApplyType] = useState("none")
    const navigate = useNavigate();

    const checkVacancy = () => {
        window.scrollTo(0, 0)
        if (!vacancyItem.id) {
            axios.get(apiUrls.baseApiUrl + apiUrls.getVacancy.replace(":id", params.id)).then((resp) =>{
                dispatcher({type: "SET_VACANCY", payload: resp.data})
                let title = vacancyItem.company.name +" yeni vakansiya! - " + vacancyItem.title
                setDocTitle(title)
                setDocDesc(vacancyItem.content)
                setOgImage(vacancyItem.id+".jpg")
            })
        } else {
            axios.get(apiUrls.baseApiUrl + apiUrls.getVacancy.replace(":id", params.id)).then((resp) => {
                window.scrollTo(0, 0)
                let title = vacancyItem.company.name +" yeni vakansiya! - " + vacancyItem.title
                setDocTitle(title)
                setDocDesc(vacancyItem.content)
                setOgImage(vacancyItem.id+".jpg")
            })
        }

    }



    useEffect(checkVacancy, [vacancyItem])
    return (
        <Grid container justifyContent={"space-between"}  alignItems={"center"} style={{
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
        }}>
            <Grid item xs={12}>
                <Link to={-1}><Button color={"primary"} variant={"contained"}><ArrowBack/><Typography style={{marginLeft: 3}}
                                                                                   variant={"button"}>{wordsWithLang.vacancyBody.back}</Typography></Button></Link>
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <Divider/>
            </Grid>

            <Grid item xs={12} style={{marginTop: 20}}>
                <Grid container justifyContent={"center"}>
                    {vacancyItem.isVip ? <Grid item xs={12}
                                               style={{
                                                   padding: 5,
                                                   borderRadius: 10
                                               }}>

                        <CsLabel hideIcon={false} icon={
                            <Diamond style={{
                                fontSize: 20,
                                marginRight: 0
                                // padding: 1,
                            }}/>} color={"#ffffff"} label={wordsWithLang.vacancyItem.premium}
                                 textColor={theme.palette.warning.light}/>

                    </Grid> : null}

                    <Grid item xs={12}
                          style={{
                              padding: 5,
                              borderRadius: 10
                          }}>
                        {vacancyItem.category.title ?
                            <Link to={"/category/" + vacancyItem.category.slug}>
                                <Button size={"small"} style={{minWidth: 300}} color={"primary"} variant={"outlined"}
                                        startIcon={<Category style={{
                                            fontSize: 20,
                                            marginRight: 0
                                            // padding: 1,
                                        }}/>}>{vacancyItem.category.title}</Button>
                                {/*<CsLabel hideIcon={false} icon={*/}
                                {/*<Category style={{*/}
                                {/*    fontSize: 20,*/}
                                {/*    marginRight: 0*/}
                                {/*    // padding: 1,*/}
                                {/*}}/>} color={"#ffffff"} label={vacancyItem.category.title}*/}
                                {/*     textColor={theme.palette.primary.main}/>*/}
                            </Link>
                            : null}

                    </Grid>


                    {vacancyItem.isNew ?
                        <Grid item xs={3} sm={3}
                              style={{
                                  padding: 5,
                                  borderRadius: 10
                              }}>

                            <CsLabel hideIcon={false} icon={
                                <AutoAwesome style={{
                                    fontSize: 20,
                                    marginRight: 2
                                    // padding: 1,
                                }}/>} color={"#ffffff"} label={wordsWithLang.vacancyItem.new}
                                     textColor={theme.palette.secondary.main}/>
                        </Grid>
                        : null}
                    <Grid item xs={3} sm={3}
                          style={{
                              padding: 5,
                              borderRadius: 10
                          }}>
                        <CsLabel hideIcon={false} icon={
                            <Visibility style={{
                                fontSize: 20,
                                marginRight: 0,
                                // padding: 10,
                            }}/>} color={"#ffffff"} label={vacancyItem.viewCount}
                                 textColor={theme.palette.primary.main}/>

                    </Grid>
                    <Grid item xs={3} sm={3}
                          style={{
                              padding: 5,
                              borderRadius: 10
                          }}>
                        {vacancyItem.created ?
                            <CsLabel
                                hideIcon={false}
                                icon={<DateRangeOutlined style={{fontSize: 20}} titleAccess={wordsWithLang.vacancyItem.date}/>}
                                color={"#ffffff"} textColor={theme.palette.primary.main}
                                label={dateCorrector(vacancyItem.created,wordsWithLang)}
                            />
                            : null}

                    </Grid>
                    <Grid item xs={3} sm={3}
                          style={{
                              padding: 5,
                              borderRadius: 10
                          }}>
                        {vacancyItem.salary ?
                            <CsLabel
                                hideIcon={false}
                                icon={<Money style={{fontSize: 20,}} titleAccess={wordsWithLang.vacancyItem.date}/>}
                                color={"#ffffff"} textColor={theme.palette.primary.main}
                                label={vacancyItem.salary + " AZN"}
                            />
                            : null}

                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={1} style={{marginTop: 20, minWidth: 70, maxWidth: 70}}>
                {vacancyItem.company.miniLogo ?
                    <Grid item xs={9} className={"vacancyItemImg"}
                          style={{backgroundImage: "url('" + companyLogo(vacancyItem) + "')",}}>
                    </Grid> : <Grid item xs={9} className={"vacancyItemImg"}
                                    style={{backgroundImage: "url('/img/company.png')", backgroundSize: "40px",}}>
                        {/*><Typography style={{fontSize: 30, color: "darkgray"}} align={"center"}>*/}
                        {/*    {vacancyItem.company.name.charAt(0).toUpperCase()}*/}
                        {/*</Typography>*/}
                    </Grid>}
            </Grid>
            <Grid item xs={4} sm={6} md={7} style={{paddingTop: 20}}>
                <Grid container justifyContent={"flex-start"} >
                    <Grid item xs={11}>
                        <Link to={"/company/" + vacancyItem.company.slug} ><Typography
                            align={"left"}
                            variant={"caption"}
                            color={"primary"}
                            style={{fontSize: 13}}>{vacancyItem.company.name}</Typography></Link>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={4}>
                <Grid container justifyContent={"flex-end"}>
                    <Button onClick={() => {
                        copyDeadline(dispatcher, vacancyItem.deadline)
                    }} title={wordsWithLang.vacancyBody.date} color={"secondary"} variant={"outlined"}><Timer
                        fontSize={"medium"}
                        style={{marginRight: 10}}/>{vacancyItem.deadline}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={11}>
                <Typography style={{marginTop: 10}}>{vacancyItem.title}</Typography>
            </Grid>
            <Grid item xs={11}>
                <div id={"content"} dangerouslySetInnerHTML={{__html: vacancyItem.content}}/>
            </Grid>

            <Grid item xs={12} style={{marginTop: 50, height: "200px"}}>
                {vacancyItem.source ?
                    <Typography style={{fontStyle: "italic", opacity: 0.4}} color={"textSecondary"}
                                variant={"caption"}>{wordsWithLang.vacancyItem.source + vacancyItem.source}</Typography> : null}
                {/*<Link to={-1}><Button onClick={()=>{dispatcher({type: "NEXT_PAGE", payload: 0})}}><ArrowBack/><Typography style={{marginLeft: 3}}*/}
                {/*                                                                                                          variant={"button"}>{wordsWithLang.vacancyBody.back}</Typography></Button></Link>*/}

            </Grid>
            <Grid item xs={12}>
                <Grid container className={"actionPanel"} justifyContent={"center"} alignItems={"center"}>
                    <Grid item md={1} lg={2} sx={{display: {xs: "none"}}}/>
                    <Grid item xs={7}>
                        <Button style={{margin: "10px 10px 0px 0px"}} color={"primary"} variant={"outlined"}
                                size={"large"} onClick={() => {
                            setApplyType("apply")
                            dispatcher({type: "APPLY_DIALOG_STATUS_SWITCH", payload: true})
                        }}>
                            <Announcement style={{marginRight: 5}}/>{wordsWithLang.vacancyBody.apply}</Button>
                        <Button style={{margin: "10px 10px 0px 0px"}} color={"secondary"} variant={"outlined"}
                                size={"large"}
                                onClick={() => {
                                    if (!userConfig.isLogin) {
                                        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
                                    } else {
                                        setApplyType("sendCV")
                                        dispatcher({type: "APPLY_DIALOG_STATUS_SWITCH", payload: true})
                                    }
                                }}>
                            <Send style={{marginRight: 5}}/>{wordsWithLang.vacancyBody.sendCv}</Button>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                        <IconButton onClick={(e) => shareHandler(dispatcher, window.location.href, e, vacancyItem)}>
                            <ShareOutlined color={"primary"} fontSize={"large"} titleAccess={wordsWithLang.vacancyItem.share}/>
                        </IconButton>
                        <IconButton onClick={() => favoriteHandler(vacancyItem.id, dispatcher, userConfig)}>
                            <Favorite
                                color={userConfig.favorites && userConfig.favorites.includes(vacancyItem.id.toString()) ? "secondary" : "inherit"}
                                fontSize={"large"} titleAccess={wordsWithLang.vacancyItem.favorite}/>
                        </IconButton>
                    </Grid>

                    <Grid item md={1} lg={2} sx={{display: {xs: "none"}}}/>

                </Grid>
                <ApplyDialog applyType={applyType}/>

            </Grid>
        </Grid>
    );
};

export default VacancyBody;
