import React from 'react';
import {Box, IconButton, Stack, Typography, useTheme} from "@mui/material";
import {Email, Facebook, Instagram, LinkedIn, Phone, Telegram, ThumbUp} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";


const OnlyContact = (wordsWithLang, theme) => {
    return (
    <div>
        <Box style={{
            padding: "10px",
            paddingBottom: "10px",
            paddingTop: "20px",
            borderBottom: "3px solid",
            borderColor: theme.palette.primary.main,
            marginBottom: "40px",
            float: "left"
            // borderRadius: "10px"
        }}>
            <Stack direction="row" alignItems="center" gap={2}>
                <Phone style={{color: theme.palette.primary.main}}/>
                <Typography variant={"button"}> +994 55 580 08 98</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
                <Email style={{color: theme.palette.primary.main}}/>
                <Typography variant={"button"}>info@bakujobs.az</Typography>
            </Stack>
        </Box>
    </div>
    )}



const OnlyLinks = (wordsWithLang,theme, navigate) => (
    <div>
        <Box style={{width: "100%", verticalAlign: "middle"}}>
            {/*<Stack direction="row" alignItems="center" gap={2}>*/}
            <br/>
            <br/>
            <Stack direction={"row"} gap={1} style={{marginBottom: 10, }}>
                <ThumbUp fontSize={"medium"} style={{color: theme.palette.primary.main}}/>
                <Typography style={{fontSize: 18}} display={"block"} color={theme.palette.text.secondary}
                            variant={"button"}>{wordsWithLang.header.followUs}</Typography>
            </Stack>

            {/*<Box  className={"followUsBanner"} />*/}
            <a href={"https://www.linkedin.com/company/baku-jobs/"} target={"_blank"}>
                <IconButton
                    style={{animationName : "linksAnimation", animationDuration:"1s",animationDelay:"0.5s", marginRight: 10, backgroundColor: theme.palette.secondary.main, color: "white"}}
                    onClick={() => navigate("/")}
                    variant={"contained"} size={"small"} display={"block"} align={"right"}>
                    <LinkedIn style={{fontSize: "20px", color: "white"}}
                              titleAccess={wordsWithLang.header.mainPage}/>

                </IconButton>
            </a>
            <a href={"https://www.instagram.com/bakujobs.az/"} target={"_blank"}>
                <IconButton
                    style={{animationName : "linksAnimation", animationDuration:"1s",animationDelay:"0.7s",
                        marginRight: 10, backgroundColor: theme.palette.secondary.main, color: "white"}}
                    onClick={() => navigate("/")}
                    variant={"contained"} size={"small"} display={"block"} align={"right"}>

                    <Instagram style={{fontSize: "20px", color: "white"}}
                               titleAccess={wordsWithLang.header.mainPage}/>

                </IconButton>
            </a>
            <a href={"https://www.facebook.com/people/Baku-Jobs/100087601481945/"} target={"_blank"}>
                <IconButton
                    style={{animationName : "linksAnimation", animationDuration:"1s",animationDelay:"0.9s",
                        marginRight: 10, backgroundColor: theme.palette.secondary.main, color: "white"}}
                    onClick={() => navigate("/")}
                    variant={"contained"} size={"small"} display={"block"} align={"right"}>
                    <Facebook style={{fontSize: "20px", color: "white"}}
                              titleAccess={wordsWithLang.header.mainPage}/>

                </IconButton>
            </a>
            <a href={"https://t.me/bakujobs_az"} target={"_blank"}>
                <IconButton
                    style={{animationName : "linksAnimation", animationDuration:"1s",animationDelay:"1.1s",
                        marginRight: 10, backgroundColor: theme.palette.secondary.main, color: "white"}}
                    onClick={() => navigate("/")}
                    variant={"contained"} size={"small"} display={"block"} align={"right"}>
                    <Telegram style={{fontSize: "20px", color: "white"}}
                              titleAccess={wordsWithLang.header.mainPage}/>

                </IconButton>
            </a>
            {/*</Stack>*/}
        </Box>
    </div>
)


const ContactAndLinks = (wordsWithLang,theme) => (
    <div>
        <Box style={{
            padding: "10px",
            paddingBottom: "10px",
            paddingTop: "20px",
            borderBottom: "3px solid",
            borderColor: theme.palette.primary.main,
            marginBottom: "40px",
            float: "right"
            // borderRadius: "10px"
        }}>
            <Stack direction="row" alignItems="center" gap={2}>
                <Phone style={{color: theme.palette.primary.main}}/>
                <Typography variant={"button"}> +994 55 580 08 98</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
                <Email style={{color: theme.palette.primary.main}}/>
                <Typography variant={"button"}>info@bakujobs.az</Typography>
            </Stack>
        </Box>
    </div>
)

const MiniContact = ({viewType, wordsWithLang}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    switch (viewType) {
        case "onlyContact":return OnlyContact(wordsWithLang, theme);
        case "onlyLinks":return OnlyLinks(wordsWithLang, theme, navigate);
        case "contactAndLinks":return ContactAndLinks(wordsWithLang, theme);
    }
};

export default MiniContact;
