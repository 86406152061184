import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {words} from "../words";
import {FilterAlt, FilterAltOff, SearchOutlined} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";
import {useNavigate} from "react-router-dom";
import {setDocDesc, setDocTitle} from "../utils/Utils";

const Search = ({wordsWithLang}) => {
    const navigate = useNavigate()
    const searchState = useSelector(state => state.search)
    const configState = useSelector(state => state.mainConfig)
    const dispatcher = useDispatch();
    const [companyName, setCompanyName] = useState("")
    const [searchTermValue, setSearchTermValue] = useState(searchState.term ? searchState.term : "")


    const setSearchTerm = (e) => {
        dispatcher({type: "SET_SEARCH_TERM", payload: searchTermValue})
        e.target.blur()
    }
    const setCategory = (category) => {
        dispatcher({type: "SET_CATEGORY", payload: category})
    }
    const setDate = (date) => {
        dispatcher({type: "SET_DATE", payload: date})
    }
    const setArea = (area) => {
        dispatcher({type: "SET_AREA", payload: area})
    }
    const setWorkday = (workday) => {
        dispatcher({type: "SET_WORKDAY", payload: workday})
    }
    const displayFilter = (data) => {
        dispatcher({type: "SET_DISPLAY_FILTER", payload: data})
        if (!data) {
            setSearchTermValue("")
            dispatcher({type: "CLEAR_FILTER", payload: null})
        }
    }

    useEffect(() => {
        if (configState.categories.length > 1) {
            if (window.location.toString().includes("company")) {
                axios.get(apiUrls.baseApiUrl + apiUrls.getCompany.replace(":slug", searchState.company)).then(
                    resp => {
                        setDocTitle(wordsWithLang.general.documentTitleFromCompanyUrl.replace("[company]", resp.data.name))
                        setDocDesc(wordsWithLang.general.documentDescCompany.split("[company]").join(resp.data.name))
                        setCompanyName(resp.data.name)
                    }
                )
            }
            // console.log(window.location.toString().split("/")[4])

             if (window.location.toString().includes("category")) {
                 let categorySlug = window.location.toString().split("/")[4]
                setDocTitle(wordsWithLang.general.documentTitleFromCategoryUrl.replace("[category]", configState.categories.find(o => o.catSlug === categorySlug).catName));
                setDocDesc(wordsWithLang.general.documentDescCategory.split("[category]").join(configState.categories.find(o => o.catSlug === categorySlug).catName));
            }
        }

    }, [searchState, configState.categories])

    return (
        <Grid container direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <AppBar position={"static"} className={"searchBar"} color={"default"}>
                <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-around"}>
                    <Grid item xs={5} className={"searchBar gridItem"}>
                        <Input fullWidth placeholder={wordsWithLang.search.search} disableUnderline={true} variant={"outlined"}
                               style={{marginLeft: 20}}
                               value={searchTermValue}
                               onFocus={() => {
                                   if (!configState.isFilterOpen) {
                                       dispatcher({type: "SET_DISPLAY_FILTER", payload: true})
                                   }
                               }}
                               onChange={(e) => setSearchTermValue(e.target.value)}

                               onKeyPress={(e) => {
                                   // press enter search
                                   if (e.key === "Enter") {
                                       setSearchTerm(e)
                                   }
                               }}/>
                    </Grid>
                    <Grid item xs={6} className={"searchBar gridItem"}>
                        <Grid container direction={"row"} alignItems={"space-around"} justifyContent={"space-around"}>
                            <Grid item xs={8}>
                                <IconButton
                                    size="medium"
                                    style={{marginRight: 20, float: "right"}}
                                    color={"primary"}
                                    onClick={(e)=>{setSearchTerm(e)}}
                                >
                                    <SearchOutlined/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                {!configState.isFilterOpen ?
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        style={{borderRadius: 20, marginRight: 20, float: "right"}}
                                        size="medium"
                                        onClick={() => displayFilter(!configState.isFilterOpen)}
                                    >
                                        <FilterAlt/>
                                        <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                                            <Typography style={{padding: 7}} variant={"button"}>Filter</Typography>
                                        </Box>
                                    </Button> :
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        style={{borderRadius: 20, marginRight: 20, float: "right"}}
                                        size="medium"
                                        onClick={() => {
                                            displayFilter(!configState.isFilterOpen);
                                            navigate("/");
                                        }}
                                    >
                                        <FilterAltOff/>
                                        <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                                            <Typography style={{padding: 7}} variant={"button"}>Filter</Typography>
                                        </Box>
                                    </Button>}
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>

                    <Grid item xs={12} className={"searchBar gridItem"}
                          style={{display: configState.isFilterOpen ? "block" : "none"}}>
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item xs={11} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel>{wordsWithLang.search.category}</InputLabel>
                                    {configState.categories.length > 1 ? (
                                        <Select
                                            name={"categoryList"}
                                            defaultValue={searchState.category}
                                            label={wordsWithLang.search.category}
                                            value={searchState.category === "" ? "all" : searchState.category}
                                            onChange={(e) => {
                                                setCategory(e.target.value)
                                            }}
                                        >
                                            {configState.categories.map((item, i) => (
                                                <MenuItem key={i} value={item.catSlug}>{item.catName}</MenuItem>
                                            ))}
                                        </Select>) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={11} sm={2}>
                                <FormControl fullWidth>
                                    <InputLabel>{wordsWithLang.search.addDate}</InputLabel>
                                    <Select
                                        defaultValue={searchState.date}
                                        value={searchState.date}
                                        label={wordsWithLang.search.addDate}
                                    >
                                        {wordsWithLang.search.addDateArray.map((item) => (
                                            <MenuItem key={item.key} value={item.key}
                                                      onClick={() => setDate(item.key)}>{item.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={11} sm={2}>
                                <FormControl fullWidth>
                                    <InputLabel>{wordsWithLang.search.area}</InputLabel>
                                    <Select
                                        defaultValue={searchState.area}
                                        value={searchState.area}
                                        label={wordsWithLang.search.area}
                                        onChange={(e) => setArea(e.target.value)}
                                    >
                                        {wordsWithLang.search.areaArray.map((item, i) => (
                                            <MenuItem key={"area-" + i} disabled={item === "-------"}
                                                      value={item.key}>{item.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={11} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel>{wordsWithLang.search.workDay}</InputLabel>
                                    <Select
                                        defaultValue={searchState.workDay}
                                        value={searchState.workDay}
                                        label={wordsWithLang.search.workDay}
                                    >
                                        {wordsWithLang.search.workdayArray.map((item, i) => (
                                            <MenuItem key={item.key} value={item.key}
                                                      onClick={() => setWorkday(item.key)}>{item.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {searchState.company !== "all" && searchState.company !== "" ? (
                                <Grid item xs={12} sm={12}>
                                    <Grid container direction={"row"} justifyContent={"flex-start"}>
                                        <Typography style={{marginLeft: 20}} variant={"overline"}>
                                            {wordsWithLang.search.company + " : " + companyName}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            ) : null}

                        </Grid>

                    </Grid>

                </Grid>

            </AppBar>
        </Grid>
    );
};

export default Search;
