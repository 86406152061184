export const competences = {
    "dataAnalysis": "Data Analizi",
    "engineeringSkills": "Mühəndislik Bacarıqları",
    "financialAcumen": "Maliyyə Bacarığı",
    "medicalExpertise": "Tibbi Bacarıq",
    "legalKnowledge": "Hüquqi Bilik",
    "scientificResearch": "Elmi Tədqiqat",
    "graphicDesign": "Qrafik Dizayn",
    "projectManagement": "Layihə İdarəçiliyi",
    "educationalProficiency": "Təhsil Bacarığı",
    "communicationSkills": "Ünsiyyət Bacarıqları",
    "teamwork": "Komanda İşi",
    "conflictResolution": "Münaqişələrin Həlli",
    "empathy": "Empatiya",
    "leadership": "Liderlik",
    "negotiation": "Danışıqlar",
    "culturalAwareness": "Mədəniyyət Bilgisi",
    "motivation": "Motivasiya",
    "adaptability": "Uyğunlaşma",
    "persuasion": "İnandırma",
    "strategicPlanning": "Strateji Planlaşdırma",
    "creativeThinking": "Yaradıcı Düşüncə",
    "criticalThinking": "Tənqidi Düşüncə",
    "problemSolving": "Problemlərin Həlli",
    "decisionMaking": "Qərar Qəbuletmə",
    "analyticalThinking": "Analitik Düşüncə",
    "organizationalSkills": "Təşkilati Bacarıqlar",
    "learningAgility": "Öyrənmə Çevikliyi",
    "riskManagement": "Risk İdarəçiliyi",
    "visionaryThinking": "Vizyoner Düşüncə"
}

