import React, {useEffect, useState} from 'react';
import {Button, Divider, Grid, Typography} from "@mui/material";
import CvAppCvOrderCard from "../../components/cvAppCvOrderCard";
import {useDispatch, useSelector} from "react-redux";
import CvOrderEdit from "./cvOrderEdit";
import {AutoAwesome, CheckCircle, HelpOutline} from "@mui/icons-material";
import CvTemplates from "./cvAppTemplate";
import apiUrls from "../../apiUrls";
import axios from "axios";
import CvAppPreview from "./cvAppPreview";

const isMobile = window.screen.width <= 700


const CvAppMain = ({wordsWithLang}) => {
    const cvAppConfig = useSelector(state => state.cvAppConfig)
    const [cvOrders, setCvOrders] = useState([]);
    const [balance, setBalance] = useState({
        id:0 ,
        userId:0,
        balance:0 ,
        isEditable:false ,
        expireDate:null,
    });

    const [balanceString, setBalanceString] = useState("");

    const dispatcher = useDispatch();


    const getUserCvOrdersAndBalance = () => {
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllOrders, {withCredentials: true}).then(resp => {
            setCvOrders(resp.data.sort((a, b) => b.id - a.id));

            // setCvOrders([{id:"1122"}])
        })

        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetUserBalance, {withCredentials: true}).then(resp => {
            setBalance(resp.data)
            if(!resp.data.expireDate){
                setBalanceString(wordsWithLang.cvApp.balance+": "+resp.data.balance+" CV")
            }
            else {
                setBalanceString(wordsWithLang.cvApp.balance+": "+resp.data.balance+" CV | "+ wordsWithLang.cvApp.balanceExpiry+": "+resp.data.expireDate)
            }
            // setCvOrders([{id:"1122"}])
        }).catch((err)=>{
            setBalanceString(wordsWithLang.cvApp.balance+": "+balance.balance+" CV | "+ wordsWithLang.cvApp.balanceExpiry+": "+balance.expireDate)
        })
    }
    useEffect(getUserCvOrdersAndBalance, [])


    return (
        <div>
            <Grid container justifyContent={"center"} style={{backgroundColor: "#faf8ff"}}>
                <Grid item xs={12} justifyContent={"center"}>
                    <Typography align={"center"} className={"violetColor fade-in"} style={{
                        marginLeft: 10,
                        marginRight: 10,
                        fontWeight: 600,
                        fontSize: isMobile ? ".9" : "1.3rem",
                        marginBottom: "20px",
                        marginTop: 30
                    }}>
                        {wordsWithLang.cvApp.mainPageTitle}</Typography>
                    <Divider variant={"middle"} size={"small"}
                             style={{margin: 15, fontSize: 15, border: ".1px solid gray"}}/>
                        <Typography align={"left"} onClick={()=>{
                            dispatcher({type:"OPEN_SNACK",payload:{
                                    isSnackOpen:true,
                                    snackType:"info",
                                    snackMessage:wordsWithLang.cvApp.balanceInfo
                                }})
                        }} style={{
                            color: "gray",
                            marginLeft: 30,
                            // marginRight: 10,
                            fontStyle: "italic",
                            // textDecoration: "underline",
                            fontWeight: 100,
                            fontSize: isMobile ? ".6rem" : ".9rem",
                            marginBottom: 15,
                            cursor:"pointer"
                            // marginTop: 10
                        }}>
                            <HelpOutline color={"warning"} fontSize={"15"}/>{balanceString}</Typography>

                </Grid>
                {cvAppConfig.cvAppScreen === "main" ?
                    <CreateNewCV updateCvOrder={getUserCvOrdersAndBalance} wordsWithLang={wordsWithLang}/> : null
                }

                {cvAppConfig.cvAppScreen === "main" ?
                    (<CvOrders cvOrders={cvOrders} updateCvOrder={getUserCvOrdersAndBalance}
                                  wordsWithLang={wordsWithLang}
                    />) : cvAppConfig.cvAppScreen === "edit" ? (
                        <CvOrderEdit wordsWithLang={wordsWithLang}/>) : null
                }

                {cvAppConfig.cvAppScreen === "template" ?
                    <CvTemplates wordsWithLang={wordsWithLang}/> : null
                }
                {cvAppConfig.cvAppScreen === "preview" ?
                    <CvAppPreview wordsWithLang={wordsWithLang} userBalance={balance} updateUserBalance={getUserCvOrdersAndBalance}/> : null
                }
            </Grid>

        </div>
    );
};


// old cvorders component
const CvOrders = ({cvOrders, updateCvOrder, wordsWithLang}) => {
    return (
        <Grid container spacing={"2"} justifyContent={"flex-start"}>
            <Typography variant={"caption"} className={"violetColor"} fontSize={22} align={"left"} display={"block"}
                        style={{
                            width: "80%",
                            fontWeight: "600",
                            marginBottom: 30,
                            marginLeft: 50
                        }}>{wordsWithLang.cvApp.oldCVs}</Typography>
            <br/>
            {cvOrders.length === 0 ? (
                <Typography variant={"caption"} color={"darkgray"} fontSize={16} align={"left"} display={"block"}
                            style={{
                                width: "80%",
                                fontWeight: "600",
                                marginBottom: 30,
                                marginLeft: 50
                            }}>{wordsWithLang.cvApp.noOldCVs}</Typography>
            ) : cvOrders.map((CvOrder) => (
                <Grid item key={CvOrder.id} xs={12} sm={12} md={6} lg={6} xl={4}>
                <CvAppCvOrderCard key={CvOrder.id} CvOrder={CvOrder} updateCvOrders={updateCvOrder}/>
                </Grid>
            ))}
        </Grid>)
}

// create new cv  component
const CreateNewCV = ({updateCvOrder, wordsWithLang}) => {
    const dispatcher = useDispatch();
    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
    const userConfig = useSelector(state => state.userConfig)



    const createNewCv = () => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            if (newCvOrder.language === null) {
                newCvOrder.language = "az"
            }
            axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostNewCvOrder, newCvOrder,
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    updateCvOrder()
                }
            )
        }
    }

    const selectLanguage = (lang) => {
        let temp = newCvOrder
        if(lang===null){
            temp.language = "az"
        }
        else{
            temp.language = lang
        }
        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})

    };

    return (
        <Grid container justifyContent={"center"} style={{marginBottom: 50}}>
            <Typography variant={"caption"} className={"violetColor"} fontSize={20} align={"left"} display={"block"}
                        style={{
                            width: "100%",
                            marginLeft: 50,
                            fontWeight: "600",
                            marginBottom: 10
                        }}>{wordsWithLang.cvApp.makeNewCV}</Typography>
            <br/>
            <Grid item xs={11}>
                <Button fullWidth size={"large"} variant={"text"}
                        style={{marginLeft: 15, marginTop: 10, color: "#333333"}}
                        onClick={() => selectLanguage('az')}>
                    {newCvOrder.language === 'az' ? <CheckCircle fontSize={"large"} style={{color: '#1778f2'}}/> :
                        <CheckCircle fontSize={"large"} style={{color: 'gray'}}/>}
                    <img src="https://bakujobs.az/img/az.png" alt="Description"
                         style={{width: '45px', height: '45px', borderRadius: '10px'}}/>
                    <Typography variant={"button"}
                                style={{width: "100%", paddingLeft: 10, fontSize: isMobile ? 15 : 20}}
                                align={"left"}> İstəyirəm kİ, CV azərbaycanca olsun?</Typography>
                </Button>
                <Button fullWidth size={"large"} variant={"text"}
                        style={{marginLeft: 15, marginTop: 10, color: "#333333"}}
                        onClick={() => selectLanguage('ru')}>
                    {newCvOrder.language === 'ru' ? <CheckCircle fontSize={"large"} style={{color: '#1778f2'}}/> :
                        <CheckCircle fontSize={"large"} style={{color: 'gray'}}/>}
                    <img src="https://bakujobs.az/img/ru.png" alt="Description"
                         style={{width: '45px', height: '45px', borderRadius: '10px'}}/>
                    <Typography variant={"button"}
                                style={{width: "100%", paddingLeft: 10, fontSize: isMobile ? 15 : 20}} align={"left"}> Я
                        хочу, чтобы мое CV было на русском языке?</Typography>

                </Button>
                <Button fullWidth size={"large"} variant={"text"}
                        style={{marginLeft: 15, marginTop: 10, color: "#333333"}}
                        onClick={() => selectLanguage('en')}>
                    {newCvOrder.language === 'en' ? <CheckCircle fontSize={"large"} style={{color: '#1778f2'}}/> :
                        <CheckCircle fontSize={"large"} style={{color: 'gray'}}/>}
                    <img src="https://bakujobs.az/img/gb.png" alt="Description"
                         style={{width: '45px', height: '45px', borderRadius: '10px'}}/>
                    <Typography variant={"button"}
                                style={{width: "100%", paddingLeft: 10, fontSize: isMobile ? 15 : 20}} align={"left"}> I
                        want my CV to be in English?</Typography>

                </Button>
            </Grid>
            <Grid item xs={11} lg={4} md={6}>
                <Button variant={"contained"}
                        fullWidth
                        className={"btn"}
                        style={{height: 50, marginTop: 25}}
                        startIcon={<AutoAwesome fontSize="medium" style={{color: 'white'}}/>}
                        onClick={() => {
                            createNewCv()
                        }}
                >
                    <Typography justifyContent={"center"} fontSize={20}>{wordsWithLang.cvApp.start}</Typography>
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Divider variant={"middle"} size={"large"}
                         style={{margin: 20, marginTop: 50, fontSize: 15, border: ".5px solid gray"}}/>
            </Grid>
        </Grid>

    )
}

export default CvAppMain;
