import {combineReducers} from "redux";
import {words} from "../words";
import {removeLastDuplicatesById} from "../utils/Utils";

const initialState = {
    mainConfig: {
        page: 1,
        categories: [],
        isMobile: window.screen.width > 700 ? false : true,
        reloadVacancies: true,
        isDrawerOpen: false,
        isFilterOpen: false,
        isSnackOpen: false,
        snackMessage: "",
        snackType: "success",
        isLoginOpen: false,
        loginDialogPage: 0,
        applyDialogStatus: false,
        loadingAllPage: "none",
        showPopUp: false,
        pageLang:window.localStorage.pageLang?window.localStorage.pageLang:"az"
    },
    search: {
        term: null,
        category: "",
        company: "",
        date: words.search.addDateArray[0].key,
        area: words.search.areaArray[0].key,
        workDay: words.search.workdayArray[0].key
    },
    prevSearch: {
        // term: null,
        // category: "",
        // company: "",
        // date: words.search.addDateArray[0].key,
        // area: words.search.areaArray[0].key,
        // workDay: words.search.workdayArray[0].key
    }
    ,
    vacancy: {
        vacancyScroll: 0,
        vacancyItem: {
            id: "",
            source: "",
            company: {
                id: "",
                name: "",
                slug: "",
                logo: "",
                vacancyCount: "",
            },
            category: {
                id: "",
                logo: "",
                miniLogo: "",
                title: "",
                slug: "",
                vacancyCount: "",
            },
            title: "",
            content: "",
            salary: "",
            slug: "",
            actionDataType: "",

            isNew: "",
            isVip: "",
            isFavorite: "",
            deadline: "",
            created: "",
            viewCount: "",
        },
        page: 1,
        hasMore: true,
        vacancies: [],
        vacanciesPreview: [],
        favVacancies: [],
    },
    userConfig: {
        email: "",
        fullName: "",
        gender: "",
        businessCategorySlug: "",
        token: "",
        favorites: [],
        newsletterCategories: [],
        isLogin: false,
        isEmailVerified: false,
        cvFileName: null
    },
    adminConfig: {
        adminDialogState: false,
        vacancyItem: {
            id: ""
        }
    },

    cvAppConfig: {
        // showCVButton:true,
        cvAppScreen: "main",

        selectedTemplate:{
            id: null,
            productType: "CV_TEMPLATE",
            lang: "az",
            currency: "azn",
            name: "Deep web hello purple",
            title: "Numune-01 adli numune shablon",
            description: "Numune-01 adli numune shablonun aciqlamasi burada olacaq barede",
            previewUrl: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png",
            filePath: "",
            createDate: "",
            badge: "",
            price: "16",
            calcPrice: "16",
            discount: "20",
            specs: "",
            status: "",
        },

        cvOrder: {
            id: null,
            status: "",
            agreeWithRules: false,
            paymentStatus: "",
            paymentDateTime: null,
            cvUrl: "",
            previewUrl: "",
            pagesCount: null,
            pdfUrl: "",
            isPublic: false,
            language: "az",
            cvData: {},
            price: null,
            products: [],
        },

        cvData: {
            id: null,
            lastPreviewGenTime: null,
            avatarUrl: null,
            note: "",
            mainColor: "#3F51B5",
            templateId: null,
            personal: {
                id: null,
                name: "",
                surname: "",
                birthDate: null,
                email: "",
                phone: "",
                about: "",
                links: []
            },
            certificates: [
                // {
                //     id: null,
                //     name: "",
                //     about: "",
                //     issue: null
                // }
            ],
            competencies: [],
            educations: [
                // {
                //     id: null,
                //     eduType: "",
                //     name: "",
                //     specialty: "",
                //     faculty: "",
                //     startDate: null,
                //     endDate: null,
                //     isContinue: null
                // }
            ],
            langs: [
                // {
                //     id: null,
                //     language: "",
                //     level: null
                // }
            ],
            programs: [
                // {
                //     id: null,
                //     program: "",
                //     level: null
                // }
            ],
            references: [
                // {
                //     id: null,
                //     refName: "",
                //     contact: ""
                // }
            ],
            workExperiences: [
                // {
                //     id: null,
                //     workplace: "",
                //     branch: "",
                //     position: "",
                //     about: "",
                //     startDate: null,
                //     endDate: null,
                //     isContinue: null
                // }
            ],

        },

    }
}


function mainConfigReducer(state = initialState.mainConfig, action) {
    switch (action.type) {
        case 'LOADING_ALL_PAGE': {
            state.loadingAllPage = state.loadingAllPage === "none" ? "block" : "none";
            return {...state}
        }
        case 'APPLY_DIALOG_STATUS_SWITCH': {
            state.applyDialogStatus = action.payload
            return {...state}
        }
        case 'OPEN_SNACK': {
            state.isSnackOpen = action.payload.isSnackOpen;
            state.snackType = action.payload.snackType;
            state.snackMessage = action.payload.snackMessage;
            return {...state}
        }
        case 'LOGIN_DIALOG_PAGE': {
            state.loginDialogPage = action.payload;
            return {...state}
        }
        case 'RELOAD_VACANCIES': {
            state.reloadVacancies = action.payload;
            return {...state}
        }
        case 'OPEN_LOGIN_DIALOG': {
            state.isLoginOpen = action.payload;
            return {...state}
        }
        case 'NEXT_PAGE': {
            state.page = action.payload;
            return {...state}
        }
        case 'IS_OPEN_DRAWER': {
            state.isDrawerOpen = action.payload;
            return {...state}
        }
        case 'SET_DISPLAY_FILTER': {
            state.isFilterOpen = action.payload;
            return {...state}
        }
        case 'SET_CATEGORIES': {
            state.categories = action.payload;
            return {...state}
        }
        case 'SET_DISPLAY_FILTER_AND_PAGE': {
            state.page = 10;
            // state.reloadVacancies = action.payload;
            state.isFilterOpen = action.payload;
            return {...state}
        }
        case 'SHOW_REK_POPUP': {
            state.showPopUp = action.payload;
            return {...state}
        }
        case 'PAGE_LANG': {
            state.pageLang = action.payload;
            return {...state}
        }
        default: {
            return state
        }
    }
};

function searchReducer(state = initialState.search, action) {
    switch (action.type) {
        case 'SET_CATEGORY': {
            state.category = action.payload;
            return {...state}
        }
        case 'SET_DATE': {
            state.date = action.payload;
            return {...state}
        }
        case 'SET_WORKDAY': {
            state.workDay = action.payload;
            return {...state}
        }
        case 'SET_AREA': {
            state.area = action.payload;
            return {...state}
        }
        case 'SET_SEARCH_TERM': {
            state.term = action.payload;
            return {...state}
        }
        case 'SET_COMPANY': {
            state.company = action.payload;
            return {...state}
        }
        case 'CLEAR_FILTER': {
            state.company = "";
            state.date = "all";
            state.term = null;
            state.area = "all";
            state.workDay = "all";
            state.category = "";
            return {...state}
        }
        default: {
            return state
        }
    }
};

function prevSearchReducer(state = initialState.prevSearch, action) {
    switch (action.type) {
        case 'UPDATE_SEARCH': {
            state = action.payload;
            return {...state}
        }

        default: {
            return state
        }
    }
};


function vacancyReducer(state = initialState.vacancy, action) {
    switch (action.type) {
        case 'UPDATE_VACANCY_SCROLL': {
            state.vacancyScroll = action.payload;
            return {...state}
        }
        case 'SET_FAV_VACANCIES': {
            state.favVacancies = action.payload;
            return {...state}
        }
        case 'SET_VACANCY': {
            state.vacancyItem = action.payload;
            return {...state}
        }
        case 'ADD_VACANCIES': {
            let arr = state.vacancies.concat(action.payload);
            state.vacancies = arr;
            // arr.forEach((itm)=>{let nItm = itm; nItm.content = ""; state.vacanciesPreview.push(nItm)})
            // state.vacancies = arr.reduce((acc, vacancy) => {
            //     if (!acc.find(v => v.id === vacancy.id)) {
            //         acc.push(vacancy)
            //     }
            //     return acc
            // }, [])
            return {...state}
        }
        case 'ADD_VACANCIES_AND_NEXT_PAGE': {
            let arr = state.vacancies.concat(action.payload.arr);
            state.vacancies = removeLastDuplicatesById(arr);
            // arr.forEach((itm)=>{let nItm = itm; nItm.content = ""; state.vacanciesPreview.push(nItm)})
            state.page = action.payload.page
            return {...state}
        }
        case 'SET_HASMORE': {
            state.hasMore = action.payload;
            return {...state}
        }
        case 'RESET_PAGE_AND_VACANCIES_AND_HASMORE': {
            state.vacancies = [];
            state.vacanciesPreview = [];
            state.page = 1
            state.hasMore = true
            return {...state}
        }

        case 'SET_VACANCIES': {
            state.vacancies = [];
            state.vacancies = action.payload
            return {...state}
        }

        default: {
            return state
        }
    }
};

function userReducer(state = initialState.userConfig, action) {
    switch (action.type) {
        case "CHANGE_LOGIN": {
            state.isLogin = action.payload;
            return {...state}
        }
        case "SET_USER": {
            if (action.payload === null) {
                action.payload = initialState.userConfig
            }
            state.email = action.payload.email
            state.fullName = action.payload.fullName
            state.gender = action.payload.gender
            state.businessCategorySlug = action.payload.businessCategorySlug
            state.token = action.payload.token
            state.favorites = action.payload.favorites
            state.newsletterCategories = action.payload.newsletterCategories
            state.isLogin = action.payload.isLogin
            state.isEmailVerified = action.payload.isEmailVerified
            state.cvFileName = action.payload.cvFileName
            return {...state}
        }

        case "CHANGE_LOGIN_SET_USER": {
            state.isLogin = "true";
            state.email = action.payload.email
            state.fullName = action.payload.fullName
            state.gender = action.payload.gender
            state.businessCategorySlug = action.payload.businessCategorySlug
            state.token = action.payload.token
            state.favorites = action.payload.favorites
            state.newsletterCategories = action.payload.newsletterCategories
            state.isLogin = action.payload.isLogin
            state.isEmailVerified = action.payload.isEmailVerified
            state.cvFileName = action.payload.cvFileName
            return {...state}
        }
        case "ADD_TO_FAVORITES": {
            if (!state.favorites) {
                let arr = []
                arr.push(action.payload)
                state.favorites = arr;
            } else if (!state.favorites.includes(action.payload)) {
                state.favorites.push(action.payload);
            } else {
                state.favorites.splice(state.favorites.indexOf(action.payload), 1);

            }
            return {...state}
        }
        case "ADD_TO_NEWSLETTERS": {
            state.newsletterCategories.push(action.payload)
            return {...state}
        }

        case "REMOVE_FROM_NEWSLETTERS": {
            state.newsletterCategories.splice(state.newsletterCategories.indexOf(action.payload), 1)
            return {...state}
        }
    }
    return {...state}
}

function adminReducer(state = initialState.adminConfig, action) {
    switch (action.type) {
        case "OPEN_ADMIN_DIALOG" : {
            state.vacancyItem.id = action.payload.adminVacId
            state.adminDialogState = action.payload.adminDialogState
            return {...state}
        }
    }
    return {...state}
}

function cvAppReducer(state = initialState.cvAppConfig, action) {
    switch (action.type) {
        case "CV_APP_CHANGE_SCREEN" : {
            state.cvAppScreen = action.payload
            return {...state}
        }
        // case "SHOW_CV_BUTTON" : {
        //     state.openCvApp = action.payload.showCVButton
        //     return{...state}
        // }
        case "CV_INSTANCE_UPDATE" : {
            state.cvOrder = action.payload
            return {...state}
        }
        case "CV_DATA_UPDATE" : {
            state.cvData = action.payload
            return {...state}
        }
        case "SELECTED_CV_UPDATE" : {
            state.selectedTemplate = action.payload
            return {...state}
        }
    }
    return {...state}
}


const RootReducer = combineReducers({
    mainConfig: mainConfigReducer,
    search: searchReducer,
    vacancy: vacancyReducer,
    userConfig: userReducer,
    adminConfig: adminReducer,
    prevSearch: prevSearchReducer,
    cvAppConfig: cvAppReducer,

})

export default RootReducer;
