import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl,
    FormControlLabel,
    Grid, InputLabel, MenuItem,
    Paper, Select,
    Typography
} from "@mui/material";
import {
    AccountCircleOutlined,
    ArrowBack, ArrowDownward, ArrowLeft, ArrowRight, ArrowUpward, AutoAwesome, AutoAwesomeMosaic,
    AutoFixHigh,
    BusinessCenterOutlined,
    Cancel,
    Check,
    CheckCircle,
    CloseOutlined,
    CorporateFareOutlined, Info, InfoOutlined, Timelapse
} from "@mui/icons-material";
import {words} from "../../words";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../../apiUrls";
import Confetti from 'confetti-react';
import {useNavigate} from "react-router-dom";
import {jsxs} from "react/jsx-runtime";

;


const CvAppPreview = ({wordsWithLang, userBalance, updateUserBalance}) => {

    const isMobile = window.screen.width <= 700
    const dispatcher = useDispatch()
    const [agreeWithRules, setAgreeWithRules] = useState(true)
    const cvAppConfigCvData = useSelector(state => state.cvAppConfig.cvData)
    const selectedTemplate = useSelector(state => state.cvAppConfig.selectedTemplate)
    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
    const [cvTemplateProduct, setCvTemplateProduct] = useState(selectedTemplate)
    const [hrAdviceCheck, setHrAdviceCheck] = useState(false)
    const [selectedPackId, setSelectedPackId] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [paymentStatusDialog, setPaymentStatusDialog] = useState("");
    const [marginDialogOpen, setMarginDialogOpen] = useState(true);
    const [selectedSection, setSelectedSection] = useState("");
    const [marginWait, setMarginWait] = useState(false);
    const previewRef = useRef()
    const [randomNum, setRandomNum] = useState(Math.floor(Math.random() * (3 - 1 + 1) + 1))
    const [ip, setIp] = useState('');


    const [cvPack1, setCvPack1] = useState({
        id: null,
        productType: "CV_PACK_1",
        name: "cvPack1",
        lang: "az",
        currency: "azn",
        title: "Peşəkar",
        description: "10 CV-lik paket",
        previewUrl: "",
        filePath: "",
        createDate: "",
        badge: "",
        price: "50",
        calcPrice: "50",
        discount: "",
        specs: "",
        status: "active",
    })
    const [cvPack2, setCvPack2] = useState({
        id: null,
        productType: "CV_PACK_2",
        name: "cvPack2",
        lang: "az",
        currency: "azn",
        title: "Korporativ",
        description: "100 CV-lik paket",
        previewUrl: "",
        filePath: "",
        createDate: "",
        badge: "",
        price: "350",
        calcPrice: "350",
        discount: "",
        specs: "",
        status: "active",
    })
    const [hrAdvicePack, setHrAdvicePack] = useState({
        id: null,
        productType: "HR_ADVICE_PACK",
        name: "hrAdvicePack",
        lang: "az",
        currency: "azn",
        title: "Peşəkar HR Məsləhəti",
        description: "Karyeranız üçün Ekspert Təlimatı. Məsləhətləri email və ya telefon vasitəsi ilə alacaqsınız. Düzəliş üçün əlavə 1 pulsuz CV haqqı veriləcək.",
        previewUrl: "",
        filePath: "",
        createDate: "",
        badge: "",
        price: "19.9",
        calcPrice: "19.9",
        discount: "",
        specs: "",
        status: "active",
    })
    const navigate = useNavigate();


    const changePackTo = (cvPack) => {
        let temp = newCvOrder;
        if (cvPack === "cvPack1") {
            setSelectedPackId(cvPack1.id)

            temp.products = temp.products.filter(product => product.id !== cvTemplateProduct.id);
            temp.products = temp.products.filter(product => product.id !== cvPack2.id);
            temp.products.push(cvPack1)

            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", cvPack1.id).replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    // setCvPack1(resp.data)
                }
            )

            dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

        } else if (cvPack === "cvPack2") {
            temp.products = temp.products.filter(product => product.id !== cvTemplateProduct.id);
            temp.products = temp.products.filter(product => product.id !== cvPack1.id);
            temp.products.push(cvPack2)

            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", cvPack2.id).replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    // setCvPack2(resp.data)
                }
            )

            dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

            setSelectedPackId(cvPack2.id)

        } else if (cvPack === "cvTemplate") {
            temp.products = temp.products.filter(product => product.id !== cvPack1.id);
            temp.products = temp.products.filter(product => product.id !== cvPack2.id);
            temp.products = temp.products.filter(product => product.id !== cvTemplateProduct.id);
            temp.products.push(cvTemplateProduct)

            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", cvTemplateProduct.id).replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    // setCvPack1(resp.data)
                }
            )

            dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

            setSelectedPackId(cvTemplateProduct.id)


        } else if (cvPack === "hrAdvice") {
            if (!hrAdviceCheck === true) {
                temp.products.push(hrAdvicePack)
                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", hrAdvicePack.id).replace("{CvOrderId}", newCvOrder.id),
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp => {
                        dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                        // setCvPack1(resp.data)
                    }
                )

            } else {
                temp.products = temp.products.filter(product => product.id !== hrAdvicePack.id);
                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", hrAdvicePack.id).replace("{CvOrderId}", newCvOrder.id),
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp => {
                        dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                        // setCvPack1(resp.data)
                    }
                )
                // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

            }
            setHrAdviceCheck(!hrAdviceCheck)

        }
        totalPriceCalc();

    }

    // const updateCvDataInServer = (comeForUpdate) => {
    //     console.log("sending data")
    //     console.log(cvAppConfigCvData)
    //     console.log("sent data")
    //     axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{id}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), cvAppConfigCvData,
    //         {
    //             withCredentials: true,
    //             headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
    //         }).then(resp => {
    //         console.log("recieved data")
    //         console.log(resp.data)
    //     })
    // }

    const totalPriceCalc = () => {
        let total = 0.0;
        let currency = "";
        newCvOrder.products.forEach(product => {
            if (product.productType === "CV_TEMPLATE" && userBalance.balance > 0) {
                product.price = 0;
                product.calcPrice = 0;
            }
            total = parseFloat(total) + product.calcPrice;
            currency = product.currency;

        })
        setTotalPrice(total);
        if (total === 0.0) {
            // changeAgreeWithRules()
        }
        return total + " " + currency;
    }

    const changeAgreeWithRules = () => {
        let temp = newCvOrder;
        temp.agreeWithRules = !agreeWithRules;
        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});
        setAgreeWithRules(!agreeWithRules)
    }


    const checkForPayment = () => {
            // if user balance is above 0 then send api checkPayment interval update cvorderobj
            // else send api checkPayment wait succes with interval cvorderobj
            setPaymentStatusDialog("WAIT")
            let isFree = true;
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetPaymentValidity.replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    if (resp.data === 0.0) {
                        // setPaymentStatusDialog("SUCCESS")
                        updateUserBalance();
                        // dont send req to pasha
                    } else {
                        isFree = false;
                    }
                }).then(() => {
                if (!isFree) {
                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetPaymentInitiate.replace("{CvOrderId}", newCvOrder.id).replace("{ipAddress}", ip),
                        {
                            withCredentials: true,
                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                        }).then(
                        resp => {
                            axios.get(resp.data).then(
                                resp => {
                                    let txId = "112233";
                                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetPaymentDone.replace("{CvOrderId}", newCvOrder.id).replace("{txId}", txId),
                                        {
                                            withCredentials: true,
                                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                                        }).then(
                                        resp => {
                                            let paymentStatus = null;
                                            let counter = 0
                                            let startInterval = false
                                            let interval = setInterval(() => {
                                                counter++;
                                                if (counter > 2) {
                                                    startInterval = true;
                                                }
                                                if (startInterval) {
                                                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", newCvOrder.id),
                                                        {
                                                            withCredentials: true,
                                                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                                                        }).then(
                                                        resp => {
                                                            paymentStatus = resp.data.paymentStatus
                                                            dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                                                        }
                                                    )
                                                    if (paymentStatus === "SUCCESS") {
                                                        paymentStatus = "SUCCESS"
                                                        setPaymentStatusDialog(paymentStatus)
                                                        let temp = newCvOrder;
                                                        temp.pdfUrl = "https://bakujobs.az/api/private/cvapp/orders/" + newCvOrder.id + "/pdfFile"
                                                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                                                        updateUserBalance();
                                                        clearInterval(interval);


                                                    } else if (paymentStatus === "ERROR") {
                                                        paymentStatus = "ERROR"
                                                        setPaymentStatusDialog(paymentStatus)
                                                        let temp = newCvOrder;
                                                        temp.pdfUrl = null
                                                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                                                        clearInterval(interval);


                                                    }
                                                    if (counter === 100) {
                                                        paymentStatus = "TIMEOUT"
                                                        setPaymentStatusDialog(paymentStatus)
                                                        let temp = newCvOrder;
                                                        temp.pdfUrl = null
                                                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                                                        clearInterval(interval);
                                                    }
                                                }

                                            }, 2000)
                                        })
                                })
                }
            )}


                // setPaymentStatusDialog(paymentStatus)
                // let temp = newCvOrder;
                // temp.pdfUrl = "https://bakujobs.az/api/private/cvapp/orders/"+newCvOrder.id+"/pdfFile"
                // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                //
                // setPaymentStatusDialog("SUCCESS")

            })}

            useEffect(() => {
                navigate('/cv/#');
                window.addEventListener('popstate', () => {
                    dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"})
                });
            }, [])

            useEffect(() => {
            }, [updateUserBalance])

            useEffect(() => {
                axios.get('https://api.ipify.org?format=json').then((response) => {

                    setIp(response.data.ip);
                    console.log(response.data.ip);
                })

            }, [])


            useEffect(() => {
                if (cvPack1.id === null) {
                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{lang}", newCvOrder.language).replace("{productType}", "CV_PACK_1"),
                        {
                            withCredentials: true,
                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                        }).then(
                        resp => {
                            setCvPack1(resp.data[0])
                        }
                    )
                }
                if (cvPack2.id === null) {
                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{lang}", newCvOrder.language).replace("{productType}", "CV_PACK_2"),
                        {
                            withCredentials: true,
                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                        }).then(
                        resp => {
                            setCvPack2(resp.data[0])
                        }
                    )
                }
                if (hrAdvicePack.id === null) {
                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{lang}", newCvOrder.language).replace("{productType}", "HR_ADVICE_PACK"),
                        {
                            withCredentials: true,
                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                        }).then(
                        resp => {
                            setHrAdvicePack(resp.data[0])
                        }
                    )
                }

                if (newCvOrder.products.length > 0) {
                    if (newCvOrder.products.filter(product => product.productType === "HR_ADVICE_PACK")[0]) {
                        setHrAdviceCheck(true)
                    }
                    if (newCvOrder.products.filter(product => product.productType === "CV_TEMPLATE")[0]) {
                        changePackTo("cvTemplate")
                    }
                } else {
                    changePackTo("cvTemplate")
                }
            }, [])

            useEffect(() => {

            }, [paymentStatusDialog])

            useEffect(() => {
                setRandomNum(Math.random())
                console.log(randomNum)
            }, [selectedTemplate, cvPack1, cvPack2, hrAdvicePack])


            return (
                <Grid container justifyContent="flex-start" spacing={2}>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            style={{marginLeft: 23}}
                            onClick={() => (dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"}))}
                        >
                            <ArrowBack fontSize="small"/>
                            <Typography style={{marginLeft: 3, fontSize: isMobile ? "20px" : "15px"}} variant="button">
                                {words.vacancyBody.back}
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid container justifyContent="center" style={{margin: "5px", marginTop: "5px"}}>
                        <Grid item xs={12}>
                            <Typography ml={7} mt={2}>
                                {wordsWithLang.cvApp.cvAppForms.generalPreview}:</Typography>
                            <Grid container justifyContent={"center"}>
                                {newCvOrder.previewUrl !== null ? (
                                    <>
                                        <Grid item xs={8}>
                                            <Button className={"addButton"} variant={"contained"}
                                                    onClick={() => setMarginDialogOpen(true)}
                                                    style={{margin: 10, padding: 20, height: "60%"}}
                                                    fullWidth>
                                                <AutoAwesomeMosaic style={{marginRight: 10}}/>
                                                <Typography>{wordsWithLang.cvApp.cvAppForms.template.headerOfMargin}</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box component="img"
                                                // src={newCvOrder.previewUrl}
                                                 src={marginWait ? Math.random() : apiUrls.baseApiUrl + apiUrls.cvAppGetPreviewOfCv.replace("{CvOrderId}", newCvOrder.id).replace("{page}", 0) + "?t=" + Date.now()}
                                                 sx={{
                                                     // padding: 2,
                                                     width: 595 / 1.5,
                                                     position: "relative",
                                                     // width: "100%",
                                                     boxShadow: "0 6px 15px rgba(0,0,0,.1), 0 0 6px rgba(0,0,0,.1);",
                                                     height: 842 / 1.5,
                                                     backgroundSize: "contain",
                                                     backgroundRepeat: "no-repeat",
                                                     backgroundPosition: "center",
                                                     // backgroundImage: "url('"  +"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png"+ "')",
                                                     display: "flex",
                                                     justifyContent: "center",
                                                     alignItems: "center",
                                                     margin: "25px auto",
                                                 }}
                                            />
                                        </Grid>

                                    </>
                                ) : (
                                    <Typography m={5} color={"error"} fontSize={20} sx={{
                                        animation: "fadeIn 2s linear infinite",
                                        animationDirection: "alternate-reverse"
                                    }}>
                                        {wordsWithLang.cvApp.cvAppForms.noPreview}
                                    </Typography>
                                )}
                            </Grid>

                        </Grid>

                        <Grid item xs={12} style={{margin: 20}}>
                            <Divider/>
                        </Grid>


                        <Grid item xs={12} marginLeft={5} marginRight={2}>
                            <Typography variant="h4" align="center" style={{marginBottom: 20, marginTop: 20}}>
                                {wordsWithLang.cvApp.cvAppForms.choseCvPack}

                            </Typography>
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12} sm={4}>
                                    <Paper
                                        elevation={3}
                                        style={{
                                            padding: 20,
                                            textAlign: 'center',
                                            borderRadius: '15px',
                                            borderColor: selectedPackId === cvTemplateProduct.id ? "#4c3198" : "#ffffff",
                                            borderWidth: 3,
                                            borderStyle: "solid",
                                            transition: 'all .3s',
                                            // boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
                                        }}
                                    ><Grid></Grid>
                                        <Typography variant="h5" style={{marginBottom: 10}}>
                                            <AccountCircleOutlined style={{
                                                fontSize: 25,
                                                marginBottom: 8,
                                                borderBottom: "2px solid #676767",
                                                color: "#676767",
                                                padding: 2
                                            }} thickness="thin"/>
                                            <br/>
                                            {wordsWithLang.cvApp.cvAppForms.individualPack}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary"
                                                    style={{marginBottom: 20, fontSize: 18}}>
                                            {wordsWithLang.cvApp.cvAppForms.individualDesc}
                                        </Typography>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.choosingColor}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <CloseOutlined style={{fontSize: 20, marginRight: 8}} color="error"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.editableCv}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <CloseOutlined style={{fontSize: 20, marginRight: 8}} color="error"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.onePrice}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.oneTimeUse}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h6" color="primary" style={{marginBottom: 20}}>
                                    <span style={{
                                        color: "darkgray",
                                        textDecoration: "line-through",
                                        fontSize: "18px"
                                    }}>{cvTemplateProduct.price + cvTemplateProduct.currency}</span>
                                            {"  " + cvTemplateProduct.calcPrice + " " + cvTemplateProduct.currency}
                                        </Typography>
                                        <Button
                                            disabled={selectedPackId === cvTemplateProduct.id}
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            style={{color: 'white', borderRadius: 20}}
                                            onClick={() => {
                                                changePackTo("cvTemplate")
                                            }}
                                        >
                                            {wordsWithLang.cvApp.cvAppForms.choosePack}
                                        </Button>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Paper
                                        elevation={3}
                                        style={{
                                            padding: 20,
                                            textAlign: 'center',
                                            borderRadius: '15px',
                                            borderColor: selectedPackId === cvPack1.id ? "#4c3198" : "#ffffff",
                                            borderWidth: 3,
                                            borderStyle: "solid",
                                            transition: 'all .3s',
                                        }}
                                    >
                                        <Typography variant="h5" style={{marginBottom: 10}}>
                                            <BusinessCenterOutlined style={{
                                                fontSize: 25,
                                                marginBottom: 8,
                                                borderBottom: "2px solid #676767",
                                                color: "#676767",
                                                padding: 2
                                            }} thickness="thin"/>
                                            <br/>
                                            {cvPack1.title}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary"
                                                    style={{marginBottom: 20, fontSize: 18}}>
                                            {cvPack1.description}
                                        </Typography>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.choosingColor}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.editableCv}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.onePrice}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.sixMonthUse}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h6" color="primary" style={{marginBottom: 20}}>
                                    <span style={{
                                        color: "darkgray",
                                        textDecoration: "line-through",
                                        fontSize: "18px"
                                    }}>{cvPack1.price + cvPack1.currency}</span>

                                            {"  " + cvPack1.calcPrice + " " + cvPack1.currency}
                                        </Typography>
                                        <Button
                                            disabled={selectedPackId === cvPack1.id}
                                            variant="contained"
                                            fullWidth
                                            color="success"
                                            style={{color: 'white', borderRadius: 20}}
                                            onClick={() => {
                                                changePackTo("cvPack1")
                                            }}
                                        >
                                            {wordsWithLang.cvApp.cvAppForms.choosePack}
                                        </Button>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Paper
                                        elevation={3}
                                        style={{
                                            padding: 20,
                                            textAlign: 'center',
                                            borderRadius: '15px',
                                            borderColor: selectedPackId === cvPack2.id ? "#4c3198" : "#ffffff",
                                            borderWidth: 3,
                                            borderStyle: "solid",
                                            transition: 'all .3s',
                                        }}
                                    >
                                        <Typography variant="h5" style={{marginBottom: 10}}>
                                            <CorporateFareOutlined style={{
                                                fontSize: 25,
                                                marginBottom: 8,
                                                borderBottom: "2px solid #676767",
                                                color: "#676767",
                                                padding: 2
                                            }} thickness="thin"/>
                                            <br/>
                                            {cvPack2.title}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary"
                                                    style={{marginBottom: 20, fontSize: 18}}>
                                            {cvPack2.description}
                                        </Typography>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.choosingColor}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.editableCv}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.onePrice}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                            <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                            <Typography variant="body1" color="textSecondary" align={"left"}>
                                                {wordsWithLang.cvApp.cvAppForms.yearUse}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h6" color="primary" style={{marginBottom: 20}}>
                                    <span style={{
                                        color: "darkgray",
                                        textDecoration: "line-through",
                                        fontSize: "18px"
                                    }}>{cvPack2.price + cvPack2.currency}</span>
                                            {"  " + cvPack2.calcPrice + " " + cvPack2.currency}
                                        </Typography>
                                        <Button
                                            disabled={selectedPackId === cvPack2.id}
                                            variant="contained"
                                            fullWidth
                                            color="secondary"
                                            style={{color: 'white', borderRadius: 20}}
                                            onClick={() => {
                                                changePackTo("cvPack2")
                                            }}
                                        >
                                            {wordsWithLang.cvApp.cvAppForms.choosePack}
                                        </Button>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Paper
                                        elevation={3}
                                        style={{padding: 20, textAlign: 'left', borderRadius: '15px'}}

                                    >
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item xs={12} style={{width: "100%"}}>
                                                <Typography variant="h6" style={{marginRight: 20, cursor: "pointer"}}
                                                            onClick={() => {
                                                                changePackTo("hrAdvice")
                                                            }}>
                                                    <Checkbox checked={hrAdviceCheck} color="primary" size={"medium"}/>
                                                    {hrAdvicePack.title}
                                                </Typography>
                                                <Typography variant={"body2"} color="textSecondary"
                                                            style={{marginLeft: 40}}>
                                                    {hrAdvicePack.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} justifyContent={"flex-end"}>
                                                {/*<Box alignItems={"flex-end"} display={"block"} style={{width:"100%"}}>*/}
                                                <Typography variant="h6" display={"block"} align={"right"}
                                                            color="primary">
                                            <span style={{
                                                color: "darkgray",
                                                textDecoration: "line-through",
                                                fontSize: "18px"
                                            }}>{hrAdvicePack.price + hrAdvicePack.currency}</span>

                                                    {"  " + hrAdvicePack.calcPrice + " " + hrAdvicePack.currency}
                                                </Typography>
                                                {/*</Box>*/}
                                            </Grid>
                                        </Grid>

                                    </Paper>
                                </Grid>


                            </Grid>
                        </Grid>


                        <Grid item xs={12} style={{marginTop: 200}}>
                            <Divider/>
                        </Grid>


                        <Grid container justifyContent={"center"}
                              style={{
                                  boxShadow: "0 5px 5px rgba(0,0,0,.08), 0 0 6px rgba(21, 101, 192, 0.3)",
                                  position: isMobile !== null ? 'fixed' : 'static',
                                  bottom: isMobile !== null ? 0 : 'auto',
                                  left: isMobile !== null ? 0 : 'auto',
                                  width: isMobile !== null ? '100%' : 'auto',
                                  zIndex: isMobile !== null ? 999 : 'auto',
                                  backgroundColor: "white"
                              }}>

                            <Grid item xs={10} p={2}>
                                <Grid container>
                                    <Typography>
                                        {wordsWithLang.cvApp.cvAppForms.totalPrice}: <span>{totalPrice + " " + cvTemplateProduct.currency}</span></Typography>
                                </Grid>
                                {/*<Grid container>*/}
                                {/*    <FormControlLabel*/}
                                {/*        value={agreeWithRules}*/}
                                {/*        control={*/}
                                {/*            <Checkbox*/}
                                {/*                name={"policy"}*/}
                                {/*                id="privacy"*/}
                                {/*                checked={agreeWithRules}*/}
                                {/*                onClick={(e) => changeAgreeWithRules(!agreeWithRules)}*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*        label={*/}
                                {/*            <Typography fontSize={13} variant={"subtitle1"} style={{fontWeight: 100}}>*/}
                                {/*                {window.localStorage.getItem("pageLang") === "az" ? (<>*/}
                                {/*                        <a style={{textDecoration: "underline"}}*/}
                                {/*                           href="https://bakujobs.az/docs/terms" target="_blank"*/}
                                {/*                           rel="noopener noreferrer">*/}
                                {/*                            {wordsWithLang.general.paymentRules}*/}
                                {/*                        </a>*/}
                                {/*                        <span>{wordsWithLang.general.iReadPaymentRules}</span>*/}
                                {/*                    </>*/}
                                {/*                ) : (*/}
                                {/*                    <>*/}
                                {/*                        <span>{wordsWithLang.general.iReadPaymentRules + " "}</span>*/}
                                {/*                        <a style={{textDecoration: "underline"}}*/}
                                {/*                           href="https://bakujobs.az/docs/terms" target="_blank"*/}
                                {/*                           rel="noopener noreferrer">*/}
                                {/*                            {wordsWithLang.general.paymentRules}*/}
                                {/*                        </a>*/}
                                {/*                    </>*/}
                                {/*                )}*/}


                                {/*            </Typography>*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} md={6} p={1}>
                                <Button
                                    variant="contained"
                                    startIcon={<AutoFixHigh fontSize="medium" style={{color: 'white'}}/>}
                                    className={newCvOrder.previewUrl === "" || newCvOrder.previewUrl === null || !agreeWithRules ? "addButton continueButton btn disabledButton" : "addButton continueButton btn"}
                                    size="small"
                                    disabled={newCvOrder.previewUrl === "" || newCvOrder.previewUrl === null || !agreeWithRules}
                                    style={{width: isMobile !== null ? '100%' : '65%', marginTop: 15, marginBottom: 15}}
                                    onClick={() => {
                                        checkForPayment()
                                    }}
                                >
                                    {words.cvApp.cvAppForms.complete}
                                </Button>
                            </Grid>
                        </Grid>


                        {/*preview margin editor*/}

                        <Dialog open={marginDialogOpen} onClose={() => {
                            setMarginDialogOpen(false)
                            setMarginWait(false)
                            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})
                        }} fullWidth>
                            {!marginWait ? (
                                <>
                                    <DialogTitle>
                                        <Typography variant={"body1"}
                                                    style={{marginBottom: 15}}
                                        >{wordsWithLang.cvApp.cvAppForms.template.headerOfMargin}</Typography>
                                        <FormControl variant="outlined"
                                                     style={{width: "100%", marginTop: 15, marginBottom: 15}}>
                                            <InputLabel
                                                id="marginSectionLabelId">{wordsWithLang.cvApp.cvAppForms.template.defaultSelect}</InputLabel>
                                            <Select
                                                labelId="marginSectionLabelId"
                                                id="marginSectionId"
                                                value={selectedSection}
                                                onChange={(e) => {
                                                    setSelectedSection(e.target.value)
                                                    document.getElementById("marginSectionLabelId").style.color = "black"
                                                    document.getElementById("marginSectionId").style.color = "black"

                                                }}
                                                label="Select"
                                            >
                                                <MenuItem
                                                    value={"avatar-section"}>{wordsWithLang.cvApp.cvAppForms.template.avatarSection}</MenuItem>
                                                <MenuItem
                                                    value={"name"}>{wordsWithLang.cvApp.cvAppForms.template.name}</MenuItem>
                                                <MenuItem
                                                    value={"surname"}>{wordsWithLang.cvApp.cvAppForms.template.surname}</MenuItem>
                                                <MenuItem
                                                    value={"birthDate"}>{wordsWithLang.cvApp.cvAppForms.template.birthDate}</MenuItem>
                                                <MenuItem
                                                    value={"email"}>{wordsWithLang.cvApp.cvAppForms.template.email}</MenuItem>
                                                <MenuItem
                                                    value={"phone"}>{wordsWithLang.cvApp.cvAppForms.template.phone}</MenuItem>
                                                <MenuItem
                                                    value={"about"}>{wordsWithLang.cvApp.cvAppForms.template.about}</MenuItem>
                                                <MenuItem
                                                    value={"links"}>{wordsWithLang.cvApp.cvAppForms.template.links}</MenuItem>
                                                <MenuItem
                                                    value={"competencies-section"}>{wordsWithLang.cvApp.cvAppForms.template.competenciesSection}</MenuItem>
                                                <MenuItem
                                                    value={"certificates-section"}>{wordsWithLang.cvApp.cvAppForms.template.certificatesSection}</MenuItem>
                                                <MenuItem
                                                    value={"educations-section"}>{wordsWithLang.cvApp.cvAppForms.template.educationsSection}</MenuItem>
                                                <MenuItem
                                                    value={"languages-section"}>{wordsWithLang.cvApp.cvAppForms.template.languagesSection}</MenuItem>
                                                <MenuItem
                                                    value={"programs-section"}>{wordsWithLang.cvApp.cvAppForms.template.programsSection}</MenuItem>
                                                <MenuItem
                                                    value={"references-section"}>{wordsWithLang.cvApp.cvAppForms.template.referencesSection}</MenuItem>
                                                <MenuItem
                                                    value={"workExperiences-section"}>{wordsWithLang.cvApp.cvAppForms.template.workExperiencesSection}</MenuItem>
                                                <MenuItem
                                                    value={"notes-section"}>{wordsWithLang.cvApp.cvAppForms.template.notesSection}</MenuItem>

                                            </Select>
                                        </FormControl>

                                        <Button variant={"contained"} color={"primary"} style={{marginRight: 15}}
                                                onClick={() => {
                                                    if (selectedSection === "") {
                                                        document.getElementById("marginSectionLabelId").style.color = "red"
                                                        return
                                                    }
                                                    ;
                                                    document.getElementById("marginSectionLabelId").style.color = "black"
                                                    setMarginWait(true)
                                                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppChangeMargin
                                                        .replace("{CvOrderId}", newCvOrder.id).replace("{section}", selectedSection).replace("{marginSide}", "bottom")
                                                        , {withCredentials: true}).then(() => {
                                                        setMarginWait(false)
                                                    })
                                                }}>
                                            <ArrowUpward/></Button>
                                        <Button variant={"contained"} color={"primary"} style={{marginRight: 15}}
                                                onClick={() => {
                                                    if (selectedSection === "") {
                                                        document.getElementById("marginSectionLabelId").style.color = "red"
                                                        return
                                                    }
                                                    ;
                                                    document.getElementById("marginSectionLabelId").style.color = "black"
                                                    setMarginWait(true)
                                                    let prevUrl = newCvOrder.previewUrl
                                                    axios.get(apiUrls.baseApiUrl + apiUrls.cvAppChangeMargin
                                                        .replace("{CvOrderId}", newCvOrder.id).replace("{section}", selectedSection).replace("{marginSide}", "top")
                                                        , {withCredentials: true}).then(() => {
                                                        setMarginWait(false)

                                                    })
                                                }}>
                                            <ArrowDownward/></Button>
                                        {/*<Button variant={"contained"} color={"primary"} style={{marginRight:15}}><ArrowUpward style={{transform:'rotate(90deg)'}}/></Button>*/}
                                        {/*<Button variant={"contained"} color={"primary"} style={{marginRight:15}}><ArrowUpward  style={{transform:'rotate(270deg)'}}/></Button>*/}

                                        <Typography display={"block"} variant={"caption"} color={"gray"}
                                                    style={{marginTop: 5}}>{"* " + wordsWithLang.cvApp.cvAppForms.template.descUse}</Typography>

                                    </DialogTitle>
                                    <DialogContent style={{padding: "15", overflow: "hidden !important"}}>
                                        <Grid container justifyContent={"center"} style={{backgroundColor: "gray"}}>
                                            {newCvOrder.previewUrl !== null && (
                                                Array.from({length: newCvOrder.pagesCount}).map((_, page) => (
                                                    <Box component="img"
                                                         ref={previewRef}
                                                        // src={newCvOrder.previewUrl}
                                                         src={marginWait ? Math.random() : apiUrls.baseApiUrl + apiUrls.cvAppGetPreviewOfCv.replace("{CvOrderId}", newCvOrder.id).replace("{page}", page) + "?t=" + Date.now()}
                                                         sx={{
                                                             margin: 2,
                                                             width: 595 / 1.5,
                                                             position: "relative",
                                                             // width: "100%",
                                                             objectFit: "contain",
                                                             objectPosition: "top",
                                                             backgroundColor: "white",
                                                             height: 842 / 1.5,
                                                             backgroundSize: "contain",
                                                             backgroundRepeat: "no-repeat",
                                                             backgroundPosition: "top",
                                                             boxShadow: "0 6px 10px rgba(0,0,0,.4), 0 0 6px rgba(0,0,0,.05)",
                                                             // backgroundImage: "url('"  +"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png"+ "')",
                                                             display: "flex",
                                                             justifyContent: "center",
                                                             alignItems: "flex-end",
                                                         }}

                                                    />)
                                                ))}
                                            {newCvOrder.previewUrl === null && (
                                                <Typography m={5} color={"error"} fontSize={20} sx={{
                                                    animation: "fadeIn 2s linear infinite",
                                                    animationDirection: "alternate-reverse"
                                                }}>
                                                    {wordsWithLang.cvApp.cvAppForms.noPreview}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </DialogContent>

                                    <DialogActions style={{borderTop: "1px solid gray"}}>

                                        <Button
                                            variant={"contained"}
                                            className={"addButton"}
                                            style={{margin: 5}}
                                            onClick={() => {
                                                setMarginDialogOpen(false);
                                                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})
                                            }}>{wordsWithLang.loginDialog.continueButton}</Button>
                                    </DialogActions>
                                </>
                            ) : (
                                <Grid container justifyContent={"center"} style={{padding: '200px'}}>
                                    <CircularProgress
                                        size={40}
                                        color={"warning"}
                                        thickness={4}
                                        variant="indeterminate"
                                        value={75}
                                        style={{margin: '30px'}}
                                    />
                                    <Typography display={"block"}>{wordsWithLang.general.pleaseWait}</Typography>
                                </Grid>)}
                        </Dialog>

                        {/*preview margin editor*/}


                        <Dialog PaperProps={{
                            style: {
                                overflow: 'hidden',
                            },
                        }}
                                open={paymentStatusDialog === "WAIT" || paymentStatusDialog === "SUCCESS" || paymentStatusDialog === "ERROR" || paymentStatusDialog === "TIMEOUT"}>
                            {/*<DialogTitle>{wordsWithLang.header.notification}</DialogTitle>*/}
                            <DialogContent style={{padding: "15", overflow: "hidden !important"}}>
                                {paymentStatusDialog === "SUCCESS" ? (
                                    <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                            <CheckCircle color="success" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.successCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.successCvGenerated2}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "black",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }} onMouseEnter={(e) => {
                                    e.currentTarget.style.color = 'red'
                                }} onMouseLeave={(e) => {
                                    e.currentTarget.style.color = 'black'
                                }}
                                   onClick={() => {
                                       window.open(newCvOrder.pdfUrl, '_blank');
                                   }}>{wordsWithLang.cvApp.cvAppForms.canDownloadFromHere}</p>
                                <Box
                                    display={newCvOrder.products.some(product => product.productType === 'HR_ADVICE_PACK') ? "flex" : "none"}
                                    alignItems="center" style={{color: "darkgray"}}><InfoOutlined color="primary"
                                                                                                  style={{margin: 10}}/> <p>{wordsWithLang.cvApp.cvAppForms.hrWillConnect}</p></Box>
                        <Confetti width={window.screen.width} height={window.screen.width}/>
                            </span>
                                ) : null}
                                {paymentStatusDialog === "ERROR" ? (
                                    <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                                <Cancel color="error" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated2}</p>
                            </span>
                                ) : null}
                                {paymentStatusDialog === "TIMEOUT" ? (
                                    <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                                <Cancel color="error" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated2}</p>
                            </span>
                                ) : null
                                }
                                {paymentStatusDialog === "WAIT" ? (
                                    <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                                <Timelapse color="warning" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.waitCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.waitCvGenerated2}</p>
                            </span>
                                ) : null
                                }
                            </DialogContent>
                            <DialogActions style={{padding: 15}}>
                                <Button onClick={() => {
                                    if (paymentStatusDialog === "SUCCESS") {
                                        window.location.reload()
                                    }
                                    setPaymentStatusDialog("")

                                }} color="primary">
                                    {wordsWithLang.cvApp.cancel}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            );
        }
    ;

    export default CvAppPreview;



