import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    IconButton,
    Menu,
    MenuItem, Select,
    Stack,
    Tab,
    Tabs,
    Typography,
    useTheme
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch, useSelector} from "react-redux";
import {DocumentScanner, Edit, Email, Favorite, Home, Language, Person} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import Search from "./search";
import axios from "axios";
import apiUrls from "../apiUrls";
import MiniContact from "./miniContact";


const MyAppBar = ({wordsWithLang}) => {
    const mainConfig = useSelector(state => state.mainConfig)
    const userConfig = useSelector(state => state.userConfig)
    const mobileClass = mainConfig.isMobile ? "-m" : "";
    const dispatcher = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const palette = useTheme().palette;

    const [anchorElLang, setAnchorElLang] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const avatarMenuClick = (event) => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const avatarMenuClose = () => {
        setAnchorEl(null);
    };
    const logOut = () => {
        dispatcher({type: "CHANGE_LOGIN", payload: false})
        dispatcher({type: "SET_USER", payload: {}})
        localStorage.removeItem("bj_token")
        // document.cookie = "bj_token=";
        axios.post(apiUrls.baseApiUrl + apiUrls.logout, userConfig,{withCredentials:"include"}).then(resp => {
            if (resp.data) {
                    dispatcher({type: "SET_USER", payload: null})
                    dispatcher({type: "CHANGE_LOGIN", payload: false})
                    dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
                localStorage.removeItem("bj_token")

                var is_ssl = window.location.protocol === "https:";

                var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                var sec = is_ssl ? ";secure" : "";

                // document.cookie = "bj_token" + " = " + resp.data.userToken + ss + sec;
            }

        })

        alert(wordsWithLang.header.logouted)
        setAnchorEl(null);
    }

    const handleClickLang = (event) => {
        setAnchorElLang(event.currentTarget);
    };

    const handleCloseLang = () => {
        setAnchorElLang(null);
    };
    const handleLanguageChange = (lang) => {
        window.localStorage.setItem("pageLang",lang)
        handleCloseLang();
        window.location.reload();
    };


    const checkAuthAndNavigateTo = (path) => {
        if (userConfig.isLogin) {
            navigate(path)
        } else {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        }
    }

    const vacancyHeader = (wordsWithLang) => {
        return (<MiniContact viewType={"onlyLinks"} wordsWithLang={wordsWithLang}/>)
    }
    const favoritesHeader = (wordsWithLang) => {
        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Favorite style={{textShadow: "-2px 2px 10px #ffffff", fontSize: 50}}
                              color={"secondary"}/>
                    <Typography style={{color: "white", textShadow: "-2px 2px 5px #1A237E"}} display={"inline"}
                                align={"center"} variant={"h4"}>{wordsWithLang.header.favoriteAds}</Typography>

                </Stack>
                <br/>
                <br/>
                <Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}
                        variant={"contained"} size={"small"} display={"block"} align={"right"}>
                    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}
                          titleAccess={wordsWithLang.header.mainPage}/>
                    {wordsWithLang.header.mainPage}
                </Button>
            </Box>)
    }

    const blogHeader = (wordsWithLang) => {
        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Edit style={{textShadow: "-2px 2px 10px #1A237E", fontSize: 50, color:"#4c3198"}}/>
                    <Typography className={"text-shine"} style={{fontSize:"1.9rem"}} display={"inline"}
                                align={"center"} variant={"h4"}>{wordsWithLang.header.blogNotes}</Typography>

                </Stack>
              <br/>
                <Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}
                        variant={"contained"} size={"small"} display={"block"} align={"right"}>
                    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}
                          titleAccess={wordsWithLang.header.mainPage}/>
                    {wordsWithLang.header.mainPage}
                </Button>
            </Box>)
    }


    // const subscribeHeader = () => {
    //     return (<Box style={{width: "100%", verticalAlign: "middle"}}>
    //         <Stack direction="row" alignItems="center" gap={2}>
    //             <Email style={{textShadow: "-2px 2px 10px #ffffff", fontSize: 50}}
    //                    color={"primary"}/>
    //             <Typography style={{color: "white", textShadow: "-2px 2px 10px #000000"}} display={"inline"}
    //                         align={"left"} variant={"h4"}> {wordsWithLang.header.subscriptionsAds}</Typography>
    //
    //         </Stack>
    //         <br/>
    //         <br/>
    //         <Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}
    //                 variant={"contained"} size={"small"} display={"block"} align={"right"}>
    //             <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}
    //                   titleAccess={wordsWithLang.header.mainPage}/>
    //             {wordsWithLang.header.mainPage}
    //         </Button>
    //     </Box>)
    // }

    const cvAppHeader = (wordsWithLang) => {
        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <DocumentScanner  style={{textShadow: "-2px 2px 10px #837BE8", fontSize: 50, color:"#4c3198"}}
                          // color={"primary"}
                    />
                    <Typography className={"typewriter"} display={"inline"} fontWeight={"900"}
                                style={{fontSize:mainConfig.isMobile?"1.2rem":"1.5rem", color:"#240085"}}
                                align={"center"} variant={"h4"}>{wordsWithLang.cvApp.cvAppHeader}</Typography>

                </Stack>
                <br/>
                <Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}
                        variant={"contained"} size={"small"} display={"block"} align={"right"}>
                    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}
                          titleAccess={wordsWithLang.header.mainPage}/>
                    {wordsWithLang.header.mainPage}
                </Button>
            </Box>)
    }


    const renderSearch = () => {
        let comp = null;
        switch (location.pathname.split("/")[1]) {
            case "" :
                comp = <Search wordsWithLang={wordsWithLang}/>;
                break;

            case "vacancy" :
                comp = vacancyHeader(wordsWithLang);
                break;

            case "cv" :
                comp = cvAppHeader(wordsWithLang);
                break;


            case "favorites" :
                comp = favoritesHeader(wordsWithLang)
                break;

            case "subscriptions" :
                // comp = subscribeHeader()
                comp = vacancyHeader(wordsWithLang)
                break;
            case "aboutUs" :
                comp = vacancyHeader(wordsWithLang);
                break;

            case "services" :
                comp = vacancyHeader(wordsWithLang);
                break;
            case "company" :
                comp = <Search wordsWithLang={wordsWithLang}/>;
                break;
            case "category" :
                comp = <Search wordsWithLang={wordsWithLang}/>;
                break;
            case "blog" :
                comp = blogHeader(wordsWithLang);
                break;
            default :
                comp = null
        }
        return comp
    }
    useEffect(() => {
        // console.log(navigator.userAgent.toString())
    }, [])




    return (
        <div>
            <AppBar style={{display: navigator.userAgent.toString().includes("render") ? "none" : "block"}}
                    position={"relative"} className={"appBar"} color={"default"}>
                <Grid className={"header" + mobileClass} container direction={"row"} alignItems={"flex-start"}
                      style={{boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)"}}
                      justifyContent={"center"}>
                    <Grid item xs={1}>
                        <Grid container direction={"column"} alignItems={"flex-start"} justifyContent={"center"}>
                            <Box component={Grid} item xs={1} display={{
                                md: "none",
                                //menu is hidden
                                xs: "none"
                                //menu is hidden
                            }}>
                                <IconButton
                                    size="medium"
                                    onClick={() => {
                                        dispatcher({
                                            type: "IS_OPEN_DRAWER",
                                            payload: !mainConfig.isDrawerOpen
                                        })
                                    }}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={11} md={10}>
                        <Grid container direction={"row"} justifyContent={"center"} alignItems={"flex-start"}
                              style={{marginTop: 30}}>
                            <Grid item xs={6} md={9} xl={9} lg={9}>
                                <Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>
                                    <Grid item xs={10} onClick={() => {
                                        navigate("/");
                                        window.location.reload();
                                    }} className={"bigLogo"}/>
                                </Grid>

                            </Grid>
                            <Grid item xs={6} md={3} xl={2} lg={2}>
                                <Grid container justifyContent={"flex-end"} alignItems={"center"}>

                                    <IconButton onClick={handleClickLang}>
                                        <Badge overlap="rectangular" color="secondary" title="12">
                                            <Language style={{ fontSize: "25px" }} titleAccess={wordsWithLang.header.changeLang} />
                                        </Badge>
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorElLang}
                                        open={Boolean(anchorElLang)}
                                        onClose={handleCloseLang}
                                    >
                                        <MenuItem onClick={() => handleLanguageChange('az')}>
                                            <Box component="img"
                                                 src={"https://flagcdn.com/az.svg"}
                                                 sx={{ width: 30, height: 20 }}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={() => handleLanguageChange('en')}>
                                            <Box component="img"
                                                 src={"https://flagcdn.com/gb.svg"}
                                                 sx={{ width: 30, height: 20 }}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={() => handleLanguageChange('ru')}>
                                            <Box component="img"
                                                 src={"https://flagcdn.com/ru.svg"}
                                                 sx={{ width: 30, height: 20 }}
                                            />
                                        </MenuItem>
                                        {/* Add more languages as needed */}
                                    </Menu>

                                    <IconButton onClick={() => checkAuthAndNavigateTo("/favorites")}>
                                        <Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}
                                               overlap="rectangular" color={"secondary"} title={"12"}>
                                            <Favorite style={{fontSize: "25px"}} titleAccess={wordsWithLang.header.likes}/>
                                        </Badge>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        checkAuthAndNavigateTo("/subscriptions")
                                    }}>
                                        <Badge overlap="rectangular" color={"secondary"}
                                               title={"12"}>
                                            <Email style={{fontSize: "25px"}} titleAccess={wordsWithLang.header.subscription}/>
                                        </Badge>
                                    </IconButton>

                                    <IconButton
                                        id="avatar-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={avatarMenuClick}
                                    >
                                        <Avatar
                                            style={{backgroundColor: !userConfig.isLogin ? palette.text.secondary : palette.primary.main}}>
                                            <Person/>
                                        </Avatar>
                                    </IconButton>
                                    <Menu
                                        id="avatar-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={avatarMenuClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={logOut}>{wordsWithLang.header.logout}</MenuItem>
                                    </Menu>

                                </Grid>
                            </Grid>
                            {/*<Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>*/}
                            <Grid item xs={12} md={12} sm={12} lg={11}>
                                <Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>
                                    <Tabs value={tabIndex} onChange={handleTabChange}>
                                        <Tab className={"headerTab" + mobileClass} label={wordsWithLang.header.vacancies}
                                             onClick={() => {
                                                 navigate("/")
                                             }}/>
                                        <Tab className={"headerTab" + mobileClass} label={wordsWithLang.header.services}
                                             onClick={() => {
                                                 navigate("services")
                                             }}/>
                                        <Tab className={"headerTab" + mobileClass} label={wordsWithLang.header.aboutUs}
                                             onClick={() => {
                                                 navigate("aboutUs")
                                             }}/>
                                    </Tabs>
                                </Grid>
                            </Grid>
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>

                    <Grid item xs={10} sm={10} md={9} lg={8} style={{marginTop: 10}}>
                        {renderSearch()}
                    </Grid>

                </Grid>

            </AppBar>


        </div>
    );
};

export default MyAppBar
