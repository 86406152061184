export const programs = [
    // Microsoft Office Suite
    "Microsoft Word", "Microsoft Excel", "Microsoft PowerPoint", "Microsoft Outlook", "Microsoft OneNote",
    //
    // Google Workspace
    "Google Docs", "Google Sheets", "Google Slides", "Gmail", "Google Drive",

    // Adobe Creative Cloud
    "Adobe Photoshop", "Adobe Illustrator", "Adobe InDesign", "Adobe Premiere Pro", "Adobe After Effects",

    // Collaboration Tools
    "Slack", "Microsoft Teams", "Zoom", "Trello", "Asana",

    // Development Tools
    "Visual Studio Code", "GitHub", "GitLab", "Bitbucket", "Docker",

    // Project Management Tools
    "Jira", "Basecamp", "Monday.com", "ClickUp", "Smartsheet",

    // Database Management Systems
    "MySQL", "PostgreSQL", "MongoDB", "Oracle Database", "Microsoft SQL Server",

    // Cloud Services
    "Amazon Web Services (AWS)", "Microsoft Azure", "Google Cloud Platform (GCP)", "IBM Cloud", "Oracle Cloud",

    // Cybersecurity Tools
    "Norton Security", "McAfee", "Kaspersky", "Bitdefender", "Avast",

    // Web Browsers
    "Google Chrome", "Mozilla Firefox", "Safari", "Microsoft Edge", "Opera",

    // For Software Development
    "IntelliJ IDEA", "Eclipse", "PyCharm", "NetBeans", "Xcode",
    "Git", "Subversion (SVN)", "Mercurial", "Perforce", "CVS",
    "Jenkins", "CircleCI", "Travis CI", "Bamboo", "TeamCity",
    "JavaScript", "Python", "Java", "C#", "C++", "Ruby", "PHP", "Swift", "Kotlin", "Go", "R", "TypeScript",
    "HTML", "CSS", "SQL", "NoSQL", "GraphQL",
    "React", "Angular", "Vue.js", "Svelte", "Node.js", "Express.js", "Django", "Flask", "Spring Boot", "ASP.NET",
    "MySQL", "PostgreSQL", "MongoDB", "Oracle Database", "Microsoft SQL Server", "SQLite", "Redis",

    // For Data Science and Analysis
    "RStudio", "Jupyter Notebook", "Anaconda", "SPSS", "SAS",
    "Tableau", "Power BI", "QlikView", "Google Data Studio", "D3.js",
    "Hadoop", "Spark", "Apache Flink", "Cassandra", "Kafka",

    // For Graphic Design and Multimedia
    "CorelDRAW", "Affinity Designer", "Sketch", "Figma", "Canva",
    "Final Cut Pro", "Avid Media Composer", "DaVinci Resolve", "Sony Vegas Pro", "Camtasia",

    // For Finance and Accounting
    "QuickBooks", "Xero", "FreshBooks", "Sage", "Wave",
    "Bloomberg Terminal", "Thomson Reuters Eikon", "FactSet", "Morningstar Direct", "YCharts",

    // For Healthcare
    "Epic", "Cerner", "Allscripts", "eClinicalWorks", "Meditech",
    "DICOM", "OsiriX", "Horos", "RadiAnt DICOM Viewer", "Amira",

    // For Marketing and Sales
    "Salesforce", "HubSpot", "Zoho CRM", "Microsoft Dynamics 365", "Pipedrive",
    "Mailchimp", "Constant Contact", "Sendinblue", "Campaign Monitor", "GetResponse",
    "SEMrush", "Ahrefs", "Moz", "Google Analytics", "Screaming Frog SEO Spider",

    // For Human Resources
    "Workday", "BambooHR", "ADP Workforce Now", "SAP SuccessFactors", "Oracle HCM Cloud",
    "LinkedIn Recruiter", "Taleo", "Greenhouse", "Lever", "Jobvite",

    // For Education and eLearning
    "Moodle", "Blackboard", "Canvas", "Google Classroom", "Schoology",
    "Articulate Storyline", "Adobe Captivate", "Lectora", "iSpring Suite", "Elucidat",

    // For Architecture and Engineering
    "AutoCAD", "SolidWorks", "Revit", "SketchUp", "ArchiCAD",
    "Autodesk BIM 360", "Navisworks", "Tekla Structures", "Vectorworks", "Bentley Systems",

    // For Legal Professionals
    "Westlaw", "LexisNexis", "Fastcase", "Casetext", "Bloomberg Law",
    "Clio", "MyCase", "PracticePanther", "Rocket Matter", "Zola Suite",

    // For Manufacturing and Logistics
    "SAP ERP", "Oracle ERP Cloud", "Microsoft Dynamics 365", "Infor ERP", "Epicor ERP",
    "SAP SCM", "Oracle SCM Cloud", "JDA Software", "Manhattan Associates", "Kinaxis",

    // For Real Estate
    "AppFolio", "Buildium", "Yardi", "Propertyware", "Rent Manager",
    "Salesforce for Real Estate", "Contactually", "Top Producer", "Wise Agent", "BoomTown",

    // More General Tools Across Industries
    "MindMeister", "XMind", "Lucidchart", "Coggle", "Miro",
    "Toggl", "RescueTime", "Clockify", "Harvest", "Time Doctor",
    "Dropbox", "Google Drive", "OneDrive", "Box", "iCloud",
    "LastPass", "1Password", "Dashlane", "Keeper", "Bitwarden",
    "Evernote", "Notion", "Bear", "Simplenote", "Microsoft OneNote",
    "Zapier", "IFTTT", "Automate.io", "Microsoft Power Automate", "Integromat",
    "Zendesk", "Freshdesk", "Help Scout", "Intercom", "LiveAgent",
    "SurveyMonkey", "Google Forms", "Typeform", "Wufoo", "JotForm",
    "WordPress", "Joomla", "Drupal", "Wix", "Squarespace",
    "Shopify", "WooCommerce", "Magento", "BigCommerce", "PrestaShop",
    "Acronis", "Carbonite", "Backblaze", "Veeam", "IDrive",
    "Shutterstock", "Adobe Stock", "Unsplash", "Pexels", "Pixabay",
    "Postman", "SoapUI", "Insomnia", "Apigee", "Paw"
];


